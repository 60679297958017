/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useState,useEffect, useContext } from "react";
import { Link,useNavigate, useParams, useSearchParams } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";
import "../../../index.scss";
import "../ticketing/ticketing_anlegen.css"
import "./event-erstellen.css";
import  {
  change_color,
  rechange_color,
} from "../login_signup/account_erstellen_functions";
import { enableRipple } from "@syncfusion/ej2-base";
import ArtistInput from "../../widgets/ArtistInput";
import LocationInput from "../../widgets/LocationInput";
import OrganizerInput from "../../widgets/OrganizerInput";
import { addDoc, collection, doc, getDocs, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import { firestore, storage } from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Benefits from "../ticketing/benefits";
import { OrganizerContext } from "../../../providers/OrganizerProvider";
import { connectToFacebook, connectToInstagram } from "../../../facebook";
import { ModalTicketing } from "../ticketing/ticketing_anlegen";
import { 
  titelbildIcon,
  nameIcon,
  datumIcon,
  adresseIcon,
  genreIcon,
  lineupIcon,
  minAlterIcon,
  dresscodeIcon,
  eintrittIcon,
  beschreibungIcon,
  coVeranstalterIcon,
  euroIcon,
  chevronLeftIcon,
  chevronDownIcon,
  deleteIcon,
  infoIcon,
  crossPlattformIcon,
  facebookIcon,
  instagramIcon,
  specialIcon,
  minusIcon,
  plusIcon,
  storyLinkIcon,
  cancelIcon,
  chevronUpIcon,
} from "../../../icons.jsx";
import Success from "../ticketing/success.jsx";
import logo from "../../../graphics/newIcon_white.png";
import kitLogo from "../../../graphics/kit_logo.png";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import LoaderDivWithOverlay, { hideLoader, showLoader } from "../../widgets/loaderdiv.jsx";
import { weekdays } from "../../global_config/weekdays.jsx";
import { infoToast } from "../../widgets/toasts.tsx";

enableRipple(true);

registerLocale("de", de);




// Ticketing Anlegen Modal imbedded end

  function Inputs(props) {
    const [searchParams] = useSearchParams();

    registerLocale("de", de); // for date pickersrt
    const {organizerId} = useContext(UserContext);
    const {organizerType} = useContext(OrganizerContext);
    const {id} = useParams();
    const [state2,setState2] = useState(true);
    const [land,setLand] = useState("");
    const [region,setRegion] = useState("");
    const [darstellen,setDarstellen] = useState("");
    const [lineup, setLineup] = useState([]);
    const [minAlter, setMinAlter] = useState(null);
    const [specials, setSpecials] = useState([]);
    const [state3, setState3] = useState(true);
    const [state4, setState4] = useState(true);
    const [dresscode, setDresscode] = useState(null);
    const [syncToExternal, setSyncToExternal] = useState([]);
    const [venue, setVenue] = useState("");
    const [city, setCity] = useState(null);
    const [location,setLocation] = useState("");
    const [veranstalter, setVeranstalter] = useState([]);
    const [ticketpreisVorverkauf ,setTicketpreisVorverkauf] = useState("")    
    const [ticketpreisAbendkasse ,setTicketpreisAbendkasse] = useState("")   
    const [ticketLink, setTicketLink] = useState("");
    const [stateVorverkauf,setStateVorverkauf] = useState(false)
    const [stateAbendkasse,setStateAbendkasse] = useState(false)
    const [stateFreierEintritt,setStateFreierEintritt] = useState(false)
    const [state,setState] = useState(false);
    const [recurrence, setRecurrence] = useState("");
    const [eventName, setEventName] = useState("");
    const [banner, setBanner] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [startTimeModal, setStartTimeModal] = useState(0);
    const [endDate, setEndDate] = useState(null);
    const [recurringEndDate, setRecurringEndDate] = useState(null)
    const [free, setFree] = useState(false);
    const [beschreibung, setBeschreibung] = useState("");
    const [genre, setGenre] = useState([]);
    const [showModal,setShowModal] = useState(false)
    const [ticketingDone,setTicketingDone] = useState(false)
    const [lowestPrice,setLowestPrice] = useState(0)  
    const [stickerBooked, setStickerBooked] = useState(false);
    const [storyLink, setStoryLink] = useState('');
    const history = useNavigate()
    const [edit,setEdit] = useState(false)
    const [isSubEvent, setIsSubEvent] = useState(false)
    const [originalData, setOriginalData] = useState({});
    const [customizedFields, setCustomizedFields] = useState({});
    const [isParentevent, setIsParentevent] = useState(false)
    const [secretLocation, setSecretLocation] = useState(false);
    const [secretLocationRevealTime, setSecretLocationRevealTime] = useState(null)
    const handleCustomizationChange = (fieldName, value) => {
      if (!isSubEvent) return;
    
      const originalValue = originalData[fieldName];
      
      // Utility functions
      const deepEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);
    
      // Check if the values are different or if the original value was an empty array/object
      const valuesDifferent = !deepEqual(originalValue, value);
    
    
      if (valuesDifferent) {
        setCustomizedFields(prevCustomizedFields => ({
          ...prevCustomizedFields,
          [fieldName]: true
        }));
      } else {
        setCustomizedFields(prevCustomizedFields => ({
          ...prevCustomizedFields,
          [fieldName]: false
        }));
      }
    };
    



    useEffect(() => { organizerId && organizerType === 'venue' && !id && setVenue(organizerId) }, [organizerId, organizerType, setVenue] );
    useEffect(() => {
      searchParams.get("viewParent") === "true" ?
      id && getDoc(doc(firestore, 'recurring_events' ,id))
      .then((event) => {
          setIsParentevent(true)
          if(searchParams.get("showToast") === "true"){
            infoToast("Du bearbeitest jetzt die Eventreihe und nicht das einzelne Event")
            // toast.info("Du bearbeitest jetzt die Eventreihe und nicht das einzelne Event", {theme:"dark"})
          }
          setTicketingDone(["standard", "hidden"].includes(event.data()?.ticket) ? true : false)
          setLowestPrice(event.data()?.price)
          setBanner(event.data()?.images?.length > 0 ? event.data()?.images[0] : null)
          setEventName(event.data()?.name) 
          setStartDate(event.data()?.start?.toDate())
          setStartTimeModal(event.data()?.start ? event.data()?.start.toDate().toLocaleTimeString('de-DE').slice(0,5) + " Uhr" : '')
          setRecurrence('every' in (event.data()?.repeat??{}) ? 'weekly' : 'monthly')
          props.setStateRecurring(true)
          setBeschreibung(event.data()?.description)
          setVenue(event.data()?.venue)
          setLocation(event.data()?.address)
          setCity(event.data()?.city)
          setLineup(event.data()?.lineup)
          setState(event.data()?.end?.toMillis() !== event.data()?.start?.toMillis())
          if(event.data()?.end?.toMillis() !== event.data()?.start?.toMillis()){
            setEndDate(event.data()?.end?.toDate())
          }
          if(event.data()?.vorverkauf){
            document.getElementsByClassName("checkboxEintritt")[0].click()
          }
          if(event.data()?.abendkasse){
            document.querySelector("#akSwitch").click()
          }
          if(event.data()?.free){
            document.getElementsByClassName("checkboxEintritt")[2].click()
          }
          setStateAbendkasse(event.data()?.abendkasse)
          setStateVorverkauf(event.data()?.vorverkauf)
          setFree(event.data()?.free)
          setTicketpreisVorverkauf(event.data()?.price)
          setTicketpreisAbendkasse(event.data()?.box_ticket_price)
          setGenre(event.data()?.genre ?? [])
          setTicketLink(event.data()?.ticket_link)
          setRecurringEndDate(event.data()?.recurring_end?.toDate())
          if(event.data()?.secret_location){
            document.getElementById("secretLocationSwitch").click()
          }
          setSecretLocation(event.data()?.secret_location)
          setSecretLocationRevealTime(event.data()?.secret_location_reveal_time)
          if(event.data()?.min_age != null){
            const possiblePreSelected = [0,16,18,21]
            if(possiblePreSelected.includes(event.data()?.min_age)){
              document.getElementById(event.data()?.min_age).click() 
            }
            else{
              document.getElementById("custom").click()
              document.getElementById("minAlterInp").value=event.data()?.min_age
            } 
          }
          setMinAlter(event.data().min_age)
          setSpecials(event.data().specials??[])
          setDresscode(event.data().dresscode)
          setVeranstalter(event.data().organizer.filter(e => e !== event.data().venue && e !== organizerId))
          setSyncToExternal(event.data().sync_to_external??[])
          if(event.data()?.start?.toDate() < new Date() && event.data().visibility === "public"){
            history(-1)
          }
      })
      :
      id && getDoc(doc(firestore, 'events' ,id))
      .then((event) => {
          setTicketingDone(["standard", "hidden"].includes(event.data()?.ticket) ? true : false)
          setLowestPrice(event.data()?.price)
          setBanner(event.data()?.images?.length > 0 ? event.data()?.images[0] : null)
          setEventName(event.data()?.name) 
          setStartDate(event.data()?.start?.toDate())
          setStartTimeModal(event.data()?.start ? event.data()?.start.toDate().toLocaleTimeString('de-DE').slice(0,5) + " Uhr" : '')
          if(!event.data().repeat){
            setCustomizedFields(event.data().customized_fields)
            setRecurrence('every' in (event.data()?.repeat??{}) ? 'weekly' : 'monthly')
            props.setStateRecurring(event.data()?.repeat ? true : false)
          }
          else{
            setIsSubEvent(true)
            setOriginalData(event.data())
            setCustomizedFields(event.data().customized_fields)
          }
          setBeschreibung(event.data()?.description)
          setVenue(event.data()?.venue)
          setLocation(event.data()?.address)
          setCity(event.data()?.city)
          setLineup(event.data()?.lineup)
          if(event.data()?.secret_location){
            document.getElementById("secretLocationSwitch").click()
          }
          setSecretLocation(event.data()?.secret_location)
          setSecretLocationRevealTime(event.data()?.secret_location_reveal_time)
          if(event.data()?.end !== event.data()?.start){
            document.querySelector("#endDateSwitch")?.click()
            setEndDate(event.data()?.end?.toDate())
          }
          if(event.data()?.vorverkauf){
            document.getElementsByClassName("checkboxEintritt")[0]?.click()
          }
          if(event.data()?.abendkasse){
            document.querySelector("#akSwitch")?.click()
          }
          if(event.data()?.free){
            document.getElementsByClassName("checkboxEintritt")[2]?.click()
          }
          setStateAbendkasse(event.data()?.abendkasse)
          setStateVorverkauf(event.data()?.vorverkauf)
          setFree(event.data()?.free)
          setTicketpreisVorverkauf(event.data()?.price)
          setTicketpreisAbendkasse(event.data()?.box_ticket_price)
          setGenre(event.data()?.genre ?? [])
          setTicketLink(event.data()?.ticket_link)

          if(event.data()?.min_age != null){
            const possiblePreSelected = ["0","16","18","21"]
            if(possiblePreSelected.includes(event.data()?.min_age)){
              document.getElementById(event.data()?.min_age).click() 
            }
            else{
              document.getElementById("custom").click()
              setTimeout(() => {
                document.getElementById("minAlterInp").value=event.data()?.min_age
              },20)
            } 
          }
          setMinAlter(event.data().min_age)
          setSpecials(event.data().specials??[])
          setDresscode(event.data().dresscode)
          setVeranstalter(event.data().organizer.filter(e => e !== event.data().venue && e !== organizerId))
          setSyncToExternal(event.data().sync_to_external??[])
          if(event.data()?.start?.toDate() < new Date() && event.data().visibility === "public"){
            history(-1)
          }

      })
    }, [id]);


    const countWords = [
      'first',
      'second',
      'third',
      'fourth',
      'fifth',
    ];

    useEffect(() => {
      props.setData({
        images: banner ? [banner] : [],
        name: eventName.length === 0 ? "Unbenannt" :  eventName,
        start: startDate ?? null,
        end: !state && startDate ? startDate : endDate ?? null,
        venue: venue,
        address: location??null,
        city: city??null,
        specials: specials.filter(item => item !== ""),
        last_edited: new Date(),
        price: free ? 0 : ticketingDone ? parseFloat(lowestPrice) : stateVorverkauf ? parseFloat(ticketpreisVorverkauf) : parseFloat(ticketpreisAbendkasse), 
        ticket_link: ticketLink || null,
        abendkasse: stateAbendkasse ?? false,
        vorverkauf: stateVorverkauf ?? false,
        free: free ?? false,
        box_ticket_price: !free && !isNaN(ticketpreisAbendkasse) ? parseFloat(ticketpreisAbendkasse) : null,
        description: beschreibung,
        genre: genre,
        min_age: isNaN(parseInt(minAlter)) ? null : parseInt(minAlter),
        dresscode: dresscode,
        organizer: veranstalter ? veranstalter : [],
        lineup: lineup,
        secret_location: secretLocation ?? false,
        secret_location_reveal_time: secretLocationRevealTime ?? null,
        customized_fields: customizedFields??null,
        ...(props.stateRecurring ? {
          recurring_end: recurringEndDate,
          repeat: recurrence === 'monthly' ? {
            [countWords[startDate?.getWeekOfMonth() - 1]]: {
              'weekdays': [ startDate?.getDay() ],
            },
          } : {
            'every': {
              'weekdays': [ startDate?.getDay() ],
            },
          },
        } : {
          sync_to_external: syncToExternal,
          story_link: stickerBooked ? storyLink : null,
        })
      })
    },[banner, eventName, startDate, endDate, stateAbendkasse, stateVorverkauf, specials, ticketpreisAbendkasse, venue, city, location, ticketpreisVorverkauf, ticketLink, beschreibung, free, genre, minAlter, dresscode, veranstalter, lineup, syncToExternal, stickerBooked, storyLink,  state, ticketingDone,recurrence, lowestPrice,props.stateRecurring,recurringEndDate,secretLocation,secretLocationRevealTime,customizedFields])

    const [shown, setShown] = useState(true);
    function animateOptionalInp(){
      if (shown){
        setShown(false)
        document.getElementsByClassName('optionalBox')[0].className="optionalBox animate__animated animate__fadeOutDown"
        setTimeout(() => {
          document.getElementsByClassName('optionalBox')[0].style.display="none"
        },400)
      }
      else{
        setShown(true)
        document.getElementsByClassName('optionalBox')[0].className="optionalBox animate__animated animate__fadeInUp"
        setTimeout(() => {
          document.getElementsByClassName('optionalBox')[0].style.display="flex"
        },400)
      } 
    }


    const addTicketing = async () => {
      showLoader()
      if (eventName.length < 3 || startDate === null || (venue ? venue.length === 0 : location ? location.length === 0 : false)) {
        alert("Du musst einen Eventnamen, das Startdatum inklusive Startzeit, und eine Location angeben, um das Ticketing zu aktivieren.");
        hideLoader("loader-ticketing")
        return;
      }
      const id = await props.saveChanges();
      props.setEventID(id);
      window.history.replaceState(null, null, `/dashboard/events/event-bearbeiten/${id}` + isParentevent ? `?viewParent=true` : "")
      setShowModal(true);
      hideLoader("loader-ticketing")
    }

    useEffect(() => {
      if(!searchParams.has('ticketing_data')) return;
      setShowModal(true);
    }, [searchParams]);

    return (
      <div className="ErstellenInputs">
        <div className="InputsItem" >
          <EventTitelInput suffix={props.suffix} handleCustomizationChange={handleCustomizationChange} onChange={setEventName} eventName={eventName} ></EventTitelInput>
        </div>
        <div className="InputsItem">
          <TitelbildInput suffix={props.suffix} handleCustomizationChange={handleCustomizationChange} banner={banner} setBanner={setBanner}></TitelbildInput>
        </div>
        <div className="InputsItem">
          <DatumInput suffix={props.suffix} handleCustomizationChange={handleCustomizationChange} beschreibung={beschreibung} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} state={state} setState={setState} stateRecurring={props.stateRecurring} setStateRecurring={props.setStateRecurring} recurrence={recurrence} recurringEndDate={recurringEndDate} setRecurringEndDate={setRecurringEndDate} setRecurrence={setRecurrence} isSubEvent={isSubEvent}></DatumInput>
        </div>
        <div className="InputsItem">
          <AdresseInput suffix={props.suffix} secretLocationRevealTime={secretLocationRevealTime} setSecretLocationRevealTime={setSecretLocationRevealTime}  handleCustomizationChange={handleCustomizationChange} location={location} venue={venue} setVenue={setVenue} city={city} setCity={setCity} setLocation={setLocation} state={state2} setState={setState2} land={land} setLand={setLand} region={region} setRegion={setRegion} darstellen={darstellen} setDarstellen={setDarstellen} secretLocation={secretLocation} setSecretLocation={setSecretLocation}></AdresseInput>
        </div>
        <div className="InputsItem">
          <GenreInput suffix={props.suffix} handleCustomizationChange={handleCustomizationChange} genre={genre} setGenre={setGenre}></GenreInput>
        </div>
        <div className="InputsItem">
          <EintrittInput suffix={props.suffix} handleCustomizationChange={handleCustomizationChange} stateRecurring={props.stateRecurring} setEdit={setEdit} addTicketing={addTicketing} lowestPrice={lowestPrice} ticketingDone={ticketingDone} setShowModal={setShowModal} eventName={eventName} startDate={startDate} location={location} venue={venue} ticketLink={ticketLink} setTicketLink={setTicketLink} ticketpreisVorverkauf={ticketpreisVorverkauf} setTicketpreisVorverkauf={setTicketpreisVorverkauf} ticketpreisAbendkasse={ticketpreisAbendkasse} setTicketpreisAbendkasse={setTicketpreisAbendkasse} stateAbendkasse={stateAbendkasse} setStateAbendkasse={setStateAbendkasse} stateVorverkauf={stateVorverkauf} setStateVorverkauf={setStateVorverkauf} stateFreierEintritt={stateFreierEintritt} setStateFreierEintritt={setStateFreierEintritt} free={free} setFree={setFree} eventForm={props.eventForm}></EintrittInput>
        </div>
        <div className="InputsItem">
          <BeschreibungInput suffix={props.suffix} handleCustomizationChange={handleCustomizationChange} beschreibung={beschreibung} setBeschreibung={setBeschreibung}></BeschreibungInput>
        </div>
        <div className="item" onClick={() => {animateOptionalInp()}}>
          <div>
            <div className="optionalHead">Optionale Angaben</div>
            <div className="optionalSubHead">Diese Angaben verbessern die Eventqualität</div>
          </div>
          <div className="optionalIcon">{shown ? chevronDownIcon : chevronLeftIcon}</div>
        </div>
        <div className="optionalBox" style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
          <div className="InputsItem">
            <LineupInput lineup={lineup} suffix={props.suffix} handleCustomizationChange={handleCustomizationChange} setLineup={setLineup}></LineupInput>
          </div>
          <div className="InputsItem">
            <MinAlterInput minAlter={minAlter} suffix={props.suffix} handleCustomizationChange={handleCustomizationChange} setMinAlter={setMinAlter} state={state3} setState={setState3}></MinAlterInput>
          </div>
          <div className="InputsItem">
            <SpecialsInput specials={specials} suffix={props.suffix} handleCustomizationChange={handleCustomizationChange} setSpecials={setSpecials}></SpecialsInput>
          </div>
          <div className="InputsItem">
            <DresscodeInput dresscode={dresscode} suffix={props.suffix} handleCustomizationChange={handleCustomizationChange} setDresscode={setDresscode} state={state4} setState={setState4}></DresscodeInput>
          </div>
          <div className="InputsItem">
            <VeranstalterInput veranstalter={veranstalter} suffix={props.suffix} handleCustomizationChange={handleCustomizationChange} setVeranstalter={setVeranstalter}></VeranstalterInput>
          </div>
          {props.partner === 'koelnisttechno' && <div className="InputsItem">
            <StoryLinkInput eventName={eventName} suffix={props.suffix} handleCustomizationChange={handleCustomizationChange} stickerBooked={stickerBooked} setStickerBooked={setStickerBooked} storyLink={storyLink} setStoryLink={setStoryLink}></StoryLinkInput>
          </div>}
          {!props.eventForm && <div className="InputsItem">
            <VerlinkungenInput syncToExternal={syncToExternal} suffix={props.suffix} handleCustomizationChange={handleCustomizationChange} setSyncToExternal={setSyncToExternal}></VerlinkungenInput>
          </div>}
        </div>
        <div style={{height: 50}}>&nbsp;</div>
        <ModalTicketing handleCustomizationChange={handleCustomizationChange} edit={edit} setShowSuccess={props.setShowSuccess} setData={props.setModalData} setLowestPrice={setLowestPrice} setTicketingDone={setTicketingDone} startDate={startDate} startTime={startTimeModal}  venue={venue} location={location} eventName={eventName} showModal={showModal} setShowModal={setShowModal} eventID={id??props.eventID}></ModalTicketing>
        <LoaderDivWithOverlay id={"loader-ticketing"} />
      </div>
    );
  }

  function TitelbildInput(props) {

    function pressUploadBtn() {
      document.getElementById("getFile")?.click();
    }
    
    useEffect(() => {(async () => {
      if(!props.banner) return;
      document.getElementById("output_image").style.visibility='visible';
      document.getElementById('UploadBtn').style.visibility='hidden';
      change_color(`titelbildIconNav${props.suffix}`, `titelbildIconInput${props.suffix}`);
      const downloadUrl = await getDownloadURL(ref(storage, props.banner));
      document.getElementById('output_image').src = downloadUrl;
    })()}, [props.banner]);

    return (
      <div className="InputBox" id="Titelbild">
        <div className="InputBoxHeader">
          <div className="InputCircle" id={`titelbildIconInput${props.suffix}`}>{titelbildIcon}</div>
          <div className="InputBoxTitle">
            <div className="InputName">Titelbild</div>
            <div className="InputDetails">Wähle ein ansprechendes Bild.</div>
          </div>
        </div>
        <div className="flex col-flex" style={{gap: "10px"}}>
          <div>
            <div className="BildBorder" id="BildBorder">
              <button
                onClick={pressUploadBtn}
                title="Upload your Image"
                className="UploadBtnErstellen"
                id="UploadBtn"
              >
                <div id="logoupload">Bild Auswählen</div>
                <input
                  type={"file"}
                  id="getFile"
                  className="imgInp"
                  accept="image/png ,image/jpeg, image/jpg"
                  onChange={(evt, i) => {
                    // preview_image_event(evt, i);
                    props.handleCustomizationChange("images", [evt.target.files[0].name])
                    var reader = new FileReader();
                    reader.onload = function(){
                        var output = document.getElementById('output_image');
                        output.src = reader.result;
                        document.getElementById("output_image").style.visibility='visible';
                        document.getElementById('UploadBtn').style.visibility='hidden';
                    }
                    reader.readAsDataURL(evt.target.files[0]);
                    const file = evt.target.files[0]
                    const reference = ref(storage,"/event_images/temp/" + file.name)
                    uploadBytes(reference,file)
                    .then(() => {
                        props.setBanner(reference.fullPath)
                      })
                      .catch(e => console.log(e))
                      change_color(`titelbildIconNav${props.suffix}`, `titelbildIconInput${props.suffix}`);
                    }}
                  />
              </button>
              <img id="output_image" alt=" " className="output_image_style"/>
              {props.banner !== null && <button
                onClick={() => {
                if(props.banner != null)
                {
                  rechange_color(`titelbildIconNav${props.suffix}`, `titelbildIconInput${props.suffix}`) 
                  document.getElementById("output_image").style.visibility='hidden';
                  document.getElementById('UploadBtn').style.visibility='visible';
                  props.setBanner(null)
                  deleteObject(ref(storage,props.banner))
                } 
                }}
                id="delete"
                title="Delete your Image"
              >
                <div id="delete-icon">{deleteIcon}</div>
              </button>}
            </div>
          </div>
          <div>
          <div className="recoSize">Empfohlene Größe: 1350px x 1080px</div>
          </div>
        </div>
      </div>
    );
  }
  
  function EventTitelInput(props) {

    function checkName(value) {
      if (value.length < 3) {
        rechange_color(`nameIconNav${props.suffix}`, `nameIconInput${props.suffix}`);
      } else {
        change_color(`nameIconNav${props.suffix}`, `nameIconInput${props.suffix}`);
      }
    }

    useEffect(() => {
      checkName(props.eventName);
    }, [props]); 
       
    return (
      <div className="InputBox" id="Eventname" key={"EventNameDiv"}>
        <div className="InputBoxHeader">
          <div className="InputCircle" id={`nameIconInput${props.suffix}`} >{nameIcon}</div>
          <div className="InputBoxTitle">
            <div className="InputName">Eventname</div>
            <div className="InputDetails">Gib den Namen des Event ein.</div>
          </div>
        </div>
        <input
          name="Name"
          className="TextInput"
          placeholder="Eventname"
          id="EventNameInput"
          key={"NameInput"}
          value={props.eventName}
          onChange={(e) => {
            props.onChange(e.target.value)
            checkName(e.target.value)
            props.handleCustomizationChange("name", e.target.value);
          }}
        ></input>
      </div>
    );
  }

  function DatumInput(props) { 
    useEffect(() => {
      if(props.endTime != null){
        let d1 = new Date(props.startDate).getTime() 
        let d2 = new Date(props.endDate).getTime()
        if((d1 === d2 || d2 < d1) && (props.startDate || props.endDate) != null){
          props.setEndDate(null)
        }
        else if((props.endDate || props.endTime) != null){
          props.setState(true)
          document.getElementsByClassName('checkboxDate')[0].click()
        }
      }
    },[props.beschreibung])


    useEffect(() => {
      let complete = false;
      if(props.state === false) {
        if(props.startDate) {
          complete = true;
        }
      } else {
        if(props.startDate && props.endDate) {
          complete = true;
        }
      }

      if(complete) {
        change_color(`dateIconNav${props.suffix}`, `dateIconInput${props.suffix}`);
      } else {
        rechange_color(`dateIconNav${props.suffix}`, `dateIconInput${props.suffix}`);
      }
      
      if(props.endDate < props.startDate){
        props.setEndDate(props.startDate)
      }
      
    }, [props.startDate, props.endDate, props.state]);

    const handleChange = (name,date) => {
      props.handleCustomizationChange(name, date)
      if(name === "startDate"){
        props.setStartDate(date)
      }
      else if(name === "endDate"){
        props.setEndDate(date)
      }
      else{
        console.error("Invalid 'name' props passed to 'handleChange' function.")
      }
    };

    return (
      <div className="InputBox" id="Datum">
        <div className="InputBoxHeader">
          <div className="InputCircle" id={`dateIconInput${props.suffix}`}>{datumIcon}</div>
          <div className="InputBoxTitle">
            <div className="InputName">Datum & Uhrzeit</div>
            <div className="InputDetails">Gib an, wann dein Event beginnt.</div>
          </div>
        </div>
        <div className="flex-gap-10">
        <DatePicker
          selected={props.startDate}
          onChange={(date) => handleChange("startDate",date)}
          dateFormat="dd.MM.yyyy HH:mm"
          className="TextInput"
          showTimeSelect
          onCalendarClose={() => {if(props.startDate < new Date() && props.startDate !== null ){
            props.setStartDate(new Date())
            toast.warning("Die Startzeit kann nicht in der Vergangenheit liegen!",{theme:"dark"})
          }
          }}
          timeIntervals={30}
          style={{ flex: 1 }}
          minDate={new Date()}
          placeholderText={props.stateRecurring ? "Start des ersten Events" : "Startzeitpunkt"}
          locale="de"
          />
        </div>
        <div className= "flex-gap-10" style={{marginTop: "10px", marginBottom: "10px"}}>
          <div id="festesEnde">Festes Ende</div>
          <label className="switch">
            <input
              type="checkbox"
              id="endDateSwitch"
              className="checkboxDate"
              checked={props.state}
              onClick={() => {
                if(props.state){
                  props.setEndDate(new Date())
                  props.setState(false)
                }
                else{
                  props.setState(true);
                }
              }}
              />
            <span className="slider round"></span>
          </label>  
        </div>
        <div id="festesDatum" className="flex-gap-10" style={props.state ? {display:"flex"} : {display:"none"}}>
          <DatePicker
            selected={props.endDate}
            showTimeSelect
            onChange={(date) => handleChange("endDate",date)}
            dateFormat="dd.MM.yyyy HH:mm"
            className="TextInput"
            style={{ flex: 1 }}
            minDate={props.startDate}
            placeholderText={props.stateRecurring ? "Ende des ersten Events" : "Endzeitpunkt"}
            locale="de"
          />
        </div>
        {!props.isSubEvent && <div className= "flex-gap-10" style={{marginTop: "10px", marginBottom: "10px"}}>
          <div id="festesEnde">Wiederholung</div>
          <label className="switch">
            <input
              type="checkbox"
              id="reoccurenceSwitch"
              className="checkboxDate"
              checked={props.stateRecurring}
              onChange={() => {
                if(props.stateRecurring){
                  props.setStateRecurring(false)
                }
                else{
                  props.setStateRecurring(true);
                }
              }}
              />
            <span className="slider round"></span>
          </label> 
        </div>}
         {props.stateRecurring && 
         <div className="recorrunceRow">          
          <select name="reoccurence" className='selectReoccurance' value={props.recurrence} onChange={(e) => props.setRecurrence(e.target.value)}>
                <option value={null}>Wähle die Wiederholungsperiode aus:</option>
                <option value="weekly">{props.startDate ? 'Jeden ' + weekdays[props.startDate.getDay()] : 'Jede Woche'}</option>
                <option value="monthly">{props.startDate ? 'Jeden ' + props.startDate.getWeekOfMonth() + '. ' + weekdays[props.startDate.getDay()] + ' im Monat' : 'Jeden Monat'}</option>
            </select>
            <DatePicker
            selected={props.recurringEndDate}
            onChange={props.setRecurringEndDate}
            dateFormat="dd.MM.yyyy"
            className="TextInput"
            style={{ flex: 1 }}
            minDate={props.startDate}
            placeholderText={"Ende der Eventreihe"}
            locale="de"
          />
         </div>}
      </div>
    );
  }

  function AdresseInput(props) {
    useEffect(() => {
      if(props.location || props.venue) {
        change_color(`addressIconNav${props.suffix}`,`addressIconInput${props.suffix}`);
      } else {
        rechange_color(`addressIconNav${props.suffix}`,`addressIconInput${props.suffix}`);
      }
    }, [props.location, props.venue]);

    return (
      <div className="InputBox" id="Adresse">
        <div className="InputBoxHeader">
          <div className="InputCircle" id={`addressIconInput${props.suffix}`}>{adresseIcon}</div>
          <div className="InputBoxTitle">
            <div className="InputName">Location</div>
            <div className="InputDetails">Suche nach der Location oder gib die Adresse an.</div>
          </div>
        </div>
        <LocationInput
          className="TextInput mb-10p"
          placeholder="Adresse/Location eingeben"
          address={props.location}
          city={props.city}
          venue={props.venue}
          setAddress={(e) => {
            props.setLocation(e);
          }}
          setCity={(e) => {
            props.setCity(e);
          }}
          setVenue={(e) => {
            props.setVenue(e);
          }}
          // handleCustomInput={props.handleCustomizationChange("location", props.location)}
        ></LocationInput>
        <div className="col-flex flex-gap-10px ">
          <div id="secretLocation" className="text-color__main">Secret Location</div>
          <label className= "switch">
              <input
              type="checkbox"
              id="secretLocationSwitch"
              value={props.secretLocation}
              // disabled={props.secretLocation}
              onClick={() => {
                document.getElementById("secretLocationReveal").classList.toggle("d-none"); 
                props.setSecretLocation(!props.secretLocation);
              }}
              />
            <span className="slider round"></span>
          </label>
          <select name="secretLocationReveal" id="secretLocationReveal" className='TextInputSelect d-none' value={props.secretLocationRevealTime} onChange={(e) => props.setSecretLocationRevealTime(e.target.value)}>
            <option value={null}>Wann soll die Location bekannt gegeben werden?</option>
            <option value="1h">1 Stunde vor Eventbeginn</option>
            <option value="3h">3 Stunden vor Eventbeginn</option>
            <option value="5h">5 Stunden vor Eventbeginn</option>
            <option value="7h">7 Stunden vor Eventbeginn</option>
            <option value="24h">1 Tag vor Eventbeginn</option>
          </select>
        </div>
      </div>
    );
  }

  function GenreInput(props) {
    useEffect(() => {
      if(props.genre?.length > 0) {
        change_color(`genreIconNav${props.suffix}`,`genreIconInput${props.suffix}`);
      } else {
        rechange_color(`genreIconNav${props.suffix}`,`genreIconInput${props.suffix}`);
      }
    }, [props.genre]);

    const [genreList, setGenreList] = useState([]);
    const [idk, setIdk] = useState("");

    useEffect(() => { getDocs(collection(firestore, 'genres')).then(e => setGenreList(e.docs)) }, []);

    return (
      <div className="InputBox" id="Genre">
        <div className="InputBoxHeader">
          <div className="InputCircle" id={`genreIconInput${props.suffix}`}>{genreIcon}</div>
          <div className="InputBoxTitle">
            <div className="InputName">Musikgenre</div>
            <div className="InputDetails">Wähle gerne mehrere Genres aus.{" "}</div>
          </div>
        </div>
          <select className="TextInputSelect" onChange={(e) => {!props.genre.includes(e.currentTarget.value) && props.setGenre(props.genre.concat([e.currentTarget.value]));props.handleCustomizationChange("genre",[...props.genre,e.currentTarget.value]); e.currentTarget.value = 'null'}}>
            <option value="null">Bitte auswählen</option>
            {genreList.map(e => <option key={e.id} value={e.id}>
              {e.data().de}
            </option>)}
          </select>
        <div>
          <div id="ausgewaehlteGenres">Ausgewählte Genre:</div>
          <div className="GenreBox">
            {props.genre.map((item) => (
              <div
                id="GenreBoxItem"
                key={item}
                onClick={() => {
                  var index = props.genre?.indexOf(item);
                  if (index > -1) {
                    // Create a new array without the item
                    let tmp = [...props.genre];
                    tmp.splice(index, 1);
                    props.setGenre(tmp);
                    props.handleCustomizationChange("genre", tmp);
                    if(tmp.length === 0) {
                      rechange_color(`genreIconNav${props.suffix}`,`genreIconInput${props.suffix}`);
                      props.setGenre([]); // Ensure a new array is set
                    }
                    setIdk(!idk);
                  }
                }}
                className="genreButton"
              >
                <div className="ItemText">{genreList.find(e => e.id === item)?.data().de}</div>
                <div>{cancelIcon}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  function LineupInput(props) {
    useEffect(() => {
      if(props.lineup?.length > 0) {
        change_color(`lineupIconNav${props.suffix}`,`lineupIconInput${props.suffix}`);
      } else {
        rechange_color(`lineupIconNav${props.suffix}`,`lineupIconInput${props.suffix}`);
      }
    }, [props.lineup]);

    const [idk, setIdk] = useState("");

    const [artistList, setArtistList] = useState([]);
    useEffect(() => { props.lineup && Promise.all(Array.from(props.lineup).map(e => e.startsWith('$') ? { id: e, data: () => ({ name: e.substring(1)})} : getDoc(doc(collection(firestore, 'artists'), e)))).then(e => setArtistList(e)) }, [props.lineup]);

    return (
      <div className="InputBox" id="Lineup">
        <div className="InputBoxHeader">
          <div className="InputCircle" id={`lineupIconInput${props.suffix}`}>{lineupIcon}</div>
          <div className="InputBoxTitle">
            <div className="InputName">Lineup</div>
            <div className="InputDetails">Bitte benutz die richtige Schreibweise der Künstler.</div>
          </div>
        </div>
        <ArtistInput
          key={props.lineup}
          className="TextInput"
          placeholder="Artist finden..."
          onSelect={(e) => {!props.lineup.includes(e) && props.setLineup(props.lineup.concat([e]));props.handleCustomizationChange("lineup",[...props.lineup,e]);}}
        ></ArtistInput>
        <div>
          <div id="ausgewaehlteGenres">Ausgewählte Künstler:</div>
          <div className="GenreBox">
            {props.lineup?.map((item) => (
              <div
                id="GenreBoxItem"
                className="genreButton"
                key={item}
                onClick={() => {
                  var index = props.lineup?.indexOf(item);
                  if (index > -1) {
                    // Create a new array without the item
                    let tmp = [...props.lineup];
                    tmp.splice(index, 1);
                    props.setLineup(tmp);
                    props.handleCustomizationChange("lineup", tmp);
                    if(tmp.length === 0) {
                      rechange_color(`lineupIconNav${props.suffix}`,`lineupIconInput${props.suffix}`,);
                      props.setLineup([]); // Ensure a new array is set
                    }
                    setIdk(!idk);
                  }
                }}
              >
                <div className="ItemText">{artistList.find(e => e.id === item)?.data()?.name}</div>
                <div>{cancelIcon}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  function EintrittInput(props) {
    // eslint-disable-next-line no-unused-vars
    const [isLink,setIsLink] = useState(false);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    let functionRunAfterTicketingDone = false
  useEffect(() => {
      if (props.stateAbendkasse && (props.ticketpreisAbendkasse !== 0 && props.ticketpreisAbendkasse !== "") ){
        change_color(`eintrittIconNav${props.suffix}`,`eintrittIconInput${props.suffix}`);      
      }
      else if(props.stateVorverkauf && (props.ticketpreisVorverkauf !== "") ){
        change_color(`eintrittIconNav${props.suffix}`,`eintrittIconInput${props.suffix}`);      
      }
      else if(props.ticketingDone && functionRunAfterTicketingDone !== true){
        functionRunAfterTicketingDone = true
        change_color(`eintrittIconNav${props.suffix}`,`eintrittIconInput${props.suffix}`);
      }
      else if(props.free){
        change_color(`eintrittIconNav${props.suffix}`,`eintrittIconInput${props.suffix}`);
      }
      else {
        rechange_color(`eintrittIconNav${props.suffix}`,`eintrittIconInput${props.suffix}`);
      }
    },[props.ticketpreisAbendkasse,props.ticketpreisVorverkauf,props.free,props.stateAbendkasse,props.stateVorverkauf,props.ticketingDone,props.lowestPrice])

    function checkLink() {
      const linkRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_.~#?&=]*)$/
      if(linkRegex.test(props.ticketLink)){
          setIsLink(true)
      }
      else{
        setIsLink(false)
      }
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isFullWidth = windowWidth > 768;
   

const [enterd,setEntered] = useState(false)
    return (
      <div className="InputBox" id="Eintritt">
        <div className="InputBoxHeader">
          <div className="InputCircle" id={`eintrittIconInput${props.suffix}`}>{eintrittIcon}</div>
          <div className="InputBoxTitle">
            <div className="InputName">Eintritt</div>
            <div className="InputDetails">Wähle wo deine Gäste Tickets kaufen können</div>
          </div>
        </div>
         <div className="col-flex flex-gap-1rem" style={{width:"100%",margin:"auto"}}>
                {!props.eventForm && !props.free && !props.stateRecurring && <div className="row-flex justify_center align_center" onMouseEnter={() => {setEntered(true)}} onMouseLeave={() => {setEntered(false)}}>
                <div className="createTicketingBtn" style={props.ticketingDone ? enterd ? {backgroundColor:"var(--blue"} : {backgroundColor:"var(--green)"} : {backgroundColor:"var(--blue)"}} onClick={() => {if(props.ticketingDone){ props.setEdit(true) } props.addTicketing()}}>
                  <div style={{fontWeight: "600", fontSize: "var(--fontsize-medium)", color: "var(--buttonCoMain)"}}>{props.ticketingDone ? enterd ? "ELGIO Ticketing bearbeiten" : "ELGIO Ticketing aktiviert" : "ELGIO Ticketing aktivieren"}</div>
                </div>
                <div style={{color: "var(--buttonCoMain)", fontSize: "var(--fontsize-regular)", cursor: "pointer",position:"relative",right:"40px",width:"0%"}} onClick={handleShow}>{infoIcon}</div>
                <Benefits show={show} handleClose={handleClose} />
                </div>}

              {!props.ticketingDone && !props.free && (
            <div>
                <div className="col-flex flexTileEintritt">
                  <div className= "row-flex justify_space_between" style={{alignItems: "center"}}>
                      <div className= "tileTextEintritt">Es gibt einen Vorverkauf</div>
                      <label className= "switch" style={{margin: "15px"}}>
                          <input
                          type="checkbox"
                          className="checkboxEintritt"
                          id="vvkSwitch"
                          disabled={props.ticketingDone}
                          checked={props.stateVorverkauf}
                          onChange={() => {
                            if(!props.stateVorverkauf){
                              props.setTicketpreisVorverkauf("")
                            }
                            props.setStateVorverkauf(!props.stateVorverkauf);
                          }}
                          />
                        <span className="slider round"></span>
                      </label>
                  </div>

                  <div className="preSaleRow" style={props.stateVorverkauf ? {margin:"15px", marginTop:"0"} : {margin:"0px 15px",display:"none"}}>
                  <div className="col-flex gap-1">
                    <div className="input-title">Ticketpreis</div>
                    <div className="row-flex align_center">
                        <input
                          type={"text"}
                          maxLength={"4"}
                          style={{backgroundColor:"var(--grey)"}}
                          className="TextInput"
                          id="TicketLink"
                          placeholder="z.B. 30,00"
                          disabled={props.ticketingDone}
                          value={props.ticketpreisVorverkauf??""}
                          onChange={(e) => {props.setTicketpreisVorverkauf(e.target.value);props.handleCustomizationChange("price", e.target.value)}}
                      ></input>
                      <div className="t-white" style={isFullWidth ? {marginLeft: "-7%"} : {marginLeft: "-20px"}}>{euroIcon}</div>
                    </div>
                  </div>
                  <div className="col-flex gap-1" style={ isFullWidth ? {width: "100%"} : {width: "75%"}}>
                    <div className="input-title">Ticketstore-Link (optional)</div>
                        <input
                          type={"url"}
                          style={{backgroundColor:"var(--grey)"}}
                          className="TextInput"
                          id="TicketLink"
                          disabled={props.ticketingDone}
                          placeholder="z.B. https://elgio.de/event/hEhj3UDxieFzx5IB2PBI/checkout..."
                          value={props.ticketingDone ? "ELGIO Ticketing" : props.ticketLink ?? ""}
                          onChange={(e) => {props.setTicketLink(e.target.value);props.handleCustomizationChange("ticket_link", e.target.value)}}
                          onBlur={() => {checkLink()}}
                          ></input>
                          </div>
                  </div>
                </div>
            </div>   
              )}
           
            {!props.free && <div className="col-flex flexTileEintritt">
            <div>
                <div className="row-flex justify_space_between" style={{alignItems: "center"}}>
                <div className="tileTextEintritt">Es gibt eine Abendkasse</div>
                <label className="switch" style={{margin:"15px"}}>
                  <input
                    type="checkbox"
                    className="checkboxEintritt"
                    id="akSwitch"
                    checked={props.stateAbendkasse}
                    onChange={() => {
                      props.setStateAbendkasse(!props.stateAbendkasse);
                      if(!props.stateAbendkasse){
                        props.setTicketpreisAbendkasse("")
                      }
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="row-flex justify_space_between flex-gap-1rem"style={props.stateAbendkasse  ? {margin:"15px", marginTop:"0"} : {margin:"0px 15px",display:"none"}}>
                <div className="col-flex gap-1">
                  <div className="input-title">Ticketpreis</div>
                  <div className="row-flex align_center">
                      <input
                        type={"text"}
                        maxLength={"4"}
                        style={{backgroundColor:"var(--grey)"}}
                        className="TextInput"
                        id="TicketLink"
                        placeholder="z.B. 30,00"
                        value={props.ticketpreisAbendkasse ?? ""}
                        onChange={(e) => {props.setTicketpreisAbendkasse(e.target.value);props.handleCustomizationChange("box_ticket_price", e.target.value)}}
                        ></input>
                    <div style={{marginLeft:"-15%", color: "var(--buttonCoMain)"}}>{euroIcon}</div>
                  </div>
                </div>
                        
                <div className="col-flex" style={{width:"75%",visibility:"hidden"}}>
                  <div className="input-top-label">
                  </div>
                      <input style={{backgroundColor:"var(--grey)"}} className="TextInput"></input>
                  </div>
                </div>
                </div>
           </div>
          }
           {!props.ticketingDone && (   
              <div>
                <div className="row-flex flexTileEintritt justify_space_between" style={{alignItems: "center"}}>
                  <div className="tileTextEintritt">Es gibt freien Eintritt</div>
                  <label className="switch" style={{margin:"15px"}}>
                    <input
                      type="checkbox"
                      className="checkboxEintritt"
                      id="freeSwitch"
                      checked={props.free}
                      onChange={() => {
                        props.setStateFreierEintritt(!props.stateFreierEintritt);
                        props.setFree(!props.free)
                        props.handleCustomizationChange("free", !props.free)
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            )} 
         </div>
      </div>
    );
  }

  function BeschreibungInput(props) {
    function check(value) {
      if (value.length < 3) {
        rechange_color(`descriptionIconNav${props.suffix}`, `descriptionIconInput${props.suffix}`);
      } else {
        change_color(`descriptionIconNav${props.suffix}`, `descriptionIconInput${props.suffix}`);
      }
    }
    useEffect(() => { props.beschreibung && check(props.beschreibung) }, [props.beschreibung]);
    return (
      <div className="InputBox" id="Beschreibung">
        <div className="InputBoxHeader">
          <div className="InputCircle" id={`descriptionIconInput${props.suffix}`}>{beschreibungIcon}</div>
          <div className="InputBoxTitle">
            <div className="InputName">Beschreibung</div>
            <div className="InputDetails">Erzähl deinen Gästen, was sie erwarten wird.</div>
          </div>
        </div>
        <textarea
          draggable="false"
          id="beschreibung"
          className="outline-none"
          value={props.beschreibung}
          placeholder="z.B. Erlebe elektrisierenden Techno beim SAMSTAGSRAVE im Nightlife mit 2 Floors" 
          onChange={(e) => {props.setBeschreibung(e.target.value);check(e.target.value);props.handleCustomizationChange("description", e.target.value)}}
          onBlur={() => {}}
          style={{resize:"none"}}
        ></textarea>
      </div>
    );
  }

  function StoryLinkInput(props) {
    function check(value) {
      if (value?.length < 3) {
        //rechange_Text_color("InputName", "iconBeschreibung", 10);
        rechange_color(`storyLinkIconNav${props.suffix}`, `storyLinkIconInput${props.suffix}`);
      } else {
        //change_Text_color("iconBeschreibung", "InputName", 10);
        change_color(`storyLinkIconNav${props.suffix}`, `storyLinkIconInput${props.suffix}`);
      }
    }
    useEffect(() => check(props.storyLink), [props.storyLink]);

    return (
      <div className="InputBox" id="StoryLink">
        <div className="InputBoxHeader">
          <div className="InputCircle" id={`storyLinkIconInput${props.suffix}`}>
            {storyLinkIcon}
          </div>
          <div style={{marginLeft: 10}}>
            <div className="InputName">Link Sticker auf der KoelnIstTechno Story</div>
            <div className="InputDetails">
              Für eine kleine Bearbeitungsgebühr von 20,00EUR fügen wir einen Link auf deiner Story ein<br />
              (z.B. Link zum Ticketshop oder Facebook-Event)
            </div>
          </div>
        </div>
        <div className="flex-gap-10">
          <div style={{color: 'white', fontSize: '110%'}}>Mit Link Sticker</div>
          {/* Start of Checkbox */}
          <label className="switch">
            <input
              type="checkbox"
              onClick={() => {
                props.setStickerBooked(!props.stickerBooked);
              }}
              value={props.stickerBooked}
            />
            <span className="slider round"></span>
          </label>
          {/* End of Checkbox */}
        </div>
        {props.stickerBooked && <>
          <div className="input-top-label">
            Füge deinen Link hier ein:
          </div>
          <input
            type={"url"}
            className="TextInput"
            id="TicketLink"
            placeholder="Link einfügen"
            value={props.storyLink}
            onChange={(e) => props.setStoryLink(e.target.value)}
          ></input>
          <div style={{color: 'white', fontSize: '90%', marginTop: 10}}>
            Bitte schicke uns die Bearbeitungsgebühr in Höhe von 20,00EUR unverzüglich per PayPal an <b>koelnisttechno.official@gmail.com</b> mit dem Hinweis "{props.eventName || '[EVENTNAME]'} Story-Link"
          </div>
        </>}
      </div>
    );
  }

  function MinAlterInput(props) {
    useEffect(() => {
      if (props.minAlter !== null) {
        change_color(`minAlterIconNav${props.suffix}`, `minAlterIconInput${props.suffix}`);
      } else {
        rechange_color(`minAlterIconNav${props.suffix}`, `minAlterIconInput${props.suffix}`);
      }
    }, [props.minAlter]);
  
    const [activeEle, setActiveEle] = useState("");
  
    const changeColor = (id) => {
      if (activeEle === id) {
        props.setMinAlter(null);
        setActiveEle("");
      } else {
        setActiveEle(id);
        props.setMinAlter(id);
      }
    };
  
    return (
      <div className="InputBox" id="MinAlter">
        <div className="InputBoxHeader">
          <div className="InputCircle" id={`minAlterIconInput${props.suffix}`}>{minAlterIcon}</div>
          <div className="InputBoxTitle">
            <div className="InputName">Mindestalter</div>
            <div className="InputDetails">Wähle das Mindestalter aus.</div>
          </div>
        </div>
        <div className="selectMinAlter">
        {['16', '18', '21', '0', 'custom'].map(age => {
        return (
          <div
            key={age}
            className={`selectMinAlterItem ${activeEle === age ? 'active' : ''}`}
            onClick={() => {
              const newActiveEle = activeEle === age ? null : age;
              props.handleCustomizationChange(
                "min_age",
                newActiveEle === 'custom' ? props.minAlter : newActiveEle === null ? null : parseInt(age, 10)
              );
              changeColor(newActiveEle);
            }}
            id={age} // Here, the div's id is set to the age value
          >
            <div id="alterNum">{age === '0' ? 'Keins' : age === 'custom' ? 'Anderes' : `${age} Jahre`}</div>
            {activeEle === age && cancelIcon}
          </div>
        );
      })}
    {activeEle === 'custom' && (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        <input
          type="number"
          id="minAlterInp"
          value={String(isNaN(parseInt(props.minAlter)) ? '' : parseInt(props.minAlter))}
          min={1}
          maxLength={2}
          onChange={e => {
            const value = parseInt(e.target.value, 10);
            const sanitizedValue = isNaN(value) ? 0 : value;
            props.setMinAlter(sanitizedValue);
            props.handleCustomizationChange("min_age", sanitizedValue);
          }}
        />
      </div>
    )}
        </div>
      </div>
    );
  }

  function SpecialsInput(props) {
    const addSpecials =  (index,value) => {
      let tmp = props.specials
      tmp[index] = value
      props.setSpecials(Array.from(tmp))
    }

     function removeSpecials(i) {
      let tmp = props.specials;
      tmp.splice(i, 1);
      props.setSpecials(Array.from(tmp));
    }

    useEffect(() => {
      if(props.specials.length !== 0 && !props.specials.includes("")) {
        change_color(`specialsIconNav${props.suffix}`, `specialsIconInput${props.suffix}`);
      } else {
        rechange_color(`specialsIconNav${props.suffix}`, `specialsIconInput${props.suffix}`);
      }
    }, [props.specials]);



    return (
      <div className={props.specials.length !== 0 ? "InputBox col-flex flex-gap-1rem" : "InputBox col-flex"} id="Specials">
        <div className="InputBoxHeader">
          <div className="InputCircle" id={`specialsIconInput${props.suffix}`}>{specialIcon}</div>
          <div className="InputBoxTitle">
            <div className="InputName">Specials</div>
            <div className="InputDetails">Gib an, welche Specials deine Gäste erwarten können.</div>
          </div>
        </div>
        <div className="specialsBox">
          {props.specials.map((element,index) => {
            return(
              <div key={`specials-${index}`} className='specialsRow'>
                <input
                  name="specials"
                  id="specialInput"
                  className="TextInput"
                  placeholder="z.B. Freier Eintritt bis 23:00 Uhr ..."
                  value={props.specials[index]}
                  onChange={(e) => {addSpecials(index,e.target.value);props.handleCustomizationChange("specials", props.specials)}}
                ></input>

                <div className="row-flex justify_center align_center">
                    <div style={{color: 'var(--grey)', fontSize: "var(--fontsize-large)", cursor: "pointer"}} onClick={() => {removeSpecials(index);props.handleCustomizationChange("specials", props.specials)}}>{minusIcon}</div>
                </div>
              </div>
            )
          })} 
        </div>

          <div id="addSpecialsBtn" onClick={() => {props.setSpecials((prev) => [...prev,""])}}>
            {plusIcon}
            Weiteres Special hinzufügen
          </div>
      </div>
    );
  }


  function DresscodeInput(props) {
    const noDresscode = 'Komm wie du willst';

    useEffect(() => {
      if(props.dresscode?.length >= 3) {
        change_color(`dresscodeIconNav${props.suffix}`, `dresscodeIconInput${props.suffix}`);
      } else {
        rechange_color(`dresscodeIconNav${props.suffix}`, `dresscodeIconInput${props.suffix}`);
      }

      if(props.dresscode === noDresscode) {
        document.getElementById("noDresscodeBtn").style.backgroundColor ="var(--bg7)";
        document.getElementById("noDresscodeBtn").style.color = "var(--textThird)";

      } else {
        document.getElementById("noDresscodeBtn").style.backgroundColor ="var(--blue)";
        document.getElementById("noDresscodeBtn").style.color = "var(--buttonCoMain)";
      }
    }, [props.dresscode]);

    return (
      <div className="InputBox" id="Dresscode">
        <div className="InputBoxHeader">
          <div className="InputCircle" id={`dresscodeIconInput${props.suffix}`}>{dresscodeIcon}</div>
          <div className="InputBoxTitle">
            <div className="InputName">Dresscode</div>
            <div className="InputDetails">Definiere den genauen Dresscode.</div>
          </div>
        </div>
        <div className="flex-gap-10">
          <input
            className="TextInput"
            placeholder="z.B. Nur weiße T-Shirts ..."
            value={props.dresscode??''}
            onClick={() => {}}
            style={{width: "100%"}}
            onChange={(e) => {props.setDresscode(e.target.value?.length === 0 ? null : e.target.value);props.handleCustomizationChange("dresscode", e.target.value);}}
          ></input>
          <div style={{color: 'var(--textMain)'}}>oder</div>
          <div
            id="noDresscodeBtn"
            onClick={() => {
              props.setDresscode(props.dresscode === noDresscode ? null : noDresscode);
              props.handleCustomizationChange("dresscode", props.dresscode === noDresscode ? null : noDresscode);
            }}
          >
            Kein&nbsp;Dresscode
          </div>
        </div>
      </div>
    );
  }

  function VeranstalterInput(props) {
    useEffect(() => {
      if(props.veranstalter?.length > 0) {
        change_color(`coVeranstalterIconNav${props.suffix}`, `coVeranstalterIconInput${props.suffix}`);
      } else {
        rechange_color(`coVeranstalterIconNav${props.suffix}`, `coVeranstalterIconInput${props.suffix}`);
      }
    }, [props.veranstalter]);

    const [idkOrganizer, setIdkOrganizer] = useState("");

    const [organizerList, setOrganizerList] = useState([]);

    useEffect(() => { props.veranstalter && Promise.all(Array.from(props.veranstalter).map(e => e.startsWith('$') ? { id: e, data: () => ({ name: e.substring(1)})} : getDoc(doc(collection(firestore, 'organizers'), e)))).then(e => setOrganizerList(e)) }, [props.veranstalter]);

    return (
      <div className="InputBox" id="Veranstalter">
        <div className="InputBoxHeader">
          <div className="InputCircle" id={`coVeranstalterIconInput${props.suffix}`}>{coVeranstalterIcon}</div>
          <div className="InputBoxTitle">
            <div className="InputName">(Co-)Veranstalter</div>
            <div className="InputDetails">Gib an, wer dieses Event (mit) veranstaltet.</div>
          </div>
        </div>
        <OrganizerInput
          key={props.veranstalter}
          className="TextInput"
          placeholder="Veranstalternamen eingeben"
          onSelect={e => !props.veranstalter.includes(e) && props.setVeranstalter(props.veranstalter.concat([e]))}
          handleCustomizationChange={props.handleCustomizationChange}
        ></OrganizerInput>
        <div>
          <div id="ausgewaehlteGenres">Ausgewählte Veranstalter:</div>
          <div className="GenreBox">
            {props.veranstalter?.map((item) => (
              <div
                  key={item}
                  id="GenreBoxItem"
                  className="genreButton"
                  onClick={() => {
                    var index = props.veranstalter?.indexOf(item);
                    if (index > -1) {
                      let tmp = props.veranstalter;
                      tmp.splice(index, 1);
                      props.setVeranstalter(tmp);
                      if(tmp.length === 0) rechange_color(`coVeranstalterIconNav${props.suffix}`, `coVeranstalterIconInput${props.suffix}`);
                      setIdkOrganizer(!idkOrganizer);
                    }
                  }}
              >
                  <div className="ItemText">{organizerList.find(e => e.id === item)?.data()?.name}</div>
                  <div>{cancelIcon}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  function VerlinkungenInput(props) {
    const { user } = useContext(UserContext);

    //Facebook Link
    const [facebookPageId, setFacebookPageId] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [facebookPageName, setFacebookPageName] = useState(null);
    const [instagramAccountId, setInstagramAccountId] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [instagramAccountUsername, setInstagramAccountUsername] = useState(null);
    useEffect(() => { getDoc(doc(collection(firestore, 'users'), user.uid)).then(doc => {
      setFacebookPageId(doc.data().facebook_page_id);
      setFacebookPageName(doc.data().facebook_page_name);
      setInstagramAccountId(doc.data().instagram_account_id);
      setInstagramAccountUsername(doc.data().instagram_account_username);
    }) }, [user]);

    useEffect(() => {
      if(props.syncToExternal.length > 0) {
        change_color(`crossPlattformIconNav${props.suffix}`, `crossPlattformIconInput${props.suffix}`);
      } else {
        rechange_color(`crossPlattformIconNav${props.suffix}`, `crossPlattformIconInput${props.suffix}`);
      }
    }, [props.syncToExternal]);

    return (
      <div className="InputBox" id="Verlinkungen">
        <div className="InputBoxHeader">
          <div className="InputCircle" id={`crossPlattformIconInput${props.suffix}`}>{crossPlattformIcon}</div>
          <div className="InputBoxTitle">
            <div className="InputName" id="Verlinkungen">Verlinkungen</div>
            <div className="InputDetails">Poste dein Event automatisch auf anderen Seiten</div>
          </div>
        </div>
        <div className="bigMediaBox">
        <div className="socialMediaBox">
          <div className="leftVerlinkung">
            <div className="verlinkungsIcon">{instagramIcon}</div>
            <div id="festesEnde">Instagram Post</div>
          </div>
          <label className="switch" style={{marginLeft: 'auto'}}>
            <input
              type="checkbox"
              onChange={async (e) => {
                if(!e.target.checked) {
                  props.setSyncToExternal(prev => prev.filter(e => e !== 'instagram_post'));
                  props.handleCustomizationChange('sync_to_external',props.syncToExternal.filter(e => e !== 'instagram_post'));
                  return;
                }
                if(!instagramAccountId) {
                  const { instagramAccountId: id } = await connectToInstagram();
                  if(!id) {
                    e.preventDefault();
                    return;
                  }
                  setInstagramAccountId(id);
                }
                props.handleCustomizationChange('sync_to_external', [...props.syncToExternal, 'instagram_post']);
                props.setSyncToExternal(prev => [...prev, 'instagram_post']);
              }}
              checked={props.syncToExternal.includes('instagram_post')}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="socialMediaBox">
          <div className="leftVerlinkung">
            <div className="verlinkungsIcon">{instagramIcon}</div>
            <div id="festesEnde">Instagram Story</div>
          </div>
          <label className="switch" style={{marginLeft: 'auto'}}>
            <input
              type="checkbox"
              onChange={async (e) => {
                if(!e.target.checked) {
                  props.setSyncToExternal(prev => prev.filter(e => e !== 'instagram_story'));
                  props.handleCustomizationChange('sync_to_external',props.syncToExternal.filter(e => e !== 'instagram_story'));
                  return;
                }
                if(!instagramAccountId) {
                  const { instagramAccountId: id } = await connectToInstagram();
                  if(!id) {
                    e.preventDefault();
                    return;
                  }
                  setInstagramAccountId(id);
                }
                props.handleCustomizationChange('sync_to_external',[...props.syncToExternal, 'instagram_story']); ;
                props.setSyncToExternal(prev => [...prev, 'instagram_story']);
              }}
              checked={props.syncToExternal.includes('instagram_story')}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="socialMediaBox">
          <div className="leftVerlinkung">
            <div className="verlinkungsIcon">{facebookIcon}</div>
            <div id="festesEnde">Facebook Post</div>
          </div>
          <label className="switch" style={{marginLeft: 'auto'}}>
          <input
              type="checkbox"
              onChange={async (e) => {
                if(!e.target.checked) {
                  props.setSyncToExternal(prev => prev.filter(e => e !== 'facebook_post'));
                  props.handleCustomizationChange('sync_to_external',props.syncToExternal.filter(e => e !== 'facebook_post'));
                  return;
                }
                if(!facebookPageId) {
                  const { facebookPageId: id } = await connectToFacebook();
                  if(!id) {
                    e.preventDefault();
                    return;
                  }
                  facebookPageId(id);
                }
                props.handleCustomizationChange('sync_to_external',[...props.syncToExternal, 'facebook_post']);
                props.setSyncToExternal(prev => [...prev, 'facebook_post']);
              }}
              checked={props.syncToExternal.includes('facebook_post')}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="socialMediaBox">
          <div className="leftVerlinkung">
            <div className="verlinkungsIcon">{facebookIcon}</div>
            <div id="festesEnde">Facebook Story</div>
          </div>
          <label className="switch" style={{marginLeft: 'auto'}}>
          <input
              type="checkbox"
              onChange={async (e) => {
                if(!e.target.checked) {
                  props.setSyncToExternal(prev => prev.filter(e => e !== 'facebook_story'));
                  props.handleCustomizationChange('sync_to_external',props.syncToExternal.filter(e => e !== 'facebook_story'));
                  return;
                }
                if(!facebookPageId) {
                  const { facebookPageId: id } = await connectToFacebook();
                  if(!id) {
                    e.preventDefault();
                    return;
                  }
                  facebookPageId(id);
                }
                props.handleCustomizationChange('sync_to_external',[...props.syncToExternal, 'facebook_story']);
                props.setSyncToExternal(prev => [...prev, 'facebook_story']);
              }}
              checked={props.syncToExternal.includes('facebook_story')}
            />
            <span className="slider round"></span>
          </label>
        </div>
        {window.elgioOrganizerToolsInstalled && <div className="socialMediaBox">
          <div className="leftVerlinkung">
            <div className="verlinkungsIcon">{facebookIcon}</div>
            <div id="festesEnde">Facebook Event (du hast die ELGIO Organizer Tools Erweiterung installiert)</div>
          </div>
          <label className="switch" style={{marginLeft: 'auto'}}>
          <input
              type="checkbox"
              onClick={async (e) => {
                if(!e.target.checked) {
                  props.setSyncToExternal(prev => prev.filter(e => e !== 'facebook_event'));
                  return;
                }
                if(!facebookPageId) {
                  const { facebookPageId: id } = await connectToFacebook();
                  if(!id) {
                    e.preventDefault();
                    return;
                  }
                  facebookPageId(id);
                }
                props.setSyncToExternal(prev => [...prev, 'facebook_event']);
              }}
              checked={props.syncToExternal.includes('facebook_event')}
            />
            <span className="slider round"></span>
          </label>
        </div>}
        <div className="verlinkungenFooter">
          <div className="postingDateInput">Post geht online: bei Event-Liveschaltung</div>
          {/* <div className="editPostBtn">Post anpassen</div> */}
        </div>
      </div>
      </div>
    );
  }

  function SideBar({partner="",suffix=""}) {
    function smoothScroll(divid) {
      document.getElementById(divid).scrollIntoView({ behavior: "smooth" });
    }
    return (
      <div className="SideBarErstellen">
        <div>
          <div className="erforderlichSidebar">Erforderliche Angaben</div>
          <div className="itemSideBar" onClick={() => smoothScroll("Eventname")}>
            <div className="circleSideBar" id={`nameIconNav${suffix}`} >{nameIcon}</div>
            <div className="iconBeschreibung">Eventname</div>
          </div>
          <div className="itemSideBar" onClick={() => smoothScroll("Titelbild")}>
            <div className="circleSideBar" id={`titelbildIconNav${suffix}`}>{titelbildIcon}</div>
            <div className="iconBeschreibung">Titelbild</div>
          </div>
          <div className="itemSideBar" onClick={() => smoothScroll("Datum")}>
            <div className="circleSideBar" id={`dateIconNav${suffix}`}>{datumIcon}</div>
            <div className="iconBeschreibung">Datum & Uhrzeit</div>
          </div>
          <div className="itemSideBar" onClick={() => smoothScroll("Adresse")}>
            <div className="circleSideBar" id={`addressIconNav${suffix}`}>{adresseIcon}</div>
            <div className="iconBeschreibung">Location</div>
          </div>
          <div className="itemSideBar" onClick={() => smoothScroll("Genre")}>
            <div className="circleSideBar" id={`genreIconNav${suffix}`}>{genreIcon}</div>
            <div className="iconBeschreibung">Musikgenre</div>
          </div>
          <div className="itemSideBar" onClick={() => smoothScroll("Eintritt")}>
            <div className="circleSideBar" id={`eintrittIconNav${suffix}`}>{eintrittIcon}</div>
            <div className="iconBeschreibung">Eintritt</div>
          </div>
          <div className="itemSideBar" onClick={() => smoothScroll("Beschreibung")}>
            <div className="circleSideBar" id={`descriptionIconNav${suffix}`}>{beschreibungIcon}</div>
            <div className="iconBeschreibung">Beschreibung</div>
          </div>
          {/*<div className="itemSideBar" onClick={() => smoothScroll("ZstInfos")}>
                <div className="circleSideBar">{zusatzAdresseIcon}</div>
                <div className="iconBeschreibung">Zst. Adresse</div>
              </div>*/}
          <div className="optionalSidebar">Optionale Angaben</div>
          <div className="itemSideBar" onClick={() => smoothScroll("Lineup")}>
            <div className="circleSideBar" id={`lineupIconNav${suffix}`}>{lineupIcon}</div>
            <div className="iconBeschreibung">Lineup</div>
          </div>
          <div className="itemSideBar" onClick={() => smoothScroll("MinAlter")}>
            <div className="circleSideBar" id={`minAlterIconNav${suffix}`}>{minAlterIcon}</div>
            <div className="iconBeschreibung">Mindestalter</div>
          </div>
          <div className="itemSideBar" onClick={() => smoothScroll("Specials")}>
            <div className="circleSideBar" id={`specialsIconNav${suffix}`}>{specialIcon}</div>
            <div className="iconBeschreibung">Specials</div>
          </div>
          <div className="itemSideBar" onClick={() => smoothScroll("Dresscode")}>
            <div className="circleSideBar" id={`dresscodeIconNav${suffix}`}>{dresscodeIcon}</div>
            <div className="iconBeschreibung">Dresscode</div>
          </div>
          <div className="itemSideBar" onClick={() => smoothScroll("Veranstalter")}>
            <div className="circleSideBar" id={`coVeranstalterIconNav${suffix}`}>{coVeranstalterIcon}</div>
            <div className="iconBeschreibung" >(Co-)Veranstalter</div>
          </div>
          <div className="itemSideBar" onClick={() => smoothScroll("Verlinkungen")}>
              <div className="circleSideBar" id={`crossPlattformIconNav${suffix}`}>{crossPlattformIcon}</div>
              <div className="iconBeschreibung">Verlinkungen</div>
          </div>
          {partner === 'koelnisttechno' && <div className="itemSideBar" onClick={() => smoothScroll("StoryLink")}>
            <div className="circleSideBar" id={`storyLinkIconNav${suffix}`}>{storyLinkIcon}</div>
            <div className="iconBeschreibung">Link Sticker auf der KoelnIstTechno Story</div>
          </div>}
        </div>
      </div>
    );
  }

  export function EventForm() {
    const navigate = useNavigate();

    const [partner, setPartner] = useState(null);
    const [searchParams] = useSearchParams();
    useEffect(() => { searchParams.get('partner') && setPartner(searchParams.get('partner')) }, [searchParams]);

    const [showOverview, setShowOverview] = useState(false);

    const [ready, setReady] = useState(false);
    const [data, setData] = useState({});
    const [stateRecurring, setStateRecurring] = useState(false);

    useEffect(() => {
      let ready = true;
      if(data.images?.length === 0) {
        ready = false;
      } else if((data.name?.length??0) < 3) {
        ready = false;
      } else if(!data.start || !data.end) {
        ready = false;
      } else if(!data.address && !data.venue) {
        ready = false;
      } else if((data.genre?.length??0) === 0) {
        ready = false;
      } else if(!data.price && data.price !== 0) {
        ready = false;
      } else if((data.description?.length??0) < 3) {
        ready = false;
      }
      setReady(ready);
    }, [data]);

    const [loading, setLoading] = useState(false);
    const submit = async e => {
      e.preventDefault();
      if(loading) return;
      setLoading(true);
      try {
        await Promise.all(data.lineup?.map(async e => e.startsWith('$') && await addDoc(collection(firestore, 'artists'), {
          name: e.substring(1),
          agent: 'event-form',
          visibility: 'public',
        }).then(x => data.lineup[data.lineup.indexOf(e)] = x.id))??[]);
        await Promise.all(data.organizer?.map(async e => e.startsWith('$') && await addDoc(collection(firestore, 'organizers'), {
          name: e.substring(1),
          agent: 'event-form',
          visibility: 'public',
        }).then(x => data.organizer[data.organizer.indexOf(e)] = x.id))??[]);
        const ref = await addDoc(collection(firestore, stateRecurring ? 'recurring_events' : 'events'), {
          ...data,
          ...(partner ? {partner} : {}),
          agent: 'event-form',
          visibility: 'private',
          pending_review: true,
          type: [],
        });
        navigate(`/event-form/success?partner=${partner??''}&event=${ref.id}`);
      } catch(e) {
        alert('Etwas hat nicht geklappt: ' + e.toString());
        setLoading(false);
      }
    };

    return <div className="event-form-wrapper">
      <Helmet>
        <title>{partner === 'koelnisttechno' ? 'WoGehtWas-Formular | KoelnIstTechno' : 'ELGIO Events hochladen'}</title>
        {partner === 'koelnisttechno' && <link rel="icon" href={kitLogo} />}
      </Helmet>
      <div className="event-form">
        <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 25}}>
          {partner === 'koelnisttechno' ? <img style={{height: 40, width: 40}} src={kitLogo} alt="kit logo"/> : <div />}
          <img style={{height: 40}} src={logo} alt="elgio logo"/>
        </div>
        <div style={{color: 'var(--white)', fontWeight: 800, fontSize: "var(--fontsize-yourmum)"}}>{partner === 'koelnisttechno' ? 'WoGehtWas-Formular' : 'Event hochladen'}</div>
        <div style={{color: 'var(--lightgrey)', marginBottom: 25}}>{partner && 'powered by ELGIO'}</div>
        <div className="event-form-overview">
          <div style={{display: 'flex', justifyContent: 'space-between', cursor: 'pointer'}} onClick={() => setShowOverview(!showOverview)}>
            <div style={{color: 'var(--white)', fontWeight: 600}}>Angabenübersicht</div>
            <div>
              {showOverview ? chevronUpIcon : chevronDownIcon}
            </div>
          </div>
          <div style={{display: showOverview ? 'block' : 'none', marginTop: 20}}>
            <SideBar suffix={"event-form"} partner={partner} />
          </div>
        </div>
        <Inputs setData={setData} eventForm={true}  stateRecurring={stateRecurring} setStateRecurring={setStateRecurring} partner={partner} />
        {ready
          ? <div style={{color: 'var(--white)', textAlign: "center"}}>Du hast alle erforderlichen Angaben gemacht</div>
          : <div style={{color: 'var(--red)', textAlign: "center"}}>Du hast noch nicht alle erforderlichen Angaben gemacht</div>
        }
        <button onClick={submit} className="submit-button" disabled={!ready && !loading}>{loading ? 'Event wird hochgeladen...' : 'Event abschicken'}</button>
        <div style={{color: 'var(--lightgrey)', fontSize: 'var(--fontsize-small)', textAlign: "center"}}>Mit dem Klicken auf "Event abschicken" stimmst du zu, dass alle angegebenen Daten und Medien zur Weiterverarbeitung und Veröffentlichung auf Apps, Webseiten, Sozialen Medien und weiteren Kanälen von der Elgio UG (haftungsbeschränkt), sowie ihren Partnern, genutzt werden darf.</div>
      </div>
    </div>;
  }

  export default function EventErstellen() {
    const {id} = useParams();
    const [eventID, setEventID] = useState(id);
    const {user, organizerId} = useContext(UserContext);
    const [data, setData] = useState({});
    const [showSuccess, setShowSuccess] = useState(false)
    const navigate = useNavigate();
    // eslint-disable-next-line no-unused-vars
    const [name, setName] = useState("");
    const [visibility, setVisibility] = useState("");
    const [stateRecurring, setStateRecurring] = useState(false);
    const [searchParams] = useSearchParams();
    useEffect(() => {
      searchParams.get("viewParent") === "true" ?
      id && getDoc(doc(firestore,'recurring_events',id))
      .then((snapshot) => {
          setName(snapshot.data().name)
          setVisibility(snapshot.data().visibility)
      })
      :
      id && getDoc(doc(firestore,'events',id))
      .then((snapshot) => {
          setName(snapshot.data().name)
          setVisibility(snapshot.data().visibility)
      })
    }, [id]);


    const delete_event = async () => {
      if(eventID > 0){
        await deleteDoc(doc(firestore,"events",eventID))
        navigate("/dashboard/events")
      }
      else{
        navigate("/dashboard/events")
      }
    }

    const saveChanges = async () => {
      if((visibility && visibility !== 'draft')) {
        await Promise.all(data.lineup?.map(async e => e.startsWith('$') && await addDoc(collection(firestore, 'artists'), {
      name: e.substring(1),
      agent: user.uid,
      visibility: 'public',
      }).then(x => data.lineup[data.lineup.indexOf(e)] = x.id))??[]);
        await Promise.all(data.organizers?.map(async e => e.startsWith('$') && await addDoc(collection(firestore, 'organizers'), {
          name: e.substring(1),
          agent: user.uid,
          visibility: 'public',
        }).then(x => data.organizers[data.organizers.indexOf(e)] = x.id))??[]);
      }
      
      const collectionRef = stateRecurring ? collection(firestore, 'recurring_events') : collection(firestore, 'events');
      const documentRef = (eventID??id) ? doc(collectionRef, eventID??id) : doc(collectionRef);
      if(stateRecurring){
        try {
          await setDoc(documentRef, {
            repeat: null,
          }, {merge: true});
        } catch(e) {}
      }
      await setDoc(documentRef, {
        ...data, 
        visibility: visibility || 'draft', 
        organizer: [organizerId, ...(data.organizer)],
        agent: user.uid,
      }, {merge: true})      
      return documentRef.id;
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isFullWidth = windowWidth < 768;

    const [showOverview, setShowOverview] = useState(false);


    return (
      <div className="content" style={{overflow: 'hidden'}}>
        {/*<div
          className="hochscrollBtn"
          onClick={() => {window.scrollTo({behavior:"smooth",top:0,left:0})}}
        >
          <div id="hochscrollIcon">{arrowUpIcon}</div>
        </div>*/}
        <div className="flex-gap-10" id="breadcrumb-title-text">
          <div style={{cursor: 'pointer'}} onClick={() => navigate(-1)}>
            {chevronLeftIcon}
          </div>
          <div>
            <Link to="/dashboard/events" className="breadcrumb">Events</Link>
            &nbsp;/&nbsp;
            {id ? <>Event bearbeiten</> : <>Eventerstellung</>}
          </div>
        </div>
        <div className="PageHeaderBox">
          <div>
            <div className="PageHeaderBoxTitle">
              {id ? <>Event bearbeiten</> : <>Eventerstellung</>}
            </div>
            <div className="PageHeaderBoxSubtitle">
              Gib so viele Informationen wie möglich an, um mehr Nutzer zu erreichen.
            </div>
          </div>
          <div className="ButtonsBox">
            {(!visibility || visibility === 'draft') && (
              <Link
                to={"#"}
                className="Button"
                style={
                  (!visibility || visibility === 'draft') &&
                  (data.images?.length ?? 0) > 0 &&
                  (data.name?.length ?? 0) > 2 &&
                  data.start &&
                  data.end &&
                  (data.address || data.venue) &&
                  (data.genre?.length ?? 0) > 0 &&
                  (data.description?.length ?? 0) > 2 &&
                  // (data.lineup?.length ?? 0) > 0 &&
                  (
                    (data.price || data.price === 0) ||
                    (data.box_ticket_price) ||
                    (data.ticket)
                  ) ? {
                    backgroundColor: "var(--bgGreen)",
                    color: "var(--textMain)",
                    cursor: 'pointer',
                    width: isFullWidth ? '100%' : 'revert-layer'
                  } : {
                    backgroundColor: "var(--bgGreen)",
                    color: 'var(--textFourth)',
                    opacity: "35%",
                    cursor: 'default',
                    width: isFullWidth ? '100%' : 'revert-layer'
                  }
                }
                onClick={
                  (!visibility || visibility === 'draft') &&
                  (data.images?.length ?? 0) > 0 &&
                  (data.name?.length ?? 0) > 2 &&
                  data.start &&
                  data.end &&
                  (data.address || data.venue) &&
                  (data.genre?.length ?? 0) > 0 &&
                  (data.description?.length ?? 0) > 2 &&
                  // (data.lineup?.length ?? 0) > 0 &&
                  (
                    (data.price || data.price === 0) ||
                    (data.box_ticket_price)
                  ) ? async () => {
                    if (data.start > new Date()) {
                      //  const collectionRef = collection(doc(collection(firestore, 'users'), user.uid), 'event_drafts')
                      if (!window.confirm("Du bist dabei das Event Live zu schalten.\nDanach kann dies nur noch von uns umgestellt werden!\nBist du dir sicher?"))
                        return;
                      await Promise.all(data.lineup?.map(async e => e.startsWith('$') && await addDoc(collection(firestore, 'artists'), {
                        name: e.substring(1),
                        agent: user.uid,
                        visibility: 'public',
                      }).then(x => data.lineup[data.lineup.indexOf(e)] = x.id)) ?? []);
                      await Promise.all(data.organizers?.map(async e => e.startsWith('$') && await addDoc(collection(firestore, 'organizers'), {
                        name: e.substring(1),
                        agent: user.uid,
                        visibility: 'public',
                      }).then(x => data.organizers[data.organizers.indexOf(e)] = x.id)) ?? []);
                      const collectionRef = collection(firestore, 'events');
                      const documentRef = eventID ?? id ? doc(collectionRef, eventID ?? id) : doc(collectionRef);
                      await setDoc(documentRef, {
                        ...data,
                        visibility: 'private',
                        pending_review: true,
                        organizer: [organizerId, ...(data.organizer)],
                        agent: user.uid,
                      }, { merge: true });

                      // Marketing stuff
                      if (data.sync_to_external?.filter(e => e !== 'facebook_event').length > 0) {
                        await setDoc(doc(collection(firestore, "social_media_posts")), {
                          type: 'event',
                          event: documentRef.id,
                          post_at: new Date(),
                          caption: data.description,
                          banner: data.images[0],
                          organizer: organizerId,
                          facebook_post: data.sync_to_external.includes('facebook_post'),
                          facebook_story: data.sync_to_external.includes('facebook_story'),
                          instagram_post: data.sync_to_external.includes('instagram_post'),
                          instagram_story: data.sync_to_external.includes('instagram_story'),
                        });
                      }
                      if (data.sync_to_external?.includes('facebook_event')) {
                        const newwindow = window.open(`https://www.facebook.com/events/create?elgio_event_id=${documentRef.id}`, 'fb_event');
                        if (window.focus) { newwindow.focus(); }
                      }

                      window.location.href = '/dashboard/events/event/' + documentRef.id;
                    } else {
                      alert("Das Startdatum liegt in der Vergangenheit! Bitte änder dies um das Event live schalten zu können.")
                    }
                  } : null
                }
              >
                <div>
                  Live schalten
                </div>
              </Link>
            )}
            <Link
              to={"#"}
              className="Button BlueBtn"
              style={windowWidth < 768 ? { width: "100%" } : {}}
              onClick={async () => {
                const id = await saveChanges();
                stateRecurring ?
                  navigate('/dashboard/events/event/' + id + "?viewParent=true") :
                  navigate('/dashboard/events/event/' + id)
              }}
            >
              <div>
                {!visibility || visibility === 'draft' ? <>Als Entwurf speichern</> : <>Änderungen speichern</>}
              </div>
            </Link>
            <div
              className="Button GreyBtn"
              onClick={() => { delete_event(); }}
              style={windowWidth < 768 ? { width: "100%" } : {}}
            >
              <div> Abbruch </div>
            </div>
          </div>
        </div>
        <div className="event-form-overview" style={windowWidth < 768 ? {display: 'block', marginBottom: "10px" } : {display: 'none'}}>
          <div style={{display: 'flex', justifyContent: 'space-between', cursor: 'pointer'}} onClick={() => setShowOverview(!showOverview)}>
            <div style={{color: 'var(--white)', fontWeight: 600}}>Angabenübersicht</div>
            <div>
              {showOverview ? chevronUpIcon : chevronDownIcon}
            </div>
          </div>
          <div style={showOverview ? {visibility:"visible", marginTop: "20px",height:"fit-content"} : {visibility:"hidden",height:"0px"}}>
            <SideBar suffix="Mobile"/>
          </div>
        </div>
        <div style={{display: 'flex', gap: 75, overflowY: 'scroll', flex: 1}}>
          {windowWidth >= 768 && <SideBar></SideBar>}
          <Inputs suffix={windowWidth < 768 ? "Mobile" : ""} setShowSuccess={setShowSuccess} eventID={eventID} setEventID={setEventID} setData={setData} saveChanges={saveChanges} stateRecurring={stateRecurring} setStateRecurring={setStateRecurring} ></Inputs>
          <div className="VorschauContainer" style={{ alignSelf: 'stretch' }}>
            <div id="vorschauheader">
              Vorschau in der App  
            <div className= "Beschreibungsuntertitel">
              So sehen deine Gäste dein Event in der App
            </div>
            </div>
            {/*<div className="VorschauContainerBody" style={{height:"70%",display:"flex",justifyContent:"center",alignItems:"center"}}></div>*/}
              <div className="ComingSoon">Coming Soon</div>
              {/* <iframe title="Vorschau" src={`https://elgio-apppreview.web.app/organizer?banner=${organizerBanner}&icon=${organizerIcon}&name=${organizerName}&description=${organizerBeschreibung}&phone=${organizerTele}&website=${organizerWebsite}&address=${organizerAdresse}&email=${organizerEmail}`} className="VorschauIFrame Profil" style={{width:"25vw",height:"70vh"}}></iframe>*/}
            </div>
          </div>
            <Success setShowSuccess={setShowSuccess} showSuccess={showSuccess} ></Success>
        </div>
    );
  }