import React, { useContext, useEffect, useMemo, useState } from "react";
import { auth, firestore, functions, storage } from "../../firebase";
import "./home.css";
import "./settings.scss";
import { reauthenticateWithCredential, updatePassword ,EmailAuthProvider, updateEmail } from "firebase/auth";
import 'animate.css/animate.css'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useRef } from "react";
import { UserContext } from "../../providers/UserProvider";
import Modal from "react-modal";
import PasswordChecklist from "react-password-checklist";
import { httpsCallable } from "firebase/functions";
import { connectToFacebook, connectToInstagram } from "../../facebook";
import "../../index.scss";
import {
  verificationIcon,
  cancelIcon,
  downloadIcon,
  facebookIcon,
  instagramIcon,
  verknüpfungIcon,
  subscriptionIcon,
  checkmarkIcon,
  ticketIcon,
  tiktokIcon,
  metaIcon,
  accountSettingsIcon,
  followersIcon,
  plusIcon,
  editIcon,
  deleteIcon,
} from "../../icons.jsx"
import SubscribeModal from "./subscriptions/subscribe_modal.jsx";
import { useSearchParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import PaymentSheet from "./subscriptions/payment_sheet.jsx";
import { loadStripe } from "@stripe/stripe-js";
import { getDownloadURL, ref } from "firebase/storage";
import { toast } from "react-toastify";
import { errorToast, successToast } from "../widgets/toasts.tsx";
import BankInput from "../widgets/BankInput.jsx";
import LoaderDivWithOverlay, { hideLoader, showLoader } from "../widgets/loaderdiv.jsx";



export default function ModalSettings({ show, handleClose }) {
  const { permissions } = useContext(UserContext);

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => { searchParams.get('settings_page') && setMenuPage(searchParams.get('settings_page')) }, [searchParams]);

  const el = Modal.setAppElement("#portal");


  const [menuPage, setMenuPage] = useState('account');

  const changeMenuPage = (page) => {
    searchParams.set('settings_page', page);
    setSearchParams(searchParams);
  };

  return (
    <>
  
    <Modal
      isOpen={show}
      appElement={el}
      unselectable="true"
      autoFocus={false}
      className="modalContentSmall"
      overlayClassName="modalOverlayNormal"
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {handleClose();hideLoader("loader_stripe")}}
      style={{
        content: {
            display: "flex",
            flexDirection: "row",
        }}}
      
    >

      <div className="SideBarModal el-hidden-small" autoFocus={false}>
        <div>
          <div className="SideBarTitle">Einstellungen</div>
          <div className="SideBarModalBox">
            <>
              <div className={menuPage === 'account' ? "SideBarModalItem Active" : "SideBarModalItem"} onClick={(e) => changeMenuPage('account')}>
                  <div className="SideBarModalIcon">{accountSettingsIcon}</div>
                  <div className="SideBarModalItemText">Kontoeinstellungen</div>
              </div>
              {permissions?.users?.view && <div className={menuPage === 'teammember' ? "SideBarModalItem Active" : "SideBarModalItem"} onClick={(e) => changeMenuPage('teammember')}>
                  <div className="SideBarModalIcon">{followersIcon}</div>
                  <div className="SideBarModalItemText">Teammember</div>
              </div>}
              {/* <div className={menuPage === 1 ? "SideBarModalItem Active" : "SideBarModalItem"} onClick={(e) => setMenuPage(1)}>
                  <div className="SideBarModalIcon">{discoveryIcon}</div>
                  <div className="SideBarModalItemText">Auto - Eventdiscovery</div>
              </div> */}
              {permissions?.marketing?.setup && <div className={menuPage === 'links' ? "SideBarModalItem Active" : "SideBarModalItem"} onClick={(e) => changeMenuPage('links')}>
                  <div className="SideBarModalIcon">{verknüpfungIcon}</div>
                  <div className="SideBarModalItemText">Verknüpfungen</div>
              </div>}
              {permissions?.marketing?.setup && <div className={menuPage === 'subscriptions' ? "SideBarModalItem Active" : "SideBarModalItem"} onClick={(e) => changeMenuPage('subscriptions')}>
                  <div className="SideBarModalIcon">{subscriptionIcon}</div>
                  <div className="SideBarModalItemText">Abonnement</div>
              </div>}
              {permissions?.marketing?.setup && <div className={menuPage === 'billing' ? "SideBarModalItem Active" : "SideBarModalItem"} onClick={(e) => changeMenuPage('billing')}>
                  <div className="SideBarModalIcon">{verificationIcon}</div>
                  <div className="SideBarModalItemText">Zahlungen</div>
              </div>}
              {permissions?.ticketing?.view && <div className={menuPage === 'ticketing' ? "SideBarModalItem Active" : "SideBarModalItem"} onClick={(e) => changeMenuPage('ticketing')}>
                  <div className="SideBarModalIcon">{ticketIcon}</div>
                  <div className="SideBarModalItemText">Ticketing</div>
              </div>}
            </>
          </div>
        </div>
        <div className="SideBarLogout" onClick={() => auth.signOut()}>Ausloggen</div>
      </div>
      <div className="ModalContent">
          <div className="ModalContentTitleBox">
            <div className="ModalContentTitle el-hidden-small">
              {(() => {
                switch(menuPage) {
                    case 'account': return 'Kontoeinstellungen';
                    case 'teammember': return 'Teammember';
                    case 'links': return 'Verknüpfungen';
                    case 'subscriptions': return 'Abonnement';
                    case 'billing': return 'Zahlungen';
                    case 'ticketing': return 'Ticketing';
                    default: return 'Einstellungen';
                }
              })()}
            </div>
            <div className="el-visible-small">
              <select className="selectSettings" value={menuPage} onChange={(e) => changeMenuPage(e.target.value)}>
                <option value="account">Kontoeinstellungen</option>
                <option value="teammember">Teammember</option>
                {/* <option value={1}>Auto - Eventdiscovery</option> */}
                <option value="links">Verknüpfungen</option>
                <option value="subscriptions">Abonnement</option>
                <option value="billing">Zahlungen</option>
                <option value="ticketing">Ticketing</option>
              </select>
            </div>
            <div className="CloseIcon" onClick={handleClose}>{cancelIcon}</div>
          </div>
          <div className="v4"/>
          {(() => {
              switch(menuPage) {
                  case 'account': return <Account handleClose={handleClose} />;
                  case 'teammember': return <Teammember handleClose={handleClose} />;
                  // case 1: return <AutoDiscover/>;
                  case 'links': return <AppLinks handleClose={handleClose} />;
                  case 'subscriptions': return <Subscription handleClose={handleClose} />;
                  case 'billing': return <Billing handleClose={handleClose} />;
                  case 'ticketing': return <Ticketing handleClose={handleClose} />;
                  default: //do nothing
              }
          })()}
      </div>
    </Modal>
    </>
  )
}


// KONTOEINSTELLUNGEN


function Account({ handleClose }) {
    const { user } = useContext(UserContext);
    const [email, setEmail] = useState(user.email);
    const [emailPassw, setEmailPassw] = useState("");
    const [currPassw, setCurrPassw] = useState("");
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [inputIndex,setInputIndex] = useState(0);
    const [passwordValid,setPasswordValid] = useState(false);
    const [done,setDone] = useState(false);
    const [error,setError] = useState("Passwörter Stimmen nicht überein")
    const [newPasswBoxVisible,setNewPasswBoxVisible] = useState(false)

    function checkPassw(){
      if(currPassw.length !== 0){
        const credential = EmailAuthProvider.credential(
          auth.currentUser.email,
          currPassw
        );
    
        reauthenticateWithCredential(auth.currentUser, credential)
          .then(() => {
            document.getElementsByClassName("newPasswBox")[0].style.display = "block";
            setNewPasswBoxVisible(true);
            // document.getElementsByClassName("loaderDivNew")[1].style.visibility="hidden"
          })
          .catch(e => {
            console.log(e); // Log the full error object for debugging
            switch (e.code) {
              case "auth/wrong-password":
                setError("Falsches Passwort!");
                break;
              case "auth/too-many-requests":
                setError("Zu viele Fehlversuche, versuche es später erneut");
                break;
              default:
                setError("Ein unbekannter Fehler ist aufgetreten");
                break;
            }
            document.getElementById('errorTextPassw').style.visibility = "visible";
            setTimeout(() => {
              document.getElementById('errorTextPassw').style.visibility = "hidden";
            }, 3000);
          });
      } else {
        toast.info("Bitte gib ein Passwort an!", {theme: "dark"});
      }
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isFullWidth = windowWidth < 768;


    return <>
        <div className="ModalContentTitleDetails">Hier kannst du wichtige Kontaktangaben (wie z.B. deine Email-Adresse oder dein Passwort) zu deinem Account bearbeiten</div>
        <div className="ModalContentSectionBox">
          <div className="ModalContentSection">
            <div className="ModalContentSectionTitle">Email-Adresse</div>
            <div className="ModalContentSectionBar"/>
            <div className="inputFlex">
              <input
                  type={"email"}
                  className="TextInputModal"
                  placeholder="Neue Email eingeben"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                ></input>
            </div>
            {email.toLowerCase() !== user.email.toLowerCase() && <div className="inputFlex">
              <div style={{marginTop: "10px", color: "white", width: "fit-content"}}>
                Bitte gib aus Sicherheitsgründen dein Passwort erneut ein, um deine E-Mail-Adresse zu ändern:
              </div>
              <div className="ModalContentSectionRow">
                <input
                  type="password"
                  className="TextInputModal"
                  value={emailPassw}
                  onChange={(e) => setEmailPassw(e.target.value)}
                  placeholder="Passwort"
                ></input>
                <div className="SaveButton" onClick={async() => {
                  try {
                    await reauthenticateWithCredential(user, EmailAuthProvider.credential(
                      user.email,
                      emailPassw,
                    ));
                    await updateEmail(user, email);
                    await updateDoc(doc(firestore, 'users', user.uid), { email });
                    alert('Email erfolgreich gespeichert');
                    window.location ='/dashboard';
                  } catch(e) {
                    switch(e.code) {
                      case 'auth/invalid-email': alert('Die eingegebene E-Mail-Adresse ist nicht gültig. Bitte mit einer richtigen E-Mail-Adresse erneut versuchen.'); break;
                      case 'auth/wrong-password': alert('Falsches Passwort, bitte erneut versuchen.'); break;
                      case 'auth/missing-password': alert('Bitte gib dein Passwort ein.'); break;
                      default: alert(`Das hat leider nicht geklappt. Bitte probiere es später erneut. (${e})`); break;
                    }
                  }
                }}>Speichern</div>
              </div>
            </div>}
          </div>
          <div className="ModalContentSection">
            <div className="ModalContentSectionTitle">Passwort ändern</div>
            <div className="ModalContentSectionBar"/>
            <div className="inputFlex">
                <div style={{color: "var(--textMain)", width: "fit-content"}}>Bitte gib dein aktuelles Passwort ein um ein neues Passwort zu erstellen</div>
            <div className="passwortVerifyFirst">
              <input
                  type={"password"}
                  className="TextInputModal"
                  value={currPassw}
                  onChange={(e) => setCurrPassw(e.target.value)}
                  placeholder="Aktuelles Passwort"
                  autoFocus={inputIndex === 0 ? window.innerWidth < 768 ? false : true : false}
                  autoComplete="new-password"
                  onClick={() => {
                    if(inputIndex !== 0){
                      setInputIndex(0)
                    }
                  }}
                  ></input>
                  <div className="newPassw white" onClick={checkPassw} style={newPasswBoxVisible ? {marginTop:"0",height:"15px", backgroundColor:"var(--green)",padding:"11px"} : currPassw.length === 0 ? {marginTop:"0",height:"15px",padding:"11px",backgroundColor:"var(--lightgrey)"} : {marginTop:"0",height:"15px",padding:"11px"}}>
                 {newPasswBoxVisible ? "Passwort Überprüft" : "Passwort Überprüfen"}
              </div>
            </div>
            <div className="newPasswBox" style={{display:"none"}}>
              <div style={{marginTop: "10px", color: "white", width: "fit-content"}}>
                    Bitte gib nun dein neues Passwort ein & bestätige dies.
                  </div>
                  <div className="inputs-box-flex-row" style={{marginTop:"10px"}}>
                  <div className="password-inputs-col">
                      <input
                      type={"password"}
                      className="TextInputModal"
                      onChange={(e) => {
                          setPassword(e.target.value);
                      }}
                      placeholder="Neues Passwort"
                      style={{ marginTop: "-15px" }}
                      value={password} 
                      autoFocus={inputIndex === 1 ? true : false}
                      onClick={() => {
                          if(inputIndex !== 1){
                              setInputIndex(1)
                          }
                      }}
                      ></input>
                      <input
                      type={"password"}
                      className="TextInputModal"
                      onChange={(e) => {
                          setPasswordAgain(e.target.value);
                          if (password !== passwordAgain) {
                          }
                      }}
                      autoFocus={inputIndex === 2 ? true : false}
                      onClick={() => {
                          if(inputIndex !== 2){
                              setInputIndex(2)
                          }
                      }}
                      value={passwordAgain}
                      placeholder="Wiederholung neues Passwort"
                      ></input>
                  </div>
                  <PasswordChecklist
                      className="passw-checklist-modal"
                      minLength={8}
                      rules={[
                      "minLength",
                      "specialChar",
                      "number",
                      "capital",
                      "match",
                      ]}
                      value={password}
                      valueAgain={passwordAgain}
                      messages={{
                      minLength: "Passwort hat mindestens 8 Zeichen.",
                      specialChar: "Passwort hat mindestens ein Symbol.",
                      number: "Passwort hat mindestens eine Zahl.",
                      capital:
                          "Passwort hat mindestens einen Großbuchstaben.",
                      match: "Passwörter stimmen überein.",
                      }}
                      iconSize={17}
                      onChange={(isValid) => {
                      if(done === false){
                          if(isValid){
                          setPasswordValid(true)
                          }
                          else{
                          setPasswordValid(false)
                          }
                          setDone(true)
                      }
                      else{
                          if(isValid){
                          setPasswordValid(true)
                          }
                      }
                      }}
                  ></PasswordChecklist>
                  </div>
                  <div className="newPassw" style={ passwordValid ? {backgroundColor:"var(--blue)"} : {backgroundColor: "var(--bg5)",cursor:"not-allowed"}} 
              onClick={(e) => {
                  const credential = EmailAuthProvider.credential(
                  auth.currentUser.email,
                  currPassw
                  )
                  passwordValid && currPassw.length !== 0 ? 
                      reauthenticateWithCredential(auth.currentUser,credential).then(() => {
                      console.log("Sucessfully reauthenticated! \nGoing to Update function now!");
                      updatePassword(auth.currentUser,password).then(() => {
                          console.log("Sucessfully Updated");
                          setNewPasswBoxVisible(!newPasswBoxVisible)
                          successToast("Dein Passwort wurde geändert")
                        }) 
                        
                      })
                      .catch((e) => {
                        errorToast("Es ist ein Fehler aufgetreten, bitte wiederholen sie den Vorgang.")
                        const eC = e.code
                        const eM = e.message
                        console.log(eC,eM);
                      }) 
                  :                  
                  currPassw.length === 0
                  ?
                  alert("Bitte geben gib dein aktuelles Passwort ein") 
                  : 
                  alert("Passwort entspricht nicht den Richtlinien")
              }}>
                  <div style={{color: "white"}}>Neues Passwort erstellen</div>
                  </div>

            </div>                          
            </div>
            <div id="errorTextPassw" className="text-color__red" style={ newPasswBoxVisible ? 
            {
                  color: "var(--red)",
                  position: "relative",
                  visibility:"hidden",
                  top: "17%",
                  left: "5.5vw",
                  width:"fit-content"
                }
            :
            {
              color: "var(--red)",
              visibility:"hidden",
              marginTop:"10px",
              width:"fit-content"

            } 
          }
            >
                {error}
            </div>
          </div>
        </div>
        {isFullWidth && <div className="SideBarLogout" onClick={() => auth.signOut()}>Ausloggen</div>}
    </>;
}


// AUTODISCOVERY


// eslint-disable-next-line no-unused-vars
function AutoDiscover() {
    const { organizerId } = useContext(UserContext);
    
    const [autoDiscover, setAutoDiscover] = useState(true);
    
    useEffect(() => {
        getDoc(doc(collection(firestore, "organizers"), organizerId))
          .then((snapshot) => {
            setAutoDiscover(snapshot.data().autoDiscover);
          })
          .catch((error) => console.log(error));
      }, [organizerId]);

    return <>
    {/* {autoDiscover ?  document.getElementById('autoDiscoverSwitch')?.click() : null} */}
    <div className="ModalContentTitle">Auto - Eventdiscovery</div>
    <div className="v4"/>
    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
      <div className="ModalContentTitleDetails">
        ELGIO synchronisiert automatisch deine Events mit Eventseiten
        wie Facebook,<br></br> Resident Advisor oder anderen. Jedoch
        kann es sein, dass Informationen zu den Events fehlen.
      </div>
      <label className="switch">
        <input type="checkbox" id="autoDiscoverSwitch" onClick={() => {setAutoDiscover(!autoDiscover)}} checked={autoDiscover}/>
        <span className="slider round"></span>
      </label>
    </div>
  </>;
}


// VERLINKUNGEN


function AppLinks({handleClose}) {
    const { user } = useContext(UserContext);

    //Meta Link
    const [facebookPageId, setFacebookPageId] = useState(null);
    const [facebookPageName, setFacebookPageName] = useState(null);
    const [instagramAccountId, setInstagramAccountId] = useState(null);
    const [instagramAccountUsername, setInstagramAccountUsername] = useState(null);
    useEffect(() => { getDoc(doc(collection(firestore, 'users'), user.uid)).then(doc => {
      setFacebookPageId(doc.data().facebook_page_id);
      setFacebookPageName(doc.data().facebook_page_name);
      setInstagramAccountId(doc.data().instagram_account_id);
      setInstagramAccountUsername(doc.data().instagram_account_username);
    }) }, [user]);

    //Tracking Pixel
    const { organizerId } = useContext(UserContext);
    const [tikTokPixel,setTikTokPixel] = useState("")
    const [metaPixel,setMetaPixel] = useState("")
  
    useEffect(() => {
      getDoc(doc(firestore,"organizers",organizerId)).then((organizer) => {
        if(organizer.data().external_tracking_ids?.tiktok)
        setTikTokPixel(organizer.data().external_tracking_ids?.tiktok)
        if(organizer.data().external_tracking_ids?.meta)
        setMetaPixel(organizer.data().external_tracking_ids?.meta)
      })
    },[organizerId])
  
    useEffect(() => {
      const organizerDoc = doc(collection(firestore,"organizers"),organizerId)
      updateDoc(organizerDoc,{
        external_tracking_ids: {
          meta: metaPixel,
          tiktok: tikTokPixel,
        },
      }).catch(e => {
        console.log(e);
      })
    }, [metaPixel, organizerId, tikTokPixel]);

    return <>
    {/* {autoDiscover ?  document.getElementById('autoDiscoverSwitch')?.click() : null} */}
    <div className="ModalContentTitleDetails">
      Hier kannst du Verlinkungen zu anderen Plattformen hinzufügen.
      So können wir z.B. Events von dir auf andern Plattformen hochladen.
    </div>
    <div className="ModalContentSectionBox">
      <div className="ModalContentSection">
        <div className="ModalContentSectionTitle">Social Media</div>
        <div className="ModalContentSectionBar"/>
        <div className="payment-methods-modal">
          <div className="rowItem">
            <div className="rowItemLeft">
              <div className="circleNormal" style={{backgroundColor: "var(--bg7)"}}>
                <div className="PaymentIcon">{facebookIcon}</div>
              </div>
              <div className="verknüpfungBox">
                <div className="verlinkungName">Facebook</div>
                {facebookPageName && <div className="verlinkungSubname">Verknüpfter Account: {facebookPageName}</div>}
              </div>
            </div>
            <div className="rowItemRight">
              <div
                className={facebookPageId ? 'activate-button-payment-text-active' : 'activate-button-payment-text'}
                onClick={async () => {
                  const {facebookPageId: id, facebookPageName: name} = await connectToFacebook();
                  setFacebookPageId(id);
                  setFacebookPageName(name);
                }}
              >
                {facebookPageId ? 'Verlinkt' : 'Verlinken'}
              </div>
            </div>
          </div>
          <div className="rowItem">
            <div className="rowItemLeft">
              <div className="circleNormal" style={{backgroundColor: "var(--bg7)"}}>
                <div className="PaymentIcon">{instagramIcon}</div>
              </div>
              <div className="verknüpfungBox">
                <div className="verlinkungName">Instagram</div>
                {instagramAccountUsername && <div className="verlinkungSubname">Verknüpfter Account: {instagramAccountUsername}</div>}
              </div>
            </div>
            <div className="rowItemRight">
              <div
                className={instagramAccountId ? 'activate-button-payment-text-active' : 'activate-button-payment-text'}
                onClick={async () => {
                  const {instagramAccountId: id, instagramAccountUsername: name, facebookPageId: fbid, facebookPageName: fbname} = await connectToInstagram(facebookPageId);
                  setInstagramAccountId(id);
                  setInstagramAccountUsername(name);
                  setFacebookPageId(fbid);
                  setFacebookPageName(fbname);
                }}
              >
                {instagramAccountId ? 'Verlinkt' : 'Verlinken'}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ModalContentSection">
        <div className="ModalContentSectionTitle">Tracking Pixel</div>
        <div className="ModalContentSectionBar"/>
        <div className="payment-methods-modal">
          <div className="rowItem">
            <div className="rowItemLeft">
              <div className="circleNormal" style={{backgroundColor: "var(--bg7)"}}>
                <div className="PaymentIcon">{tiktokIcon}</div>
              </div>
              <div className="verlinkungName">TikTok Pixel</div>
            </div>
            <div className="rowItemRight">
              <input type="text"
                className='TextInput' 
                style={{backgroundColor:"var(--bg4)"}}
                value={tikTokPixel}
                placeholder='z.B 123456789'
                onChange={(e) => {setTikTokPixel(e.target.value)}}
              />
            </div>
          </div>
          <div className="rowItem">
            <div className="rowItemLeft">
              <div className="circleNormal" style={{backgroundColor: "var(--bg7)"}}>
                <div className="PaymentIcon">{metaIcon}</div>
              </div>
              <div className="verlinkungName">Meta Pixel</div>
            </div>
            <div className="rowItemRight">
              <input type="text" 
                className="TextInput" 
                style={{backgroundColor:"var(--bg4)"}}
                value={metaPixel}
                placeholder='z.B 123456789'
                onChange={(e) => {setMetaPixel(e.target.value)}}
                />
            </div>
          </div> 
        </div>
      </div>  
    </div>
  </>;
}


// Abonnement / Subscription


function Subscription({ handleClose }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const tableRef = useRef(null)

  const { organizerId } = useContext(UserContext);

  const [show, setShow] = useState(false);
  const [membership, setMembership] = useState(null);

  const [previouslySubscribed, setPreviouslySubscribed] = useState(true);
  const [current, setCurrent] = useState(null);
  const [nextMembership, setNextMembership] = useState(null);
  useEffect(() => {
    onSnapshot(
      query(
        collection(firestore, 'membership_subscriptions'),
        where('organizer', '==', organizerId),
        where('status', 'in', ['active',  'active_canceled', 'active_unpaid', 'expired', 'expired_unpaid', 'expired_canceled']),
        limit(1),
      ),
      e => setPreviouslySubscribed(!e.empty),
    );
    onSnapshot(
      query(
        collection(firestore, 'membership_subscriptions'),
        where('organizer', '==', organizerId),
        where('status', 'in', ['active', 'active_canceled', 'active_unpaid']),
        limit(1),
      ),
      e => setCurrent(e.empty ? null : e.docs[0])
    );
  }, [organizerId]);
  useEffect(() => {
    if(!current?.exists) {
      setNextMembership(null);
      return;
    }
    getDoc(doc(collection(firestore, 'memberships'), current.data().membership)).then(async membershipDoc => {
      if(!membershipDoc.exists) {
        setNextMembership(null);
        return;
      }
      let nextDoc;
      if(membershipDoc.data().renew_as) {
        nextDoc = await getDoc(doc(collection(firestore, 'memberships'), membershipDoc.data().renew_as));
      } else {
        nextDoc = membershipDoc;
      }
      setNextMembership(nextDoc);
    });
  }, [current]);

  
  useEffect(() => {}, [organizerId]);

  return <>
  <SubscribeModal membership={membership} show={show} setShow={setShow} />
  <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
    <div className="ModalContentTitleDetails">
      Hier siehst du die verschiedenen Abonnements mit ihre Vorteilen<br></br>
      Upgrade auf ein besseres Abonnement um bessere Ticketkonditionen, Marketingfunktionen sowie ander Vorteile zu erhalten.
    </div>
  </div>
  <div className="fixTableHead">
    <table id="html-table" style={{color:"var(--lightgrey)"}} className="table-ticket-overview" cellSpacing={"20%"}  cellPadding={"10%"} ref={tableRef} >
    <thead>
      <tr>
          <th style={{color: "var(--textMain)"}}></th>
          <th className="subscriptionPlan">
            <div className="col-flex flex-gap-1rem">
              <div className="planBoxTop">
                <div className="aboName">Basic</div>
                <div className="pricingBox">
                  <div className="pricingNumber">0€</div>
                  <div className="pricingDetails">pro Monat</div>
                </div> 
              </div>
              { current && current?.data().status !== 'active_canceled'
                ? <div className="subscriptionBtn downgrade" onClick={() => {
                  if(current?.data().status === 'active_unpaid') {
                    alert('Bitte bezahle deine offenen Rechnungen bevor du dein Abo kündigst.');
                    return;
                  }
                  if(!window.confirm(`Bist du dir sicher, dass du dein Abonnement beenden willst? Wenn du kündigst, verlierst zum ${current?.data().expiration?.toDate().toLocaleDateString('de-DE')} alle Vorteile deines Abonnements.`))
                    return;
                  updateDoc(current.ref, {
                    status: 'active_canceled',
                  });
                }}>Zu Basic wechseln</div>
                : current?.data().status === 'active_canceled'
                ? <div className="subscriptionBtn current">Dein zukünftiges Abo</div>
                : <div className="subscriptionBtn current">Derzeitiges Abo</div>
              }
            </div>
          </th>
          <th className="subscriptionPlan">
            <div className="col-flex flex-gap-1rem">
              <div className="planBoxTop">
                <div className="aboName">Premium</div>
                <div className="pricingBox">
                  <div className="pricingNumber">{previouslySubscribed ? <>{((nextMembership?.data().price??1990) / 100).toFixed(2).replace('.', ',')}€</> : <>0€</>}</div>
                  <div>
                    <div className="pricingDetails">{previouslySubscribed ? <>pro Monat</> : <>im ersten Monat</>}</div>
                    <div className="pricingDetails">{previouslySubscribed ? <></> : <>danach 19,90€ pro Monat</>}</div>
                  </div>
                </div>
              </div>
              <div className="col-flex">
                { current && current?.data().status !== 'active_canceled'
                  ? <div className="subscriptionBtn current">Derzeitiges Abo</div>
                  : current?.data().status === 'active_canceled'
                  ? <div className="subscriptionBtn upgrade" onClick={() => {
                    if(!window.confirm(`Bist du dir sicher, dass du dein Abonnement doch fortsetzen willst? Wir buchen dann weiterhin von deiner hinterlegten Karte monatlich ${(nextMembership?.data().price / 100).toFixed(2).replace('.', ',')}€ ab.`))
                      return;
                    updateDoc(current.ref, {
                      status: 'active',
                    });
                  }}>Abonnement fortsetzen</div>
                  : <div className="subscriptionBtn upgrade" onClick={() => {
                    setMembership(previouslySubscribed ? 'dashboard_pro_monthly' : 'dashboard_pro_monthly_trial');
                    setShow(true);
                  }}>Upgrade</div>
                }
                {current?.data().status === 'active_canceled'
                  ? <div className="subscriptionCanceled" style={{textAlign: "center"}}>Du hast dein Abonnement gekündigt. Es läuft noch bis zum {current?.data().expiration?.toDate().toLocaleDateString('de-DE')}.</div>
                  : current?.data().status === 'active_unpaid'
                  ? <div className="subscriptionCanceled" style={{cursor: 'pointer', textAlign: "center"}} onClick={() => {
                    searchParams.set('settings_page', 'billing');
                    setSearchParams(searchParams);
                  }}>Es gab ein Problem bei der letzten Bezahlung. Klicke hier, um deine verknüpfte Karte zu aktualisieren.</div>
                  : <div className="freeTrial">jederzeit kündbar</div>
                }
              </div>
            </div>
          </th>
        </tr>
    </thead>
    <tbody style={{marginTop:"20px"}}>
      <tr className="serviceSection">
        <tr>
          <td className="serviceHeader">Angebote</td>
          <td className="servicePlan"></td>
          <td className="servicePlan"></td>
        </tr>
        <tr className="bottomBorder">
          <td className="serviceDetails">Event-erstellung</td>
          <td className="servicePlan">unendlich</td>
          <td className="servicePlan">unendlich</td>
        </tr>
        <tr className="bottomBorder">
          <td className="serviceDetails">Kundenservice</td>
          <td className="servicePlan">basic</td>
          <td className="servicePlan">bevorzugt</td>
        </tr>
        <tr className="bottomBorder">
          <td className="serviceDetails">Kundenaktivitäten</td>
          <td className="servicePlan">letzten 30 Tage</td>
          <td className="servicePlan">unendlich</td>
        </tr>
        <tr className="bottomBorder">
          <td className="serviceDetails">Eigene Website</td>
          <td className="servicePlan">über ELGIO Domain</td>
          <td className="servicePlan">über eigene Domain</td>
        </tr>
        {/*<tr>
          <td className="serviceDetails">Zugang zur Veranstalterdatenbank</td>
          <td className="servicePlan">{cancelIcon}</td>
          <td className="servicePlan">{checkmarkIcon}</td>
        </tr>*/}
      </tr>
      <tr className="serviceSection">
        <tr>
          <td className="serviceHeader">Marketing</td>
          <td className="servicePlan"></td>
          <td className="servicePlan"></td>
        </tr>
        <tr className="bottomBorder">
          <td className="serviceDetails">Automatisches erstellen und posten</td>
          <td className="servicePlan">{cancelIcon}</td>
          <td className="servicePlan">{checkmarkIcon}</td>
        </tr>
        <tr className="bottomBorder">
          <td className="serviceDetails">Zugang zu 20+ Post-Templates</td>
          <td className="servicePlan">{cancelIcon}</td>
          <td className="servicePlan">{checkmarkIcon}</td>
        </tr>
        <tr>
          <td className="serviceDetails">1x eigenes Template</td>
          <td className="servicePlan">{cancelIcon}</td>
          <td className="servicePlan">{checkmarkIcon}</td>
        </tr>
      </tr>
      <tr className="serviceSection">
        <tr>
          <td className="serviceHeader">Ticketing</td>
          <td className="servicePlan"></td>
          <td className="servicePlan"></td>
        </tr>
        <tr className="bottomBorder">
          <td className="serviceDetails">Kommission kostenloses Ticket</td>
          <td className="servicePlan">Kostenlos</td>
          <td className="servicePlan">Kostenlos</td>
        </tr>
        <tr className="bottomBorder">
          <td className="serviceDetails">Kommission bis 15€ Ticketpreis</td>
          <td className="servicePlan">0,99€</td>
          <td className="servicePlan">0,99€</td>
        </tr>
        <tr className="bottomBorder">
          <td className="serviceDetails">Kommission ab 15€ Ticketpreis</td>
          <td className="servicePlan">0,40€ + 5%</td>
          <td className="servicePlan">0,40€ + 4%</td>
        </tr>
        <tr className="bottomBorder">
          <td className="serviceDetails">Ticket Scan App</td>
          <td className="servicePlan">{checkmarkIcon}</td>
          <td className="servicePlan">{checkmarkIcon}</td>
        </tr>
        <tr>
          <td className="serviceDetails">Rabattcodes</td>
          <td className="servicePlan">5</td>
          <td className="servicePlan">Unendlich</td>
        </tr>
      </tr>
      <tr className="serviceSection">
        <tr>
          <td className="serviceHeader">Statistiken</td>
          <td className="servicePlan"></td>
          <td className="servicePlan"></td>
        </tr>
        <tr className="bottomBorder">
          <td className="serviceDetails">Eventaufrufe, -merkungen & -teilungen</td>
          <td className="servicePlan">{checkmarkIcon}</td>
          <td className="servicePlan">{checkmarkIcon}</td>
        </tr>
        <tr className="bottomBorder">
          <td className="serviceDetails">Profilaufrufe & Followeranzahl</td>
          <td className="servicePlan">{checkmarkIcon}</td>
          <td className="servicePlan">{checkmarkIcon}</td>
        </tr>
        <tr className="bottomBorder">
          <td className="serviceDetails">Kundendemografie (Alter & Geschlecht)</td>
          <td className="servicePlan">{checkmarkIcon}</td>
          <td className="servicePlan">{checkmarkIcon}</td>
        </tr>
        <tr className="bottomBorder">
          <td className="serviceDetails">Kundenstamm & Herkunft</td>
          <td className="servicePlan">{cancelIcon}</td>
          <td className="servicePlan">{checkmarkIcon}</td>
        </tr>
        <tr>
          <td className="serviceDetails">Beliebte Clubs und Musikgenre</td>
          <td className="servicePlan">{cancelIcon}</td>
          <td className="servicePlan">{checkmarkIcon}</td>
        </tr>
      </tr>
    </tbody>
    </table>
  </div>
</>;
}

// ZAHLUNGEN
const stripePromise = loadStripe('pk_live_51J8PWkFdYz2NQ6zW2GeAoUEU5tuw92p2GpVkKaZxd766RgO8VGfghuTpixRMJ1zUTXpuDUdbTB2qSLx29zEKpX2y00mCnyqCAo');

function Billing({handleClose}) {
  const [searchParams, setSearchParams] = useSearchParams();
  // eslint-disable-next-line no-unused-vars
  const [events,setEvents] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [ticketCountsList,setTicketCountsList] = useState([])
  const {user, organizerId} = useContext(UserContext) 
  const tableRef = useRef(null)
  const [current, setCurrent] = useState(null);
  const [nextMembership, setNextMembership] = useState(null);
  useEffect(() => {
    onSnapshot(
      query(
        collection(firestore, 'membership_subscriptions'),
        where('organizer', '==', organizerId),
        where('status', 'in', ['active', 'active_canceled', 'active_unpaid']),
        limit(1),
      ),
      e => setCurrent(e.empty ? null : e.docs[0])
    );
  }, [organizerId]);
  useEffect(() => {
    if(!current?.exists) {
      setNextMembership(null);
      return;
    }
    getDoc(doc(collection(firestore, 'memberships'), current.data().membership)).then(async membershipDoc => {
      if(!membershipDoc.exists) {
        setNextMembership(null);
        return;
      }
      let nextDoc;
      if(membershipDoc.data().renew_as) {
        nextDoc = await getDoc(doc(collection(firestore, 'memberships'), membershipDoc.data().renew_as));
      } else {
        nextDoc = membershipDoc;
      }
      setNextMembership(nextDoc);
    });
  }, [current]);

  const [paymentsDoc, setPaymentsDoc] = useState(null);
  useEffect(() => { getDoc(doc(collection(doc(collection(firestore, 'organizers'), organizerId), 'private'), 'payments')).then(setPaymentsDoc) }, [organizerId]);

  const [editBankAccount, setEditBankAccount] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [paymentId, setPaymentId] = useState(null);
  const initiatePayment = async () => {
      try {
          setPaymentId(null);
          setClientSecret(null);

          setEditBankAccount(true);

          const createPayment = httpsCallable(functions, 'payments-createPayment');
          const { paymentDocId, paymentSecret } = (await createPayment({
              membershipSubscription: current?.data().status === 'active_unpaid' ? current.id : null,
              setupFutureUse: true,
          })).data;
          
          setPaymentId(paymentDocId);
          setClientSecret(paymentSecret);
      } catch(e) {
          console.error(e);
      }
  };

  return <>
    <div className="ModalContentTitleDetails">
      Hier hast du Einsicht in dein Abomodell, deine hinterlegte Karte und deine Rechnungen
    </div>
    <div className="ModalContentSectionBox">
      <div className="ModalContentSection">
        <div className="ModalContentSectionTitle">Dein Abo</div>
        <div className="ModalContentSectionBar"/>
        <div className="rowItem">
          <div className="rowItemLeft" style={{fontSize: "var(--fontsize-large)", color: "var(--textMain)"}}>{current ? 'Premium' : 'Basic'}</div>
          <div className="rowItemRight" style={{gap: "10px", alignItems: "center"}}>
            {
              current?.data().status === 'active'
              ? <div className="planDetails">{(nextMembership?.data().price / 100).toFixed(2).replace('.', ',')}€ pro Monat · erneuert am {current?.data().expiration?.toDate().toLocaleDateString('de-DE')}</div>
              : current?.data().status === 'active_canceled'
              ? <div className="planDetails">gekündigt am {current?.data().canceled_timestamp?.toDate().toLocaleDateString('de-DE')} · läuft ab am {current?.data().expiration?.toDate().toLocaleDateString('de-DE')}</div>
              : current?.data().status === 'active_unpaid'
              ? <div className="planDetails" style={{color: 'var(--red)', textAlign: "right"}}>Es gab ein Problem mit deiner letzten Zahlung.<br></br>Bitte aktualisiere deine verknüpfte Karte.</div>
              : <div className="planDetails"></div>
            }
            {
              current && current?.data().status !== 'active_canceled'
              ? <div className="cancelPlan" onClick={() => {
                if(current?.data().status === 'active_unpaid') {
                  alert('Bitte bezahle deine offenen Rechnungen bevor du dein Abo kündigst.');
                  return;
                }
                if(!window.confirm(`Bist du dir sicher, dass du dein Abonnement beenden willst? Wenn du kündigst, verlierst zum ${current?.data().expiration?.toDate().toLocaleDateString('de-DE')} alle Vorteile deines Abonnements.`))
                  return;
                updateDoc(current.ref, {
                  status: 'active_canceled',
                });
              }}>Abo kündigen</div>
              : current?.data().status === 'active_canceled'
              ? <div className="cancelPlan" onClick={() => {
                if(!window.confirm(`Bist du dir sicher, dass du dein Abonnement doch fortsetzen willst? Wir buchen dann weiterhin von deiner hinterlegten Karte monatlich ${(nextMembership?.data().price / 100).toFixed(2).replace('.', ',')}€ ab.`))
                  return;
                updateDoc(current.ref, {
                  status: 'active',
                });
              }}>Abo fortsetzen</div>
              : <div className="cancelPlan" onClick={() => {
                searchParams.set('settings_page', 'subscriptions');
                setSearchParams(searchParams);
              }}>Abo wählen</div>
            }
          </div>
        </div>
      </div>
      {paymentsDoc?.data()?.payment_default_card?.id && <div className="ModalContentSection">
        <div className="ModalContentSectionTitle">Deine verknüpfte Karte</div>
        <div className="ModalContentSectionBar"/>
        {editBankAccount ? <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <button className="cardEditCancel" onClick={() => setEditBankAccount(false)}>Zurück</button>
            <Elements stripe={stripePromise} options={{
                mode: current?.data().status === 'active_unpaid' ? 'payment' : 'setup',
                amount: current?.data().status === 'active_unpaid' ? current.data().price : 0,
                setupFutureUsage: 'off_session',
                paymentMethodTypes: ['card', 'link'],
                currency: 'eur',
                locale: 'de',
                appearance: { theme: 'night', variables: { fontSizeBase: '12px' }},
            }}>
                <PaymentSheet
                    clientSecret={clientSecret}
                    billingDetails={{ email: user.email }}
                    price={current?.data().status === 'active_unpaid' ? current.data().price : null}
                    priceMonthly={nextMembership?.data().price??0}
                    onSuccess={() => setEditBankAccount(false)}
                    submitButtonText="Karte speichern"
                />
            </Elements>
          </div>
        </div> : <div className="rowItem">
        <div className="rowItemLeft" style={{fontSize: "var(--fontsize-large)", color: "var(--textMain)"}}>{paymentsDoc.data().payment_default_card.subtype?.toUpperCase()} {current?.data().status === 'active_unpaid' && '(Es gab ein Problem bei der letzten Zahlung)'}</div>
          <div className="rowItemRight" style={{gap: "10px", alignItems: "center"}}>
            <div className="planDetails">endet auf {paymentsDoc.data().payment_default_card.last4}</div>
            <div className="cancelPlan" onClick={initiatePayment}>Karte ändern</div>
          </div>
        </div>}
      </div>}
      <div className="ModalContentSection">
        <div className="ModalContentSectionTitle">Deine Rechnungen</div>
        <div className="ModalContentSectionBar"/>
        <div className="tableInvoicesBox">
          <div className="fixTableHead">
            <table id="html-table" style={{color:"var(--lightgrey)"}} className="table-ticket-overview" cellSpacing={"20%"}  cellPadding={"10%"} ref={tableRef} >
              <thead>
                <tr>
                  <th className="tableInvoicesElement" style={{width:"40%"}}>Beschreibung</th>
                  <th className="tableInvoicesElement" style={{width:"20%"}}>Datum</th>
                  <th className="tableInvoicesElement" style={{width:"15%"}}>Betrag</th>
                  <th className="tableInvoicesElement">Rechnung</th>
                </tr>
              </thead>
              <tbody className="table-invoices">
                {Object.keys(events).length !== 0 ? 
                Object.keys(events).map((key,index) => {
                  return(
                    <tr>
                      <td style={{borderRight:"0",textAlign:"start",width:"40%"}}> {events[key].data().name ?? "N/a"}</td>
                      <td style={{borderRight:"0",textAlign:"start",width:"20%"}}> {events[key].data().start.toDate().toLocaleDateString("de-DE", {weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric'}) ?? "N/a"}</td>
                      <td style={{borderRight:"0",textAlign:"start",width:"15%"}}> {ticketCountsList[index] * 6  ?? "N/a" }€</td>
                      <td style={{borderRight:"0",textAlign:"start"}}> 
                        <div className="ModalPaymentsDownloadButton" onClick={() => {alert("Das funktioniert noch nicht!")}}>
                          <div color="black">{downloadIcon}</div>
                          <div>PDF Herunterladen</div>
                        </div>
                      </td>
                    </tr>
                  )
                })
                :
                  <>
                    <tr style={{display:"flex",justifyContent:"center",marginTop:"10px", marginBottom:"20px",fontSize:"var(--fontsize-large)"}}>
                      <td style={{border:"none"}}>Du hast noch keine Rechnungen</td>
                    </tr>
                  </>
                }
              </tbody>
            </table>
          </div>
        </div> 
      </div>
    </div>
  </>;
}

// TICKETING


// TICKETING
function Ticketing({ handleClose }) {
  const { permissions } = useContext(UserContext);
  const { organizerId } = useContext(UserContext);
  const [loadingOnboarding, setLoadingOnboarding] = useState(false);
  const [organizer, setOrganizer] = useState(null);
  const [events, setEvents] = useState([]);
  const [paymentsDoc, setPaymentsDoc] = useState(null);
  const [editBankAccount, setEditBankAccount] = useState(null);
  const tableRef = useRef(null);

  useEffect(() => {
      getDoc(doc(collection(firestore, 'organizers'), organizerId))
          .then(doc => setOrganizer(doc));
  }, [organizerId]);

  useEffect(() => {
      getDocs(query(collection(firestore, "documents"), where("organizer", "==", organizerId)))
          .then(data => setEvents(data.docs));
  }, [organizerId]);

  useEffect(() => {
      onSnapshot(doc(collection(doc(collection(firestore, 'organizers'), organizerId), 'private'), 'payments'), e => {
          setPaymentsDoc(e.exists() ? e : null);
      });
  }, [organizerId]);

  useEffect(() => {
      if (paymentsDoc && editBankAccount === null) {
          setEditBankAccount(!paymentsDoc.data()?.bank_account?.id);
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsDoc]);

  const activatePaymentProvider = (provider) => {
      setLoadingOnboarding(true);
      showLoader("loader_stripe");
      const onboard = httpsCallable(functions, "payments-onboard");
      onboard({
          paymentProvider: provider,
          returnUrl: window.location.href,
          refreshUrl: window.location.href,
      })
          .then((res) => window.location.replace(res.data.onboardingUrl))
          .catch((error) => console.log(error.code));
  };

  return (
      <>
          <LoaderDivWithOverlay id="loader_stripe"/>
          <div className="ModalContentTitleDetails">
              Hier kannst du deinen Account für das ELGIO Ticketing aktivieren, Angaben bearbeiten und dann deine Abrechnugnen einsehen.
          </div>
          <div className="ModalContentSectionBox">
              {permissions?.ticketing?.setup && <div className="ModalContentSection">
                  <div className="ModalContentSectionTitle">Verifizierung</div>
                  <div className="ModalContentSectionBar" />
                  <div className="verifyRow">
                      <div className="rowItemLeft">
                          {['active', 'bank_account_required'].includes(paymentsDoc?.data().payment_account?.status) && (
                              <div className="ticketing-activation-status success-text">
                                  ELGIO Ticketing wurde erfolgreich aktiviert
                              </div>
                          )}
                          {paymentsDoc?.data().payment_account?.status === 'update_required' && (
                              <div className="ticketing-activation-status warning-text">
                                  ELGIO Ticketing ist zur Zeit noch aktiv, jedoch fehlen uns noch ein paar Angaben von dir.
                                  {paymentsDoc.data().payment_account.update_deadline && (
                                      <>
                                          <br />
                                          Bitte vervollständige deine Angaben bis zum {paymentsDoc.data().payment_account.update_deadline.toDate().toLocaleDateString("de-DE", { weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric' })}, sonst müssen wir ELGIO Ticketing für dein Konto temporär sperren.
                                      </>
                                  )}
                              </div>
                          )}
                          {paymentsDoc?.data().payment_account?.status === 'inactive' && (
                              <div className="ticketing-activation-status warning-text">
                                  ELGIO Ticketing wurde für dein Konto temporär deaktiviert.<br />
                                  Bitte vervollständige deine Daten, um ELGIO Ticketing wieder zu aktivieren.
                              </div>
                          )}
                          {!paymentsDoc?.data().payment_account?.status && (
                              <div className="ticketing-activation-status warning-text">
                                  Um ELGIO Ticketing zu aktivieren, fehlen uns noch ein paar Angaben von dir.
                              </div>
                          )}
                      </div>
                      <div className="rowItemRight">
                          <div className="activate-button-payment" disabled={loadingOnboarding} onClick={() => activatePaymentProvider("stripe")}>
                              <div className="activate-button-payment-text">
                                  {loadingOnboarding ? 'Wird geladen...' : organizer?.data().payment_account ? 'Angaben anpassen' : 'Angaben machen'}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>}
              {permissions?.ticketing?.setup && organizer?.data().payment_account && (
                <div className="ModalContentSection">
                  <div className="ModalContentSectionTitle">Auszahlungskonto</div>
                  <div className="ModalContentSectionBar" />
                    <BankInput />
                </div>
              )}
              <div className="ModalContentSection">
                  <div className="ModalContentSectionTitle">Rechnungen</div>
                  <div className="ModalContentSectionBar" />
                  <div className="tableInvoicesBox">
                      <div className="fixTableHead">
                          <table id="html-table" style={{ color: "var(--lightgrey)" }} className="table-ticket-overview" cellSpacing={"20%"} cellPadding={"10%"} ref={tableRef}>
                              <thead>
                                  <tr>
                                      <th  className="invoices-table-header-name" > Eventname</th>
                                      <th  className="invoices-table-header-date el-visible-large d-table" data-table>Datum</th>
                                      <th  className="invoices-table-header-price  el-visible-large d-table" data-table>Betrag</th>
                                      <th ></th>
                                  </tr>
                              </thead>
                              <tbody className="table-invoices">
                                  {events.length !== 0 ? (
                                      events.map((event, index) => {
                                          const eventData = event.data();
                                          const eventName = eventData.event_name ?? "N/a";
                                          const eventStart = eventData.event_start ? eventData.event_start.toDate().toLocaleDateString("de-DE") : "N/a";
                                          const payout = eventData.payout ? `${eventData.payout}€` : "N/a";
                                          const pdfRef = eventData.pdf;

                                          return (
                                              <tr key={index}>
                                                  <td className="invoices-table-body-name" >{eventName}</td>
                                                  <td className="el-visible-large invoices-table-body-date" data-table>{eventStart}</td>
                                                  <td className="el-visible-large invoices-table-body-price" data-table>{payout}</td>
                                                  <td className="invoices-table-body-download">
                                                      <div className="ModalPaymentsDownloadButton" onClick={async () => {
                                                          let downloadUrl = await getDownloadURL(ref(storage, pdfRef));
                                                          window.open(downloadUrl, '_blank').focus();
                                                      }}>
                                                          <div color="black">{downloadIcon}</div>
                                                            <div>Rechung</div>
                                                      </div>
                                                  </td>
                                              </tr>
                                          );
                                      })
                                  ) : (
                                      <tr style={{ display: "flex", justifyContent: "center", marginTop: "10px", marginBottom: "20px", fontSize: "var(--fontsize-large)" }}>
                                          <td style={{ border: "none" }}>Du hast noch keine Abrechnungen</td>
                                      </tr>
                                  )}
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </>
  );
}


// Team Member


function Teammember({ handleClose }) {
  const { user, organizerId, permissions } = useContext(UserContext);

  const[addUser, setAddUser] = useState(false)
  const handleCloseAddUser = () => {
    setNewMember(null);
    setAddUser(false);
  }

  const[newMember, setNewMember] = useState();

  const addUserModal = Modal.setAppElement('#portal')

  //permission presets
  const [permissionPresets, setPermissionPresets] = useState(null);
  useEffect(() => {
    getDocs(collection(firestore, 'permission_presets')).then((snapshot) => {
      const presets = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
      setPermissionPresets(presets);
    });
  }, []);

  //team members
  const [teamMembers, setTeamMembers] = useState(null);
  useEffect(() => {
    getDoc(doc(firestore, 'organizers', organizerId)).then(snap => {
      Promise.all(snap.data().users.map(userId => getDoc(doc(firestore, 'users', userId)))).then(users => {
        setTeamMembers(users.map(user => ({id: user.id, ...user.data()})));
      });
    });
  }, [organizerId]);

  //invitations
  const [invitations, setInvitations] = useState(null);
  useEffect(() => {
    getDocs(query(collection(firestore, 'invitations'), where('organizer', '==', organizerId))).then(snap => {
      setInvitations(snap.docs.map(doc => ({id: doc.id, ...doc.data()})));
    });
  }, [organizerId]);
  const teamMembersWithInvitations = useMemo(() => [
    ...(teamMembers?.sort((a, b) => a.id === user.uid ? -1 : b.id === user.uid ? 1 : 0)??[]),
    ...(invitations??[]).map(invitation => ({isInvitation: true, ...invitation})),
  ], [teamMembers, invitations]);

  //editing
  const [editNameOf, setEditNameOf] = useState(null);
  const updateField = (id, field, value) => {
    if(teamMembers.some(member => member.id === id)) {
      updateDoc(doc(firestore, 'users', id), { [field]: value });
      setTeamMembers(teamMembers.map(member => member.id === id ? {...member, [field]: value } : member));
    } else {
      updateDoc(doc(firestore, 'invitations', id), { [field]: value });
      setInvitations(invitations.map(invitation => invitation.id === id ? {...invitation, [field]: value } : invitation));
    }
  };
  const deleteMember = (id) => {
    if(!window.confirm('Bist du sicher, dass du diesen Benutzer entfernen möchtest?')) return;
    if(teamMembers.some(member => member.id === id)) {
      alert('Diese Funktion ist noch nicht implementiert. Bitte kontaktiere uns, wenn du einen Benutzer entfernen möchtest: support@elgio.de');
    } else {
      deleteDoc(doc(firestore, 'invitations', id));
      setInvitations(invitations.filter(invitation => invitation.id !== id));
    }
  };
  const addMember = () => {
    addDoc(collection(firestore, 'invitations'), {
      type: 'organizer',
      organizer: organizerId,
      permission_preset: newMember.permission_preset??'organizer_admin',
      ...newMember,
    });
    setInvitations([...(invitations??[]), newMember]);
    handleCloseAddUser();
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        <div className="ModalContentTitleDetails">
          Hier kannst du verwalten, wer alles diesen Account mitbenutzen kann.<br />
        </div>
      </div>
      <div className="t-white" style={{overflowY: "scroll", scrollbarWidth: "none"}}>
        <div className="addMemberRow">
          <div className="big font-semibold">Benutzer</div>
          {permissions?.users?.edit && <button className="addMemberButton" onClick={() => setAddUser(true)}>{plusIcon} Neuer Benutzer</button>}
        </div>
        <div className="memberColumn">
          {teamMembersWithInvitations?.map(member => (
            <div key={member.id} className="memberItem">
              <div className="leftSideMember">
                <div className="nameInputMember">
                  {editNameOf === member.id
                    ? <input className="TextInput" style={{height: "25px"}} value={member.name} type="text" onChange={e => updateField(member.id, 'name', e.target.value)} onKeyDownCapture={e => e.key === 'Enter' && setEditNameOf(null)} />
                    : <div className="medium">{member.name} {member.id === user.uid && <span className="t-secondary regular" style={{marginLeft: "5px"}}>(Du)</span>} </div>
                  }
                  {permissions?.users?.edit && <button className="t-bg4 pointer" onClick={() => setEditNameOf(e => e === member.id ? null : member.id)}>
                    {editNameOf === member.id ? checkmarkIcon : editIcon}
                  </button>}
                </div>
                <div className="regular">{member.email}</div>
              </div>
              <div className="rightSideMember">
                {member.isInvitation && <div className="small t-secondary">(Einladung verschickt)</div>}
                <div className="selectBoxMember">
                  <select
                    className="selectMember"
                    value={permissionPresets?.map(e => e.id).includes(member.permission_preset) ? member.permission_preset : 'custom'}
                    onChange={e => updateField(member.id, 'permission_preset', e.target.value)}
                    disabled={!permissions?.users?.edit || member.id === user.uid}
                  >
                    {permissionPresets?.map(preset => <option key={preset.id} value={preset.id}>{preset.name}</option>)}
                    {!permissionPresets?.map(e => e.id).includes(member.permission_preset) && <option value="custom">Benutzerdefiniert</option>}
                  </select>
                  {member.id !== user.uid && permissions?.users?.edit && <button className="btnDeleteMember pointer" onClick={() => deleteMember(member.id)}>{deleteIcon}</button>}
                  {member.id == user.uid && permissions?.users?.edit && <div style={{color: "var(--bg2)"}}>{deleteIcon}</div>}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <div className="regular t-white" style={{marginBottom: "5px", marginTop: "20px"}}>Was bedeuten die verschiedenen Rechte?</div>
          {permissionPresets?.map(preset => <div key={preset.id} className="small t-secondary" style={{marginBottom: "5px"}}>{preset.name} - {preset.description}</div>)}
        </div>
      </div>
      <Modal 
        isOpen={addUser}
        appElement={addUserModal}
        unselectable="true"
        autoFocus={false}
        className="modalContentMember"
        overlayClassName="modalOverlayNormal"
        shouldFocusAfterRender={false}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseAddUser}
      >
        <div className="col-flex" style={{gap:"2rem",height:"100%", alignItems: "center"}}>
          <div className="col-flex" style={{width: "100%", gap: "20px"}}>
            <div className="row-flex" style={{justifyContent:"space-between",alignItems:"center"}}>
              <div style={{fontWeight: "600", fontSize: "var(--fontsize-enormous)", color: "var(--textMain)"}}>Benutzer hinzufügen</div>
              <div onClick={handleCloseAddUser} style={{cursor:"pointer", color: "var(--textMain)", fontSize: "var(--fontsize-gigantic)"}}>{cancelIcon}</div>
            </div>
            <div className="t-white gap-3 flex flex-col col-flex">
              <div className="rowInputAddMember">
                <div className="col-input" style={{width: "100%"}}>
                  <div className="input-title">Benutzername</div>
                  <div>
                    <input className="TextInput" style={{width: "calc(100% - 10px)"}} placeholder="z.B. Dieter" value={newMember?.name} onChange={e => setNewMember({...newMember, name: e.target.value})} type="text" />
                  </div>
                </div>
                <div className="col-input" style={{width: "100%"}}>
                  <div className="input-title">Email-Adresse</div>
                  <div>
                    <input className="TextInput" style={{width: "calc(100% - 10px)"}} placeholder="z.B. dieter@e.de" value={newMember?.email} onChange={e => setNewMember({...newMember, email: e.target.value})} type="text" />
                  </div>
                </div>
              </div>
              <div className="col-input selectAdd">
                <div className="input-title">Rechte</div>
                <select
                  className="selectNewMember"
                  value={newMember?.permission_preset}
                  onChange={e => setNewMember({...newMember, permission_preset: e.target.value})}
                >
                  {permissionPresets?.map(preset => <option key={preset.id} value={preset.id}>{preset.name}</option>)}
                </select>
              </div>
              <div>
                <div className="regular t-white" style={{marginBottom: "5px", marginTop: "15px"}}>Was bedeuten die verschiedenen Rechte?</div>
                {permissionPresets?.map(preset => <div key={preset.id} className="small t-secondary" style={{marginBottom: "5px"}}>{preset.name} - {preset.description}</div>)}
              </div>
            </div>
          </div>
          <div className="addUserBtn" style={{cursor:"pointer"}} onClick={addMember}>Einladung schicken</div>
        </div>
      </Modal>
    </>
  );
}