/* eslint-disable react-hooks/exhaustive-deps */
import { addDoc, collection, deleteDoc, doc, onSnapshot, query, where } from 'firebase/firestore'
import React, { useContext, useState } from 'react'
import "../../../index.scss";
import { useEffect } from 'react'
import { useRef } from 'react'
import Modal from 'react-modal'
import { useParams } from 'react-router-dom'
import { firestore } from '../../../firebase'
import Papa from 'papaparse'
import { UserContext } from "../../../providers/UserProvider";
import Template from "../../../graphics/CSV-Vorlage.png"
import "./gaesteliste.css";
import { cancelIcon, deleteIcon, searchIcon } from '../../../icons';

function Gaesteliste({show,handleClose}) {
  const el = Modal.setAppElement('#portal')
  const el2 = Modal.setAppElement('#portal2')
  const [totalNumber,setTotalNumber] = useState(35)
  const {id} = useParams();
  const [searchQuery, setSearchQuery] = useState('');
  const {organizerId, permissions} = useContext(UserContext)
  // const docRef = doc(collection(firestore,"events"),id)
  const [gaesteliste,setGaesteliste] = useState([])
  const [name,setName] = useState("")
  const [anmerkung,setAnmerkung] = useState("")
  const tableRef = useRef(null)
  // const [organizerName,setOrganizerName] = useState("")
  const [showTemplate,setShowTemplate] = useState(false)
  const handleCloseTemplate = () => {
    setShowTemplate(false)
  }
  const [scanner,setScanner] = useState([])
  const [activeList,setActiveList] = useState(1)
  useEffect(() => {
    onSnapshot(
        query(
            collection(firestore, 'users'),
            where('event', '==', id)
        ),
        (snap) => setScanner(snap.docs.map(e => ({ id: e.id, ...e.data() }))??[])
    )
  }, [id])
  // useEffect(() => {
  //   getDoc(doc(collection(firestore,"organizers"),organizerId)).then(e => {setOrganizerName(e.data().name)})
  // },[])

  useEffect(() => {
    setTotalNumber(gaesteliste.length)
  },[gaesteliste])

  const handleSelect = (e) => {
    if(e.currentTarget.value === "1" && activeList !== 1){
      setActiveList(1)
    }
    else if(e.currentTarget.value === "2" && activeList !== 2){
      setActiveList(2)
    }
  } 

  useEffect(() => {
    onSnapshot(
      query(
        collection(firestore, 'tickets'),
        where('event', '==', id),
        where('type', "==", "guestlist")
       
      ),
      (snap) => setGaesteliste(snap.docs.map(e => ({
        id: e.id,
        name: e.data().first_name,
        liste: (() => {
          switch(e.data().list){
            case 'guestlist': return 'Gästeliste'
            case 'friendslist': return 'Freundesliste'
            default: return e.data().list
          }
        })(),
        addedBy: (() => {
          switch(e.data().created_by) {
            case organizerId: return 'Admin';
            default: return scanner.find(f => f.id === e.data().created_by)?.data().name??e.data().created_by;
          }
        })(),
        addedVia: (() => {
          switch(e.data().created_via) {
            case 'dashboard': return 'Dashboard';
            case 'ticketscanner': return 'Scan-App';
            case 'import': return 'Import';
            default: return e.data().created_via;
          }
        })(),
        ...e.data(),
      }))),
    )
  }, [id, firestore])


  const addGast = (name,anmerkung,addedVia) => {
    addDoc(
      collection(firestore, 'tickets'),
      {
        first_name: name,
        created_by: organizerId,
        created_via: addedVia,
        comment: anmerkung,
        type: 'guestlist',
        list: activeList === 1 ? 'guestlist' : 'friendslist',
        status: 'confirmed',
        event: id,
        seller: organizerId,
      },
    );
  }

  const deleteGast = async (index) => {
      let g = gaesteliste[index]
      deleteDoc(
        doc(collection(firestore, 'tickets'), g.id),
      );
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      Papa.parse(file, {
        header: true, // Treat the first row as headers
        complete: (result) => {
          for (let index = 0; index < result.data.length; index++) {
            if(result.data[index].Name.length !== 0){
              const name = result.data[index].Name
              const comment = result.data[index].Anmerkung
              addGast(name,comment,"import")
            } 
          }
        },
      });
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

  const isFullWidth = windowWidth < 768;

  return (
    <Modal
      isOpen={show}
      appElement={el}
      unselectable="true"
      autoFocus={false}
      className="modalContentSmall"
      overlayClassName="modalOverlayNormal"
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleClose}
      style={{
        content: {
            padding: isFullWidth ? "20px" : "25px 35px",
        },
      }}
    >
      <div className="col-flex" style={{gap:"2rem", height:"100%"}}>
        <div className="row-flex" style={{justifyContent:"space-between",alignItems:"center"}}>
          <div className="ModalHeader">Gästeliste</div>
          <div onClick={handleClose} className="cancelModal">{cancelIcon}</div>
        </div>
        <div style={{color:"var(--lightgrey)", fontSize: "var(--fontsize-large)"}}>
          Hier kannst du deine Gästeliste verwalten, die für deine Türsteher auch in der Scanapp einsehbar ist.
        </div>
        {permissions?.ticketing?.edit && <div className="row-flex flex-gap-1rem">
          <div className="col-flex flex-gap-1rem" style={isFullWidth ? {width:"100%"} : {width:"60%"}}>
            <div style={{width:"fit-content", fontSize: "var(--fontsize-large)", color: "var(--textMain)", fontWeight: "600"}}>Gast hinzufügen</div>
            <div className="guestlistBoxAdd">
              <div className="InputColumn">
                <div style={{color: "var(--textMain)"}}>Name</div>
                <input type="text" className='GuestInput' value={name} onChange={(e) => {setName(e.target.value)}} onKeyPress={(e) => {if(e.key === 'Enter' && anmerkung && name){
                  addGast(name,anmerkung,"dashboard");setName("");setAnmerkung("")
                }}} placeholder='z.B. Fabian + 2'/>
              </div>
              <div className="InputColumn">
                <div style={{color: "var(--textMain)"}}>Anmerkung</div>
                <input type="text" className='GuestInput' value={anmerkung} onChange={(e) => {setAnmerkung(e.target.value)}} onKeyPress={(e) => {if(e.key === 'Enter' && anmerkung && name){
                  addGast(name,anmerkung,"dashboard");setAnmerkung("");setName("")
                }}} placeholder='z.B. Freunde'/>
              </div>
              <div className="InputColumn">
                <div style={{color: "var(--textMain)"}}>Liste</div>
                <select className="GuestInput" onChange={(e) => {handleSelect(e)}}>
                  <option value="1">Gästeliste</option>
                  <option value="2">Freundesliste</option>
                </select>
              </div>
              <div className='hinzufuegenBtn Monsterrat_400 fontSize-16px white' style={{cursor:"pointer"}} onClick={() => {addGast(name,anmerkung,"dashboard");setName("");setAnmerkung("")}}>Hinzufügen</div>
            </div>
          </div>
        </div>}
        <div className="midContent">
          <div className='leftSideMid'>
            <input type="text" className="input-suchfeld" placeholder="z.B. Name" value={searchQuery} onChange={e => setSearchQuery(e.target.value)}></input>
            <div className="searchIconGuest" onClick={() => {document.getElementById("suchfeld-tickets")?.click()}}>{searchIcon}</div>
          </div>
          {permissions?.ticketing?.edit && <div className='rightSideMid'>
            <div className="Montserrat_400 fontSize-16px white">Gästelisteanzahl (ohne +) : {totalNumber}</div>
            <div className="csvBtnBox row-flex flex-gap-1rem">
              <input type="file" accept='.csv' id="CsvImportInput" style={{display:"none"}} onChange={handleFileUpload}/>
              <div className="csvBtn CSV" onClick={() => {setShowTemplate(true)}}>CSV-Liste importieren</div>
            </div>
          </div>}
        </div>
        <div className='table-tickets'>
        <table id="html-table" className="table-ticket-overview" cellPadding={"10%"} ref={tableRef}>
    <thead className='table-tickets-head'>
      <tr>
        <th className="table-tickets-box">Name</th>
        <th className="table-tickets-box">Anmerkung</th>
        <th className="table-tickets-box">Liste</th>
        <th className="table-tickets-box">Hinzugefügt von</th>
        <th className={`table-tickets-box${!permissions?.ticketing?.edit ? " border-r-0" : ""}`}>Hinzugefügt über</th>
        {permissions?.ticketing?.edit && <th className="table-tickets-box border-r-0">Entfernen</th>}
      </tr>
    </thead>
    <tbody className='table-tickets-body' style={Object.keys(gaesteliste).length === 0 ? {overflowY:"unset"} : {}}>
      {Object.keys(gaesteliste).length !== 0 ? (
        Object.keys(gaesteliste)
          .filter(key =>
            gaesteliste[key]?.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            gaesteliste[key]?.comment.toLowerCase().includes(searchQuery.toLowerCase()) ||
            gaesteliste[key]?.addedBy.toLowerCase().includes(searchQuery.toLowerCase()) ||
            gaesteliste[key]?.addedVia.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .length > 0 ? (
            Object.keys(gaesteliste)
              .filter(key =>
                gaesteliste[key]?.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                gaesteliste[key]?.comment.toLowerCase().includes(searchQuery.toLowerCase()) ||
                gaesteliste[key]?.addedBy.toLowerCase().includes(searchQuery.toLowerCase()) ||
                gaesteliste[key]?.addedVia.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((key, i) => {
                return (
                  <tr key={key}>
                    <td className="cursor-text table-tickets-box">{gaesteliste[key]?.first_name}</td>
                    <td className="cursor-text table-tickets-box">{gaesteliste[key]?.comment !== 0 && gaesteliste[key]?.comment !== "" ? gaesteliste[key]?.comment : "-"}</td>
                    <td className="pointer table-tickets-box">{gaesteliste[key].liste}</td>
                    <td className="table-tickets-box">{gaesteliste[key]?.addedBy}</td>
                    <td className={`table-tickets-box${!permissions?.ticketing?.edit ? " border-r-0" : ""}`}>{gaesteliste[key]?.addedVia}</td>
                    {permissions?.ticketing?.edit && (
                      <td className="table-tickets-box border-r-0">
                        <div className="deleteBtn" onClick={() => {deleteGast(i)}}>{deleteIcon}</div>
                      </td>
                    )}
                  </tr>
                );
              })
          ) : (
            <tr>
              <td className='p-20p text-center fontSize-20px' colSpan={6}>
                Keine Ergebnisse
              </td>
            </tr>
          )
      ) : (
        <tr>
          <td className='p-20p text-center' colSpan={6}>
            Es ist keiner in der Gästeliste eingetragen!
          </td>
        </tr>
      )}
    </tbody>
  </table>
        </div>  
      </div>
      <Modal 
        isOpen={showTemplate}
        appElement={el2}
        unselectable="true"
        autoFocus={false}
        className="modalContentNormal"
        overlayClassName="modalOverlayNormal"
        shouldFocusAfterRender={false}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleCloseTemplate}
        style={{
            content: {
                backgroundColor: "var(--bg2)",
                width: "25vw",
                height: "fit-content",
                padding: "25px 30px",
            }}}>
        <div className="col-flex" style={{gap:"2rem",height:"100%"}}>
          <div className="col-flex ">
            <div className="row-flex" style={{justifyContent:"space-between",alignItems:"center"}}>
              <div style={{fontWeight: "600", fontSize: "var(--fontsize-enormous)", color: "var(--textMain)"}}>.csv Importieren</div>
              <div onClick={handleCloseTemplate} style={{cursor:"pointer", color: "var(--textMain)", fontSize: "var(--fontsize-gigantic)"}}>{cancelIcon}</div>
            </div>
            <div className="row-flex align_center">
              <div style={{color:"var(--lightgrey)", marginTop: "10px"}}>
                Der Import der CSV wird nur erfolgreich sein, wenn in das Format eingehalten wird. <br/>
                "Name" und "Anmerkung" müssen wie im unteren Beispiel in der ersten Reihe der ersten beiden Spalten stehen. <br/>
              </div>
            </div>
          </div>
          <div className="col-flex justify_center align_center" style={{gap:"2rem"}}>
            <img src={Template} width={230} height={100} alt=""/>
            <div className="hinzufuegenBtn Montserrat_400 fontSize-16px white" style={{cursor:"pointer"}} onClick={() => {setShowTemplate(false);document.getElementById('CsvImportInput').click()}}>Importieren</div>
          </div>
        </div>
      </Modal>    
    </Modal>
  )
}

export default Gaesteliste