/* LIST OF ICONS */

/* direction arrows */
export const chevronRightIcon = <i className="fa fa-chevron-right"></i>;
export const arrowUpIcon = <i className="fas fa-arrow-up"></i>;
export const chevronLeftIcon = <i className="fa fa-chevron-left"></i>;
export const chevronDownIcon = <i className="fa fa-chevron-down"></i>;
export const chevronUpIcon = <i className="fa fa-chevron-up"></i>;

/* action icons */
export const hinzufügenIcon = <i className="fas fa-plus-circle"></i>;
export const deleteIcon = <i className="far fa-trash-alt"></i>;
export const cancelIcon = <i className="fa-solid fa-xmark"></i>;
export const downloadIcon = <i className="fa-solid fa-download"></i>;
export const copyIcon = <i className="fa-solid fa-copy"></i>;
export const linkIcon = <i className="fa-solid fa-link"></i>;
export const plusIcon = <i className="fas fa-plus"></i>;
export const minusIcon = <i className="fa-solid fa-circle-minus"></i>;
export const playIcon = <i className="fa-solid fa-play"></i>;
export const stopIcon = <i className="fa-solid fa-pause"></i>;
export const logoutIcon = <i className="fas fa-sign-out-alt"></i>;
export const searchIcon = <i className="fa-solid fa-magnifying-glass"></i>;
export const editIcon = <i className="fa-solid fa-pen"></i>;
export const banIcon = <i className="fa-solid fa-ban"></i>
export const menuIcon = <i className="fa-solid fa-bars"></i>

/* event erstellen inputs */
export const titelbildIcon = <i className="far fa-images"></i>;
export const nameIcon = <i className="fas fa-signature"></i>;
export const datumIcon = <i className="fas fa-calendar"></i>;
export const adresseIcon = <i className="fas fa-map-marked-alt"></i>;
export const genreIcon = <i className="fas fa-music"></i>;
export const lineupIcon = <i className="fas fa-microphone"></i>;
export const minAlterIcon = <i className="fas fa-user-lock"></i>;
export const dresscodeIcon = <i className="fas fa-tshirt fa-xs"></i>;
export const getraenkeIcon = <i className="fas fa-cocktail"></i>;
export const eintrittIcon = <i className="fas fa-ticket-alt"></i>;
export const beschreibungIcon = <i className="fas fa-edit"></i>;
export const zusatzAdresseIcon = <i className="fas fa-map-marked-alt"></i>;
export const coVeranstalterIcon = <i className="fas fa-users-cog"></i>;
export const crossPlattformIcon = <i className="fa-solid fa-photo-film"></i>;
export const specialIcon = <i className="fa-solid fa-star"></i>;
export const storyLinkIcon = <i className="fas fa-link" id="IconInField"></i>;
export const secretLocationIcon = <i className="fa-solid fa-location-pin-lock"></i>

/* special account erstellen inputs */
export const regionIcon = <i className="fas fa-map-marked-alt"></i>;
export const residentsIcon = <i className="fas fa-microphone"></i>;
export const kontaktdatenIcon = <i className="fas fa-id-card-alt fa-sm"></i>;
export const socialMediaIcon = <i className="fas fa-users-cog"></i>;
export const areasIcon = <i className="fa-solid fa-building"></i>;
export const jobsIcon = <i className="fa-solid fa-briefcase"></i>;
export const openingHoursIcon = <i className="fa-solid fa-clock"></i>;
export const legalIcon = <i className="fa-solid fa-scale-balanced"></i>;

/* SideNav */
export const dashboardIcon = <i className="fa-solid fa-house"></i>;
export const myEventsIcon = <i className="far fa-calendar"></i>;
export const ticketIcon = <i className="fa-solid fa-ticket"></i>;
export const statistikenIcon = <i className="fa-solid fa-chart-column"></i>;
export const profilIcon = <i className="far fa-address-card"></i>;
export const networkIcon = <i className="fa-solid fa-address-book"></i>;
export const sunIcon = <i className="fa-solid fa-sun"></i>;
export const moonIcon = <i className="fa-solid fa-moon"></i>;

/* used for Statistics */
export const infoIcon = <i className="fa-solid fa-circle-info"></i>;

/* used on Settings */
export const passwordIcon = <i className="fas fa-key"></i>;
export const discoveryIcon = <i className="fas fa-network-wired"></i>;
export const verificationIcon = <i className="fa-solid fa-stamp"></i>;
export const invoiceIcon = <i className="fas fa-ticket"></i>;
export const trackingIcon = <i className="fa-solid fa-link"></i>;
export const connectionIcon = <i className="fas fa-network-wired"></i>;
export const settingsIcon = <i className="fa-solid fa-gear"></i>;
export const accountSettingsIcon = <i className="fas fa-user-cog"></i>;
export const verknüpfungIcon = <i className="fa-solid fa-cloud-arrow-up"></i>;
export const subscriptionIcon = <i className="fa-solid fa-receipt"></i>;
export const checkmarkIcon = <i className="fa-solid fa-check"></i>;
export const helpIcon = <i className="fa-solid fa-question"></i>;

/* social media and brands*/
export const instagramIcon = <i className="fab fa-instagram"></i>;
export const youtubeIcon = <i className="fab fa-youtube"></i>;
export const tiktokIcon = <i className="fab fa-tiktok"></i>;
export const facebookIcon = <i className="fa-brands fa-facebook"></i>;
export const metaIcon = <i className="fa-brands fa-meta"></i>;
export const stripeIcon = <i className="fa-brands fa-stripe"></i>;
export const androidIcon = <i className="fa-brands fa-google-play"></i>
export const appleIcon = <i className="fa-brands fa-apple"></i>
export const spofityIcon = <i className="fa-brands fa-spotify"></i>;
export const soundcloudIcon = <i class="fa-brands fa-soundcloud"></i>;

/* contact details */
export const mailIcon = <i className="fa-solid fa-envelope"></i>;
export const phoneIcon = <i className="fa-solid fa-phone"></i>;
export const websiteIcon = <i className="fa-solid fa-globe"></i>;

/* performance & statistics */
export const viewsIcon = <i className="fas fa-eye"></i>;
export const followersIcon = <i className="fas fa-users"></i>;
export const sharesIcon = <i className="fas fa-share-alt"></i>;
export const participantsIcon = <i className="fa-solid fa-users"></i>;
export const bookmarksIcon = <i className="fa-solid fa-bookmark"></i>;

/* special */
export const excelIcon = <i className="fa-solid fa-file-excel"></i>;
export const pdfIcon = <i className="fa-solid fa-file-pdf"></i>;
export const warningIcon = <i className="fa-solid fa-triangle-exclamation"></i>;
export const euroIcon = <i className="fa-solid fa-euro-sign"></i>;

/* ticketing anlegen */
export const variantsIcon = <i className="fa-solid fa-clipboard-list"></i>;
export const paymentsIcon = <i className="fa-solid fa-credit-card"></i>;
export const finishIcon = <i className="fa-solid fa-flag-checkered"></i>;
export const clockIcon = <i className="fa-solid fa-clock"></i>;
export const numberIcon = <i className="fa-solid fa-hashtag"></i>;
export const bankIcon = <i className="fa-solid fa-building-columns"></i>;

/* discount codes */
export const listCheckIcon = <i className="fa-solid fa-list-check"></i>;
export const valueIcon = <i className="fa-solid fa-tag"></i>;
export const usageIcon = <i className="fa-solid fa-infinity"></i>;

/*password */
export const eyeIcon = <i className="fa-solid fa-eye"></i>;
export const closedEyeIcon = <i className="fa-solid fa-eye-slash"></i>;

/* benefits */
export const commissionIcon= <i className="fa-solid fa-coins"></i>;
export const webappIcon= <i className="fa-solid fa-desktop"></i>;
export const scannerIcon= <i className="fa-solid fa-qrcode"></i>;
export const friendslistIcon= <i className="fa-solid fa-clipboard-list"></i>;
export const customerServiceIcon= <i className="fa-solid fa-headset"></i>;
export const flexibilityIcon= <i className="fa-solid fa-person-running"></i>;
export const onePaymentIcon= <i className="fa-brands fa-creative-commons-nc-eu"></i>;

/* marketing */
export const marketingIcon= <i className="fa-solid fa-photo-film"></i>;
export const repeatIcon = <i className="fa-solid fa-repeat"></i>;
export const promoteIcon = <i className="fa-solid fa-fire"></i>;


/* network */
export const organizerLogoIcon = <i className="fa-regular fa-id-card" style={{fontSize:"larger"}}></i>