/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  useContext, useEffect, useMemo, useRef, useState } from "react";
import "../../../index.scss";
import "./event-einzeln.css";
import PostBox from "../../widgets/postbox.jsx";
import { Link, useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  updateDoc,
  setDoc,
  query,
  where,
  deleteDoc,
  orderBy,
  limit,
} from "firebase/firestore";
import { firestore, storage } from "../../../firebase";
import FirebaseStorageImage from "../../functions/FirebaseStorageImage";
import { UserContext } from "../../../providers/UserProvider";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { Loader } from "../../../loader";
import "../home.css";
import { ModalTicketing } from "../ticketing/ticketing_anlegen";
import phoneFrame from "../../../graphics/phone_frame.png";
import LoaderDivWithOverlay, { hideLoader } from '../../widgets/loaderdiv';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale, //x axis
  LinearScale, //y axis
  PointElement,
  Tooltip,
  Title,
  Filler,
  ArcElement,
  BarElement,
} from "chart.js";
import OrganizerName from "../../widgets/OrganizerName";
import GenreName from "../../widgets/GenreName";
import VenueAddress from "../../widgets/VenueAddress";
import ScannerHinzufuegen from "../ticketing/scanner-hinzufuegen";
import { StatsContext } from "../../../providers/StatsProvider";
import Gaesteliste from "../ticketing/gaesteliste";
import ProgressBar from "@ramonak/react-progress-bar";
import { useCallback } from "react";
import { 
  viewsIcon,
  participantsIcon,
  chevronLeftIcon,
  chevronRightIcon,
  bookmarksIcon,
  sharesIcon,
  warningIcon,
  plusIcon,
  pdfIcon,
  chevronDownIcon,
  editIcon,
  secretLocationIcon,
  promoteIcon,
} from "../../../icons.jsx";
import { PictureModal, PostModal } from "../marketing.jsx";
import PieChart from "../../widgets/statistics_widgets/pieChart";
import FurtherInfo from "../../widgets/tooltip";
import BarChart from "../../widgets/statistics_widgets/barChart";
import LineGraph from "../../widgets/statistics_widgets/lineGraph";
import LineChart from "../../widgets/statistics_widgets/lineChart";
import ContextMenu__Event from "../../widgets/contextmenu_events.jsx";
import { linkCopiedToast } from "../../widgets/toasts.tsx";
import { recurrenceTextMap } from "../../global_config/recurrenceTextMap.jsx";
import ArtistBearbeiten from "../../widgets/ArtistEdit.jsx";
import ArtistDetails from "../../widgets/ArtistDetails.jsx";


function EventEinzeln() {
  const { organizerId, permissions } = useContext(UserContext);
  useEffect(() => { getDoc(doc(collection(firestore, 'organizers'), organizerId)).then((organizer) => {setIsProUser(organizer.data().pro)}) }, [organizerId]);
  const [visibility, setVisibility] = useState(null);
  const [banner, setBanner] = useState(null);
  const [attendeeCount, setAttendeeCount] = useState(0);
  const [views, setViews] = useState(0);
  const [saved, setSaved] = useState(0);
  const [shares, setShares] = useState(0);
  const [club, setClub] = useState("");
  const [titel, setTitel] = useState("");
  const [date, setDate] = useState("");
  const [startDateRaw, setStartDateRaw] = useState("");
  const [end, setEnd] = useState("");
  const [endDateRaw, setEndDateRaw] = useState(null);
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [ticket, setTicket] = useState("");
  const [genre, setGenre] = useState([]);
  const [lineup, setLineup] = useState([]);
  const [price, setPrice] = useState(null);
  const [minAlter, setMinAlter] = useState("");
  const [dresscode, setDresscode] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [status, setStatus] = useState("Loading...");
  const [organizer, setOrganizer] = useState([]);
  const [specials,setSpecials] = useState([])
  const { id } = useParams();
  const [promoted, setPromoted] = useState(false);
  const [necessaryInp,setNecessaryInp] = useState(true)
  const [menuState,setMenuState] = useState(0)
  const [showModalTicketing,setShowModalTicketing] = useState(false)
  const [showModalAnlegen,setShowModalAnlegen] = useState(false)
  // const [comingSoon] = useState(true)
  const [eventOver,setEventOver] = useState(false)
  const [eventPost,setEventPost] = useState([])
  const [isProUser,setIsProUser] = useState(false)
  // Week or Month Posts
  const [otherPosts,setOtherPosts] = useState([])
  const [pdfRef,setPdfRef] = useState(null)

  const eventHasPassed = useMemo(() => endDateRaw < new Date(), [endDateRaw]);
  const [canceled,setCanceled] = useState(false)
  const [pendingReview,setPendingReview] = useState(false);

  const [searchParams] = useSearchParams();
  const [secretLocation, setSecretLocation] = useState(false)
  const [secretLocationRevealTime, setSecretLocationRevealTime ] = useState(null)
  useEffect(() => {
    // Fetch Week posts or Month posts including the specific event
    getDocs(
      query(
        collection(firestore,"social_media_posts"),
        where("organizer","==",organizerId),
        where("events","array-contains",id),
        orderBy("post_at","desc")
        )
        ).then((posts) => {
          setOtherPosts(posts.docs)
        })
        
        // Fetch Eventpost of the specific event
        getDocs(
          query(
            collection(firestore,"social_media_posts"),
            where("organizer","==",organizerId),
            where("event","==",id), 
            orderBy("post_at","desc")
      )
    ).then((post) => {
          setEventPost(post.docs)
        })
  },[])

  // For Modal
  const [show,setShow] = useState(false)
  const handleClose = () => setShow(false);

  // Show Enlarged Post Modal
  const [modalPic,setModalPic] = useState(null);

  // For Modal
  const [showGaesteListe,setShowGaesteListe] = useState(false)
  const handleCloseGaesteListe = () => setShowGaesteListe(false);

  // For Modal Artist
  const [showArtistEdit,setShowArtistEdit] = useState(false)
  const handleCloseArtistEdit = () => setShowArtistEdit(false);

  const [showPostModal,setShowPostModal] = useState(false)
  const [edit,setEdit] = useState(false)
  const [dataEdit,setDataEdit] = useState([])
  const [artistIdToEdit, setArtistIdToEdit] = useState(null)
  let navigate = useNavigate();

  const [ticketingLoading,setTicketingLoading] = useState(true)
  const [recurrenceKey, setRecurrenceKey] = useState("-")
                         
  const { loaded: statsLoaded, getTicketSales } = useContext(StatsContext);
  const [navBarVisible, setNavBarVisible] = useState(false)
  const animate = useCallback((index) => {
    document.getElementById("tab-wrapper").classList='';
    setTimeout(() => {
      setMenuState(index)
      if(index > menuState){
        document.getElementById("tab-wrapper").classList="animate__animated animate__fadeInRight"
      } else if(index < menuState) {
        document.getElementById("tab-wrapper").classList="animate__animated animate__fadeInLeft"
      }
    }, 100)
  }, [menuState])




  const [isParentEvent,setIsParentEvent] = useState(false)
  const [isSubeventDraft,setIsSubeventDraft] = useState(false)
  const [recurringEvent, setRecurringEvent] = useState(null)
  const [isSubevent, setIsSubevent] = useState(false)

  useEffect(() => {
    document.getElementsByClassName("loaderDiv")[0].style.visibility =
      "visible";
    searchParams.get("viewParent") === "true" ? 
    onSnapshot(doc(firestore, "recurring_events", id), (event) => {
      setIsParentEvent(true)
      setRecurrenceKey(event.data().recurrence)
      setVisibility(event.data().visibility);
      if(event.data().canceled){
        setStatus("Abgesagt")
      }
      else if (event.data().visibility === "draft") {
        setStatus('Offline');
      }
      else if (event.data().pending_review) {
        setStatus('Wird überprüft');
      }
      else if (event.data().visibility === "private") {
        setStatus("Privat")
      }
      else {
        setStatus('Online');
      }
      setBanner(
        event.data().images?.length > 0 ? event.data().images[0] : null
      );
      setTitel(event.data().name);
      setClub(event.data().venue);
      setOrganizer(event.data().organizer);
      setSpecials(event.data()?.specials)
      setNecessaryInp(
        event.data().name
        && event.data().start
        && event.data().images?.length
        && (event.data().address || event.data().venue)
        && event.data().genre?.length
        && (event.data().price || event.data().free || event.data().box_ticket_price || ["standard", "hidden"].includes(event.data().ticket))
        && event.data().description
      )
      if(event.data().start !== ""){
        setStartDateRaw(event.data()?.start?.toDate())
      setEndDateRaw(event.data()?.end?.toDate())
        setDate(event.data()?.start?.toDate()?.toLocaleDateString("de-DE"));
        setStartTime(
          event
            .data()
            ?.start?.toDate()
            ?.toLocaleTimeString("de-DE")
            ?.split(" ")[0].substring(0,5)
          );
          if(event.data().start?.toDate() < new Date()) setEventOver(true)
          setEnd(event.data()?.end?.toDate()?.toLocaleDateString("de-DE"));
          if(event.data().end !== "openEnd"){
            setEndTime(
              event.data()?.end?.toDate()?.toLocaleTimeString("de-DE")?.split(" ")[0].substring(0,5)
            );
          }
          else{
            setEnd("openEnd")
          }
      }
      setCanceled(event.data()?.canceled)
      setDescription(event.data().description);
      setPromoted(event.data().sponsored);
      setLocation(event.data().venue);
      setSaved(event.data().bookmark_count ?? 0);
      setViews(event.data().view_count ?? 0);
      setShares(event.data().share_count ?? 0);
      setAttendeeCount(event.data().attendee_count ?? 0);
      setTicket(event.data().ticket ?? "none");
      setAddress(event.data().address ?? "")
      setSecretLocation(event.data()?.secret_location)
      setSecretLocationRevealTime(event.data()?.secret_location_reveal_time)
      if (["standard", "hidden"].includes(event.data().ticket)) {
        setTicketingLoading(false)
      }
      setPendingReview(event.data().pending_review)
      setGenre(event.data().genre);
      setLineup(event.data().lineup);
      setPrice(event.data().price);
      setMinAlter(event.data().min_age?.toString());
      setDresscode(event.data().dresscode);
      setRecurringEvent(event.id)
      document.getElementsByClassName("loaderDiv")[0].style.visibility =
        "hidden";
      document.getElementById("status").style.visibility = "visible";

    }
    )
    :
    onSnapshot(doc(firestore, "events", id), (event) => {
        setIsParentEvent(false)
        setVisibility(event.data().visibility);
        setSecretLocation(event.data()?.secret_location)
        setSecretLocationRevealTime(event.data()?.secret_location_reveal_time)  
        if(event.data().canceled){
          setStatus("Abgesagt")
        }
        else if (event.data().visibility === "draft") {
          setStatus('Offline');
        }
        else if (event.data().pending_review) {
          setStatus('Wird überprüft');
        }
        else if (event.data().visibility === "private") {
          setStatus("Privat")
        }
        else {
          setStatus('Online');
        }
        setBanner(
          event.data().images?.length > 0 ? event.data().images[0] : null
        );
        setTitel(event.data().name);
        setClub(event.data().venue);
        setOrganizer(event.data().organizer);
        setPromoted(event.data().sponsored);
        setSpecials(event.data()?.specials)
        setNecessaryInp(
          event.data().name
          && event.data().start
          && event.data().images?.length
          && (event.data().address || event.data().venue)
          && event.data().genre?.length
          && (event.data().price || event.data().free || event.data().box_ticket_price || ["standard", "hidden"].includes(event.data().ticket))
          && event.data().description
        )
        if(event.data().start !== ""){
          setStartDateRaw(event.data()?.start?.toDate())
        setEndDateRaw(event.data()?.end?.toDate())
          setDate(event.data()?.start?.toDate()?.toLocaleDateString("de-DE"));
          setStartTime(
            event
              .data()
              ?.start?.toDate()
              ?.toLocaleTimeString("de-DE")
              ?.split(" ")[0].substring(0,5)
            );
            if(event.data().start?.toDate() < new Date()) setEventOver(true)
            setEnd(event.data()?.end?.toDate()?.toLocaleDateString("de-DE"));
            if(event.data().end !== "openEnd"){
              setEndTime(
                event.data()?.end?.toDate()?.toLocaleTimeString("de-DE")?.split(" ")[0].substring(0,5)
              );
            }
            else{
              setEnd("openEnd")
            }
        }
        setCanceled(event.data()?.canceled)
        setDescription(event.data().description);
        setLocation(event.data().venue);
        setSaved(event.data().bookmark_count ?? 0);
        setViews(event.data().view_count ?? 0);
        setShares(event.data().share_count ?? 0);
        setAttendeeCount(event.data().attendee_count ?? 0);
        setTicket(event.data().ticket ?? "none");
        setAddress(event.data().address ?? "")
        if (["standard", "hidden"].includes(event.data().ticket)) {
          setTicketingLoading(false)
        }
        setPendingReview(event.data().pending_review)
        setGenre(event.data().genre);
        setLineup(event.data().lineup);
        setPrice(event.data().price);
        setMinAlter(event.data().min_age?.toString());
        setDresscode(event.data().dresscode);
        setRecurringEvent(event.data()?.recurring_event)
        setIsSubeventDraft(event.data()?.recurring_event && event.data().visibility === "draft")
        setIsSubevent(![null,undefined].includes(event.data()?.recurring_event) && event.data().recurring_event.length > 1)
        if(event.data().has_invoice || event.data().hasInvoice){
          getDocs(
            query(
              collection(firestore,"documents"),
              where("event","==",id),
            )).then( (invoice) => {
              setPdfRef(invoice.docs[0].data().pdf);
            }
          )
        }
        if(document.getElementsByClassName("loaderDiv")[0] != null) document.getElementsByClassName("loaderDiv")[0].style.visibility = "hidden";
        if(document.getElementById("status") != null) document.getElementById("status").style.visibility = "visible";
      }
      ) 
  }, [id]);

  const [nextRecurringEvents,setNextRecurringEvents] = useState(null);
  useEffect(() => {
    if(!recurringEvent) return;
    getDocs(
      query(
        collection(firestore,"events"),
        where("start", ">", new Date()),
        where("recurring_event","==",recurringEvent),
      ),
    ).then((events) => {
      setNextRecurringEvents(events.docs);
    });
  }, [recurringEvent]);


  const {
    getCustomerStatuses,
    getCustomerAges,
    getCustomerGenders,
    getCustomerGenres,
    getCustomerClubs,
    getCustomerOrigin,
  } = useContext(StatsContext);


  const [customerStatuses, customerStatusesLabels] = getCustomerStatuses({ event: id });
  const [customerAges, customerAgesLabels] = getCustomerAges({ event: id });
  const [customerGenders, customerGendersLabels] = getCustomerGenders({ event: id });
  const [customerOrigin, customerOriginLabels] = getCustomerOrigin({ event: id });
  const [customerClubs, customerClubsLabels] = getCustomerClubs({ event: id });
  const [customerGenres, customerGenresLabels] = getCustomerGenres({ event: id });
  

  ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Title,
    ArcElement,
    BarElement,
    Filler)

    
    const handleLinkCopyClick = (e,id) => {
      e.stopPropagation();
      e.preventDefault();
      window.navigator.clipboard.writeText(`https://t.elgio.de/${id}`)
  }

  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setNavBarVisible(false);
    }
  };

  useEffect(() => {
    if (navBarVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navBarVisible]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

  const isFullWidth = windowWidth < 768;

  const [isFirstEvent, setIsFirstEvent] = useState(false);
  useEffect(() => {
    getDocs(
      query(
        collection(firestore, "events"),
        where("organizer", "==", organizerId),
        orderBy("start"),
        limit(1)
      )
    ).then((events) => {
      setIsFirstEvent(events.docs[0]?.id === id);
    });
  }, [id, organizerId]);

  return (
    <div className="content">
      <LoaderDivWithOverlay></LoaderDivWithOverlay>
      <div className="flex-gap-10" id="breadcrumb-title-text">
        <div style={{cursor: "pointer"}} onClick={() => navigate(-1)}>{chevronLeftIcon}</div>
        <div>
          <Link to="/dashboard/events" className="breadcrumb">Events</Link>
          &nbsp;/&nbsp;
          {titel}
        </div>
      </div>
      {promoted === true && <div className="not-all-inputs-done" id="promoInput">
        <div>{promoteIcon}</div>
        <div>Du hast dieses Event promoted</div>
      </div>}
      <Link to={"/dashboard/events/event-bearbeiten/" + id} style={necessaryInp ? {display: "none"} : {visibility:"visible"}}>
        <div className="not-all-inputs-done" id="warningInput">
          <div>{warningIcon}</div>
          <div id="notallInpText">Achtung: Diesem Event fehlen noch erforderliche Angaben. Klicke hier oder auf Bearbeiten um diese zu ergänzen.</div>
        </div>
      </Link>
      {pendingReview && (isFirstEvent ? <div className="not-all-inputs-done" id="disclaimerInput">
        <div>{warningIcon}</div>
        <div id="notallInpText">Dein erstes Event wird von uns manuell überprüft. In Zukunft geschieht diese Überprüfung i.d.R. automatisch und sofortig.</div>
      </div> : <div className="not-all-inputs-done" id="disclaimerInput">
        <div>{warningIcon}</div>
        <div id="notallInpText">Dieses Event konnte nicht automatisch überprüft werden und wird von uns manuell überprüft.</div>
      </div>)}
      <div className="eventEinzelnBox" style={{height:"100%",position:"relative"}}>
        {(isParentEvent || recurringEvent) && <div className="navbar-eventreihe" ref={menuRef}>
          <div className="navbar-eventreihe-header" onClick={() => setNavBarVisible(!navBarVisible)}>
            <div>{isParentEvent ? 'Eventreihe' : date}</div>
            <div>{navBarVisible ?  chevronDownIcon : chevronLeftIcon}</div>
          </div>

          {navBarVisible && 
            <div className="navbar-eventreihe-items">
              <Link to={"/dashboard/events/event/" + recurringEvent + "?viewParent=true"} className="navbar-eventreihe-item" onClick={() => setNavBarVisible(false)}>Eventreihe</Link>
              {nextRecurringEvents?.map((event) => {
                return(
                  <Link to={"/dashboard/events/event/" + event.id} className="navbar-eventreihe-item" onClick={() => setNavBarVisible(false)}>{event.data().start?.toDate().toLocaleDateString("de-DE")}</Link>
                )
              })}
            </div>
          }
        </div>}
        <div className="status-div" id="status" style={
          status === 'Online'
            ? !necessaryInp
              ? { visibility: "hidden", backgroundColor: "var(--bgGreen)"}
              : {visibility:"hidden",backgroundColor: "var(--bgGreen)"}
            : status === 'Wird überprüft'
              ? { visibility: "hidden", backgroundColor: "var(--bgOrange)"}
              : necessaryInp
                ? {visibility: "hidden",backgroundColor: "var(--bgRed)"} 
                : { visibility: "hidden", backgroundColor: "var(--bgRed)"}
        }>
          <div style={{color: "var(--buttonCoMain)"}}>
            Status: &nbsp;
            <span style={{color: "var(--buttonCoMain)", fontWeight: "600"}}>{status}</span>  
          </div>
        </div>
        <FirebaseStorageImage reference={banner}
          style={{
            width: "100%",
            height: "18vh",
            objectFit: "cover",
            borderRadius: "7px 7px 0px 0px ",
          }}
        ></FirebaseStorageImage>
        <div className="below-img-text">
          <div className="below-img-left">
            <div style={{ height: "100%" }}>
              <div id="BoxHeaderTitle">{titel}</div>
              <div className="BoxHeaderTitleDetails-Flex">
              {
                isParentEvent ?
                  <div id="BoxHeaderTitleDetails">  
                    {startTime !== undefined && recurrenceKey !== "-" ? recurrenceTextMap[recurrenceKey] + " ab " +  startTime +  " Uhr " : recurrenceKey !== "-" ? recurrenceTextMap[recurrenceKey] : "-"}
                  </div>
                : 
                <div id="BoxHeaderTitleDetails">
                   {date !== undefined && startTime !== undefined ? date + " ab " +  startTime +  " Uhr " : "Kein Datum"}
                  {startTime !== endTime && end !== "openEnd" && end !== null && endTime !== null && <>bis {endTime} Uhr</>}
                </div>
              }

                <div className="event-venue">
                  <div style={{fontSize: "var(--fontsize-medium", fontWeight: "600", color: "var(--textMain)", whiteSpace: "nowrap"}}>
                    <OrganizerName id={location} alt={address}/> 
                  </div>
                    - 
                  <div style={{fontSize: "var(--fontsize-medium"}}>
                    <VenueAddress id={location} alt={address} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="below-img-right">
            <div className="ButtonsBoxEinzel">
              <div className="row align_center" id="buttonFlexEvent">

                {visibility === "draft" && (
                  <a
                    id="ButtonsBoxItem"
                    className="bg-green Button white"
                    onClick={async () => {
                      if(startDateRaw < new Date()){
                        alert("Das Startdatum liegt in der Vergangeheit! Bitte änder dies um das Event live schalten zu können.")
                      }
                      else{
                      if (
                        necessaryInp &&
                        window.confirm(
                          "Du bist dabei das Event Live zu schalten.\nDanach kann dies nur noch von uns umgestellt werden!\nBist du dir sicher?"
                        ) === true 
                      ) {
                        updateDoc(doc(firestore, "events", id), {
                          visibility: "private",
                          pending_review: true,
                        });
                        //Marketing stuff
                        const data = (await getDoc(doc(firestore, "events", id))).data();
                        if(data.sync_to_external?.filter(e => e !== 'facebook_event').length > 0) {
                          setDoc(doc(collection(firestore,"social_media_posts")), {
                            type: 'event',
                            event: id,
                            post_at: new Date(),
                            caption: data.description,
                            banner: data.images[0],
                            organizer: organizerId,
                            facebook_post: data.sync_to_external.includes('facebook_post'),
                            facebook_story: data.sync_to_external.includes('facebook_story'),
                            instagram_post: data.sync_to_external.includes('instagram_post'),
                            instagram_story: data.sync_to_external.includes('instagram_story'),
                          });
                        }
                        if(data.sync_to_external?.includes('facebook_event')) {
                          const newwindow = window.open(`https://www.facebook.com/events/create?elgio_event_id=${id}`, 'fb_event');
                          if (window.focus) { newwindow.focus() }
                        }
                      }
                    }}
                    }
                    style={necessaryInp
                      ? {
                        border: "none",
                        }
                      : {
                        border: "none",
                        backgroundColor:"rgb(21, 119, 66)",
                        color:"rgb(71, 71, 71)",
                        cursor:"default",
                    }}
                  >
                    <div id="editBtnText">Live Schalten</div>
                  </a>
                )}
                {visibility === "public" && 
                  <div className="bg-grey Button white" onClick={(e) => {handleLinkCopyClick(e,id);linkCopiedToast()}} style={ isFullWidth ? {width: "fit-content"} : {}}>Eventlink kopieren</div>
                }
                {visibility === "public" &&
                  <Link to={`/event-form/success?event=${id}&redirected_from_dashboard=true`}>
                    <div className="bg-green Button white" style={ isFullWidth ? {width: "fit-content"} : {}}>Event promoten</div>
                  </Link>
                }

                {permissions?.events?.edit && <Link
                  id="ButtonsBoxItem"
                  className="bg-blue Button"
                  to={isParentEvent ? "/dashboard/events/event-bearbeiten/" + id + "?viewParent=true"  : "/dashboard/events/event-bearbeiten/" + id}
                  onClick={(e) => {if(startDateRaw < new Date() && visibility === "public"){window.alert("Du kannst dieses Event nicht mehr bearbeiten,\nweil es in der Vergangeheit liegt!"); e.stopPropagation();e.preventDefault()}}}
                >
                  {editIcon}
                  <div className="buttonText">Bearbeiten</div>
                </Link>}
                {isSubevent && !isParentEvent && <Link
                  id="ButtonsBoxItem"
                  className="bg-blue Button"
                  to={isParentEvent ? "/dashboard/events/event-bearbeiten/" + id + "?viewParent=true" : isSubeventDraft ? "/dashboard/events/event-bearbeiten/" + recurringEvent + "?viewParent=true&showToast=true" : "/dashboard/events/event-bearbeiten/" + id}
                  onClick={(e) => {if(startDateRaw < new Date() && visibility === "public"){window.alert("Du kannst dieses Event nicht mehr bearbeiten,\nweil es in der Vergangeheit liegt!"); e.stopPropagation();e.preventDefault()}}}
                >
                  <div id="editBtnText">Eventreihe Bearbeiten</div>
                </Link>}
                <div className="col-flex flex-gap-1rem justify-center align-center">
                  <div className="dropdown-button-einzel">
                    <ContextMenu__Event id={id} visibility={visibility} startDateRaw={startDateRaw} canceled={canceled} pendingReview={pendingReview}></ContextMenu__Event>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="stats-box-event">
        <div id="aufrufe" className="Stats-Event-Einzeln">
          <div className="StatsHeader">
            <div className="StatsIconEventEinzeln" style={{backgroundColor: "var(--bgBlue)"}}>{viewsIcon}</div>
            <div id="StatsTitelEventEinzeln">Aufrufe</div>
          </div>
          <div className="statsAnzahlBody">
            <div className="stats-counter animate__animated animate__fadeInDown">{views}</div>
          </div>
        </div>
        <div id="teilungen" className="Stats-Event-Einzeln">
          <div className="StatsHeader">
            <div className="StatsIconEventEinzeln" style={{backgroundColor: "var(--bgGreen)"}}>{participantsIcon}</div>
            <div id="StatsTitelEventEinzeln">Teilnehmer</div>
          </div>
          <div className="statsAnzahlBody">
            <div className="stats-counter animate__animated animate__fadeInDown">{attendeeCount }</div>
          </div>
        </div>
        <div id="follower" className="Stats-Event-Einzeln">
          <div className="StatsHeader">
            <div className="StatsIconEventEinzeln" style={{backgroundColor: "var(--bgOrange)"}}>{bookmarksIcon}</div>
            <div id="StatsTitelEventEinzeln">Gespeichert</div>
          </div>
          <div className="statsAnzahlBody">
            <div className="stats-counter animate__animated animate__fadeInDown">{saved}</div>
          </div>
        </div>
        <div id="ticketkaeufer" className="Stats-Event-Einzeln">
          <div className="StatsHeader">
            <div className="StatsIconEventEinzeln" style={{backgroundColor: "var(--purple)"}}>{sharesIcon}</div>
            <div id="StatsTitelEventEinzeln">Teilungen</div>
          </div>
          <div className="statsAnzahlBody">
            <div className="stats-counter animate__animated animate__fadeInDown">{shares}</div>
          </div>
        </div>
      </div>
      <div className="col-flex">
        <div className="row-flex" style={{justifyContent:"space-around",gap:".3rem"}}>
          <div className={menuState === 0 ? "eventMenuTile eventMenuTileActive" : "eventMenuTile"} onClick={() => {animate(0)}} id="detailsTabEvent">Details</div>
          {permissions?.marketing?.view && <div className={menuState === 1 ? "eventMenuTile eventMenuTileActive" : "eventMenuTile"} onClick={() => {animate(1)}} id="marketingTabEvent">Marketing</div>}
          {permissions?.ticketing?.view && <div className={menuState === 2 ? "eventMenuTile eventMenuTileActive" : "eventMenuTile"} onClick={() => {animate(2)}} id="ticketingTabEvent">Ticketing</div>}
          {permissions?.statistics?.view && <div className={menuState === 3 ? "eventMenuTile eventMenuTileActive" : "eventMenuTile"} onClick={() => {animate(3)}} id="statistikenTabEvent">Statistiken</div>}
          {permissions?.events?.view && <div className={menuState === 4 ? "eventMenuTile eventMenuTileActive" : "eventMenuTile"} onClick={() => {animate(4)}} id="galerieTabEvent">Galerie</div>}
        </div> 
        <div className="tabContent"> 
          <div id="tab-wrapper">
            {(() => {
              switch(menuState){
                case 0: return <DetailsTab></DetailsTab>
                case 1: return <MarketingTab></MarketingTab>
                case 2: return <TicketingTab  setShowModal={setShowModalTicketing} setShowModalAnlegen={setShowModalAnlegen}></TicketingTab>
                case 3: return <StatistikenTab></StatistikenTab>
                case 4: return <GalerieTab></GalerieTab>
                default: return <DetailsTab></DetailsTab>
              }
            })()}    
          </div>
        </div>
      </div>
      <Loader></Loader>
      <ScannerHinzufuegen show={show} handleClose={handleClose}></ScannerHinzufuegen>
      <Gaesteliste show={showGaesteListe} handleClose={handleCloseGaesteListe}></Gaesteliste>
      <ArtistBearbeiten show={showArtistEdit} handleClose={handleCloseArtistEdit} artistId={artistIdToEdit} lineup={lineup} indexToChange={lineup.indexOf(artistIdToEdit)}></ArtistBearbeiten>
      <ModalTicketing 
        startDate={date}
        startTime={startTime} 
        venue={club}
        location={address}
        eventName={titel}
        showModal={showModalTicketing}
        setShowModal={setShowModalTicketing}
        eventID={id}
        edit
      ></ModalTicketing>
      <ModalTicketing 
        startDate={date}
        startTime={startTime} 
        venue={club}
        location={address}
        eventName={titel}
        showModal={showModalAnlegen}
        setShowModal={setShowModalAnlegen}
        eventID={id}
      ></ModalTicketing>
      <PostModal idEdit={id} setEdit={setEdit} show={showPostModal} setShow={setShowPostModal} edit={edit} dataEdit={dataEdit}></PostModal>
    </div>
  );




  // DETAILS TAB

  function DetailsTab(){
    return(
      <div className="detailsTab" id="details">
        <div className="detailsTabColumn">
          <div className="col-flex" style={{gap:".5rem"}}>
            <div className="detailsheader">Eventname</div>
            <div className="detailssubheader" style={titel.length === 0 ? {fontStyle:"italic"}  : {fontStyle:"unset"}}>{titel}</div>
          </div>
          <div className="detailsbox">
            <div className="detailsheader">Datum & Uhrzeit</div>
            <div className="detailssubheader" style={ (date && startTime ) === undefined ? {fontStyle:"italic"}  : {fontStyle:"unset"}}>
            {date !== undefined && startTime !== undefined ? "Ab: " + date +  " " + startTime : 'Kein Datum'}</div>
            <div className="detailssubheader" style={ (endTime && end) === undefined ? {fontStyle:"italic"}  : {fontStyle:"unset"}}>{endTime && end ? "Bis: " +  end + " " + endTime : ""}</div>
          </div>
          <div className="detailsbox">
            <div className="detailsheader">Addresse</div>
            <div className="detailssubheader" style={address.length === 0 ? {fontStyle:"italic"} : {fontStyle:"unset"}}>
              {secretLocation ?
              <span className="col-flex">
                <span>{secretLocationIcon} Secret Location (wird bekannt gegeben {secretLocationRevealTime} vor Beginn)</span>
                <span>{address}</span>
              </span>
              : address}
            </div>  
          </div>
          <div className="detailsbox">
            <div className="detailsheader">Musikgenre</div>
            <div className="detailssubheader" style={ genre?.length === 0 ? {fontStyle:"italic"} : {fontStyle:"unset"}}>
              <div className="col-flex">
                {genre?.length > 0 ? genre.map((e, i) => <div key={e + "a"}><GenreName key={e} id={e} />{i !== genre.length - 1 && ''}</div>) : 'Keine Musikrichtugen'} 
              </div>
            </div>  
          </div>
          <div className="detailsbox">
            <div className="detailsheader">Mindestalter</div>
            <div className="detailssubheader" style={minAlter === undefined ? {fontStyle:"italic"}  : minAlter === "0" ? {fontSize:"var(--fontsize-massive)"} :{fontStyle:"unset"}}>{minAlter === "0" ? "Kein Mindesalter" : minAlter ?? 'Kein Mindestalter'}</div>
          </div>
        </div>
        <div className="detailsTabColumn">
          <div className="detailsbox">
            <div className="detailsheader">Dresscode</div>
            <div className="detailssubheader" style={ dresscode === null ? {fontStyle:"italic"}  : {fontStyle:"unset"}}>{dresscode??'Kein Dresscode'}</div>
          </div>
          <div className="detailsbox">
            <div className="detailsheader">Beschreibung</div>
            <div className="detailssubheader" style={description.length === 0  ? {fontStyle:"italic"} :{maxHeight:"100px",overflowY:"auto"}}>{description.length > 0 ? description : "Keine Beschreibung"}</div>
          </div>
          <div className="detailsbox">
            <div className="detailsheader">Lineup</div>
            <div className="detailssubheader pointer gap-2 col-flex" style={ lineup?.length === 0 ? {fontStyle:"italic"}  : {fontStyle:"unset"}}>
              {lineup?.length > 0 ? 
                lineup.map(e => e.toString().startsWith("$")
                  ? <div key={e}>{e}</div>
                  : <div key={e + "b"}  onClick={permissions?.events?.edit ? () => {setShowArtistEdit(true);setArtistIdToEdit(e)} : () => {}} className="row-flex align_center justify_space_between flex-gap-1rem">
                      <ArtistDetails id={e}/> {permissions?.events?.edit && editIcon}
                    </div>
                )
              : 'Kein Lineup'}
            </div>
          </div>
          <div className="detailsbox">
            <div className="detailsheader">Co-Veranstalter</div>
            <div className="detailssubheader" style={ organizer.filter(e => e !== club && e !== organizerId).length === 0 ? {fontStyle:"italic"} : {fontStyle:"unset"}}> 
            { (() => {
                const coorgnizers = organizer.filter(e => e !== club && e !== organizerId);
                if(coorgnizers.length === 0) return <>Keine Co-Veranstalter</>;
                return coorgnizers.map(e => <div key={e} ><OrganizerName key={e} id={e} /></div>);
              })() } 
            </div>
          </div>
          <div className="detailsbox">
           <div className="detailsheader">Eintritt</div>
           <div className="detailssubheader priceForNaN" style={price === null ? {fontStyle:"italic"} : {fontStyle:"unset"}}>
            {price === 0 
              ? <span> Freier Eintritt </span> 
              : price 
                ? <>
                    <span>ab&nbsp;</span>
                    <span>{price}€</span>
                  </> 
                : <span> Kein Eintritt </span>
            }
          </div>
          </div>
          <div className="detailsbox">
            <div className="detailsheader">Specials</div>
            <div className='detailssubheader col-flex' style={  specials?.length === 0 ? {fontStyle:"italic"}  : {fontStyle:"unset"}}>{
            specials?.length > 0 ?
            specials.map((s) => {
              return(<span key={s}>{s}</span>)
            })
            : "Keine Specials"
            }</div>
          </div>
        </div>
        <div className="previewAppEvent">
          <div className='websiteVorschauBox' style={{marginBottom: "20px"}}>
            <div className="profilheaders">Vorschau in der App</div>
            <FurtherInfo 
              explanation="Hier siehst du eine mobile Vorschau deines Events. Manchmal dauert es bis zu 2min bis Änderungen hier sichtbar sind."
            />
          </div>
          <div className="previewContainer">
              <img src={phoneFrame} alt="phone_frame_for_preview" className='phoneFramePreview'/>
              <div className="iframeContainer" style={{width: "89%"}}>
                <LoaderDivWithOverlay id="preview"/>
                <iframe title="webapp-preview-event-einzeln" onLoad={() => hideLoader("preview")} src={`https://elgio.de/event/${id}?hide_navigation=true`} style={{ transformOrigin: '0% 0%', border: 'none', margin: 0, padding: 0, height: '100%', width: '100%' }} />
              </div>
          </div>
        </div>
      </div>
    );
  }



  // MARKETING TAB

  function MarketingTab(){
    const deletePost = async (id) => {
      if(window.confirm("Sind du dir sicher, dass du diesen Post löschen willst?")){
          await deleteDoc(doc(collection(firestore,"social_media_posts"),id))

      }
    }
    
    return(
      <>
        { 
        isParentEvent ? 
        <div id="noTicketing">
        <div>Das funktioniert noch nicht mit Eventreihen!</div>
        </div>
        :
        isProUser ? 
        <div className="MarketingBox">
          <div className="eventPostBox col-flex flex-gap-1rem">
            <div className="TextWhite" style={{fontSize:"var(--fontsize-huge)"}}>Du hast folgende Posts zu diesem Event:</div>
            {eventPost.length !== 0 ? 
            eventPost.map((post) => {
              return(
                <PostBox
                  post_at_raw={post.data()?.post_at?.toDate()}
                  post_at={post.data()?.post_at?.toDate().toLocaleDateString("de-DE",{weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric'}) + " um " + post.data().post_at.toDate().toLocaleTimeString("de-DE",{hour:"2-digit",minute:"2-digit"})}    
                  banner={post.data()?.banner}
                  status={post.data()?.status}
                  wholeDoc={post.data()}
                  setDataEdit={setDataEdit}
                  deletePost={deletePost}
                  setShow={setShowPostModal}
                  setModalPic={setModalPic}
                  id={post.id}
                  repeat={post.data()?.repeat}
                  instagram_story={post.data().instagram_story}
                  instagram_post={post.data().instagram_post}
                  facebook_story={post.data().facebook_story}
                  facebook_post={post.data().facebook_post}
                  type={post.data().type}
                  caption={post.data().caption}
                ></PostBox>
              )
            }) : <div className="noEvents">Es gibt keine Eventposts zu diesem Event</div>}
            <PictureModal pic={modalPic} setPic={setModalPic} ></PictureModal>
          </div>
          <div className="otherPostBox col-flex flex-gap-1rem">
            <div className="TextWhite" style={{fontSize:"var(--fontsize-huge)"}}>Dieses Event ist in folgenden anderen Posts gefeatured:</div>
            {otherPosts.length !== 0 ? otherPosts.map((post) => {
              return(
                <PostBox
                  post_at_raw={post.data()?.post_at?.toDate()}
                  post_at={post.data()?.post_at?.toDate().toLocaleDateString("de-DE",{weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric'}) + " um " + post.data().post_at.toDate().toLocaleTimeString("de-DE",{hour:"2-digit",minute:"2-digit"})}    
                  banner={post.data()?.banner}
                  status={post.data()?.status}
                  wholeDoc={post.data()}
                  setDataEdit={setDataEdit}
                  deletePost={deletePost}
                  setShow={setShowPostModal}
                  setModalPic={setModalPic}
                  id={post.id}
                  repeat={post.data()?.repeat}
                  instagram_story={post.data().instagram_story}
                  instagram_post={post.data().instagram_post}
                  facebook_story={post.data().facebook_story}
                  facebook_post={post.data().facebook_post}
                  type={post.data().type}
                  caption={post.data().caption}
                ></PostBox>
              )
            }) : <div className="noEvents">Dieses Event ist in keinen anderen Posts gefeatured</div> }
            <PictureModal pic={modalPic} setPic={setModalPic} ></PictureModal>
          </div>
        </div>
        :
        <div id="noTicketing">
          <div>Du hast diese Funktion noch nicht freigeschaltet!</div>
          <Link to={"/dashboard/marketing"}>
            <div className="newPassw flex-gap-1rem white">
              <div>{plusIcon}</div>
              <div>Kostenlos testen</div>
            </div>
          </Link>
        </div>
        }
      </>
    )
  }



  // TICKETING TAB

  function TicketingTab(props){
    const {permissions} = useContext(UserContext);
    const [ticketKategorien,setTicketKategorien] = useState([])
    const [totalTicketsEvent, setTotalTicketsEvent] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [statusTicketing, setStatusTicketing] = useState('');
    const [ticketSalesWeekSums, ticketSalesWeekNum,, ticketSalesWeekLabels] = getTicketSales({timeframe: 'week', event: id});
    const [ticketSalesTodaySums, ticketSalesTodayNum,, ticketSalesTodayLabels] = getTicketSales({timeframe: 'today', event: id});
    const [ticketSalesYesterdaySums, ticketSalesYesterdayNum,, ticketSalesYesterdayLabels] = getTicketSales({timeframe: 'yesterday', event: id});
    const [ticketSalesLast30DaysSums, ticketSalesLast30DaysNum,, ticketSalesLast30DaysLabels] = getTicketSales({timeframe: 'last_30_days', event: id});
    const [ticketSalesState,setTicketSalesState] = useState(2)                   
    const [ticketSalesActiveSums,setTicketSalesActiveSums] = useState([])
    const [ticketSalesActiveNum,setTicketSalesActiveNum] = useState(0)
    const [ticketSalesActiveLabels,setTicketSalesActiveLabels] = useState([])                   
    const [ticketSalesActiveText,setTicketSalesActiveText] = useState("Heute")
    const [completedTotal, setCompletedTotal] = useState(0);
    const [availableTicketsEvent, setAvailableTicketsEvent] = useState(0);

    
    useEffect(() => {
      if(ticketSalesState === 0){
         setTicketSalesActiveSums(ticketSalesTodaySums)
         setTicketSalesActiveNum(ticketSalesTodayNum)
         setTicketSalesActiveLabels(ticketSalesTodayLabels)
         setTicketSalesActiveText("Heute")
      }
      else if(ticketSalesState === 1){
          setTicketSalesActiveSums(ticketSalesYesterdaySums)
          setTicketSalesActiveNum(ticketSalesYesterdayNum)
          setTicketSalesActiveLabels(ticketSalesYesterdayLabels)
          setTicketSalesActiveText("Gestern")
      }
      else if(ticketSalesState === 2){
          setTicketSalesActiveSums(ticketSalesWeekSums)
          setTicketSalesActiveNum(ticketSalesWeekNum)
          setTicketSalesActiveLabels(ticketSalesWeekLabels)
          setTicketSalesActiveText("Woche")      
      }
      else if(ticketSalesState === 3){
          setTicketSalesActiveSums(ticketSalesLast30DaysSums)
          setTicketSalesActiveNum(ticketSalesLast30DaysNum)
          setTicketSalesActiveLabels(ticketSalesLast30DaysLabels)
          setTicketSalesActiveText("Letzte 30 Tage")      
      }
    },[ticketSalesState,statsLoaded])
    
    //      Coupon Stuff  
    //  ! STILL COMING SOON !
    // 
    // eslint-disable-next-line no-unused-vars
    const [coupons, setCoupons] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [noCoupons, setNoCoupons] = useState(true);
    //   useEffect(() => 
    //   {getDocs(
    //     query(
    //       collection(firestore, "coupons"),
    //       where("organizer", "==", organizerId),
    //       where("events", "array-contains", id)

    //     )
    //   ).then((snapshot) => {
    //     if(snapshot.docs.length > 0){
    //       setNoCoupons(false)
    //       document.getElementById('coupon-flex').style.overflowY="auto"
    //       setCoupons(snapshot.docs);
    //     } 
    //     else{
    //       setNoCoupons(true)
    //       document.getElementById('coupon-flex').style.overflowY="auto"
    //     }
    //   }).catch((error) => console.log(error));
    // }, [organizerId]);
  

    useEffect(() => {
      getDocs(collection(firestore, "events", id, "tickets")).then((category) => {
        setTicketKategorien(category.docs);

        let total_tickets_all_categories = 0;
        let available_tickets_all_categories = 0;

        category.docs.forEach((doc) => {
          total_tickets_all_categories += doc.data().total_tickets;
          available_tickets_all_categories += doc.data().available_tickets;
        });

        const state = available_tickets_all_categories === 0 ? "Ausverkauft" : "Nicht ausverkauft";
        setStatusTicketing(state);
        setTotalTicketsEvent(total_tickets_all_categories);
        setAvailableTicketsEvent(available_tickets_all_categories); // Update available tickets

        const totalSoldTickets = total_tickets_all_categories - available_tickets_all_categories;
        const completionPercentage = (totalSoldTickets / total_tickets_all_categories) * 100;
        setCompletedTotal(completionPercentage);
      });
    }, [id, firestore]);

    async function downloadPDF(){
      let downloadUrl = await getDownloadURL(ref(storage,pdfRef))
      window.open(downloadUrl, '_blank').focus()
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return(
      isParentEvent ? 
      <div id="noTicketing">
        <div>Das funktioniert noch nicht mit Eventreihen!</div>
      </div>
      :
      ["standard", "hidden"].includes(ticket) ?
        <div className="ticketingTabBoxTotal" id="ticketing" style={ ["standard", "hidden"].includes(ticket) ? {} : {justifyContent:"center",alignItems:"center"}}>
          <div className="ticketingTabBoxLeft">
            <div className="ticketingTabBox">
              <div className="row-flex eckdatenText justify_space_between">
                <div> Übersicht </div>
                {eventOver ? 
                  <div className="AbrechnungBox pointer" onClick={downloadPDF}>{pdfIcon} Abrechnung</div> 
                : <></>}
              </div>
              <div className="ticketCategoriesFlex">
                {ticketKategorien.map((category, key) => {
                  const totalTickets = category.data().total_tickets;
                  const availableTickets = category.data().available_tickets;
                  const completed = ((totalTickets - availableTickets) / totalTickets) * 100;
                  return (
                    <div className="ticketCategoriesRow" key={key}>
                      <div className="ticketCategoriesLeft">
                        <div className="medium">{category.data().name}</div>
                        <div className="row-flex gap-1">
                          <div className="t-secondary">{((totalTickets - availableTickets) * category.data().price / 100).toFixed(2).replace('.', ',')}€ -</div>
                          <div style={{color: "var(--lightgrey)"}}>({(totalTickets - availableTickets) + "/" + totalTickets})</div>
                        </div>
                      </div>
                      <ProgressBar
                        bgColor="var(--blue)"
                        baseBgColor="none"
                        barContainerClassName="borderRadius-5px"
                        animateOnRender={true}
                        labelAlignment="outside"
                        borderRadius="15px"
                        height="30px"
                        className="progressBarTicketingTab"
                        labelClassName="Montserrat_400 textProgressBar"
                        completed={Math.round(completed)}
                        maxCompleted={100}
                        width="200px"
                      />
                    </div>
                  );
                })}
                <div className="ticketCategoriesRow" style={{marginTop: "15px"}}>
                  <div className="ticketCategoriesLeft">
                    <div style={{ fontSize: "var(--fontsize-big)", fontWeight: "600" }}>Insgesamt</div>
                    <div className="row-flex gap-1">
                      <div className="t-secondary">{ticketKategorien.reduce(
                        (acc, category) => acc + ((category.data().total_tickets - category.data().available_tickets) * category.data().price / 100), 0
                      ).toFixed(2).replace('.', ',')}€ -</div>
                      <div style={{ fontSize: "var(--fontsize-medium)", color: "var(--textSecondary)"}}>({totalTicketsEvent - availableTicketsEvent} / {totalTicketsEvent})</div>
                    </div>
                  </div>
                  <ProgressBar
                    bgColor="var(--blue)"
                    baseBgColor="none"
                    barContainerClassName="borderRadius-5px"
                    animateOnRender={true}
                    labelAlignment="outside"
                    borderRadius="15px"
                    height="30px"
                    className="progressBarTicketingTab"
                    labelClassName="Montserrat_400 textProgressBar"
                    completed={Math.round(completedTotal)}
                    maxCompleted={100}
                    width="200px"
                  />
                </div>
              </div>
            </div>
            <div className="ticketingTabBox">
              <div className="row-flex eckdatenText">Funktionen</div>
              <div className="FunctionsOberbox">
                <div className="FunctionsBox">
                  <div className="col-flex" style={{width:"70%"}}>
                    <div className="FunctionsHeader">Ticketliste</div>
                    <div className="FunctionsSubheader">Siehe den Status und alle Details zu jedem Ticket</div>
                  </div>
                  <Link to={"/dashboard/events/ticket-overview/" + id} className="ticketoverview-btn">
                    {windowWidth < 768 ? chevronRightIcon : "Zur Ticketliste"} 
                  </Link>
                </div>
                <div className="FunctionsBox">
                  <div className="col-flex" style={{width:"70%"}}>
                    <div className="FunctionsHeader">Gästeliste</div>
                    <div className="FunctionsSubheader">Schreibe oder importiere Namen auf deine Gästeliste</div>
                  </div>
                  <div onClick={() => {setShowGaesteListe(true)}} className="ticketoverview-btn">
                    {windowWidth < 768 ? chevronRightIcon : "Zur Gästeliste"}
                  </div>
                </div>
                <div className="FunctionsBox">
                  <div className="col-flex" style={{width:"70%"}}>
                    <div className="FunctionsHeader">Türsteher Codes</div>
                    <div className="FunctionsSubheader">Erstelle Zugangscodes für die Scannapp für deine Türsteher</div>
                  </div>
                  <div className="ticketoverview-btn" onClick={() => {setShow(true)}}>
                    {windowWidth < 768 ? chevronRightIcon : "Zu den Scanner Codes"}
                  </div>
                </div>
                {permissions?.ticketing?.edit && <div className="FunctionsBox">
                  <div className="col-flex" style={{width:"70%"}}>
                    <div className="FunctionsHeader">Ticketing bearbeiten</div>
                    <div className="FunctionsSubheader">Erstelle neue Ticketarten oder passe die Anzahl bestehender an</div>
                  </div>
                  <div className="ticketoverview-btn" onClick={(e) => {props.setShowModal(true); e.preventDefault()}}>
                    {windowWidth < 768 ? chevronRightIcon : "Ticketing bearbeiten"}
                  </div>
                </div>}
              </div>
            </div>
            {/* <div className="ticketingTabBox" style={comingSoon ? {height: "250px"} : {minHeight: "150px"}}>                  
              <div className="row-flex eckdatenText">
                <div> Gutscheincodes </div>
                {<div className="box-title-second-ele" style={{width:"100%"}}>
                  { comingSoon ? "" : 
                    noCoupons ? <></> :
                  <Link to="/dashboard/discount-codes" style={{display: "flex", color: "var(--blue)"}}>
                    <div className="col-flex justify_center align_center" style={{backgroundColor:"var(--blue)",borderRadius:"5px", width: "100px"}}>
                      <div className="uebersichtTitelDetails" style={{margin:"10px",color: "var(--textMain)",width:"100%",textAlign:"center"}}>Neuer Code</div>
                    </div>
                  </Link> 
                    }
                </div>}
              </div>
              <div className="coupon-box-body-flex" id="coupon-flex" style={
                comingSoon 
                ? { justifyContent: "center", alignItems: "center", overflowY: "hidden" } 
                : noCoupons 
                  ? { overflowY: "auto", minHeight: "180px", maxHeight: "200px" }
                  : { justifyContent: "center", alignItems: "center", overflowY: "hidden" }
              }>
              { comingSoon ? <div>Coming Soon</div> :  noCoupons 
                ? 
                  <div className="col-flex justify_center align_center">
                    <div id="noUpcoming" className="animate__animated animate__fadeInDown" style={{fontSize:"var(--fontsize-big)",color: "var(--textSecondary)", marginBottom: "15px"}}>Du hast noch keine Gutscheincodes!</div>
                    <Link to="/dashboard/discount-codes" className="newCodeBtn"> {plusIcon} Neuer Code </Link>
                  </div>
                :
                  coupons.map((coupon, index) => {
                    return(
                      <div className="coupon-col-item" style={{width: "99%"}}>
                        <div className="coupon-col-item-left">
                          <div className="circleNormal" style={{backgroundColor:"var(--bg7)"}}>
                            <div className="couponVal">{coupon.data().discount_absolute ? (coupon.data().discount_absolute / 100) + '€' : (coupon.data().discount_percent + '%')}</div>
                          </div>
                          <div className="coupon-code-name">"{coupon.data().code}"
                            <div className="coupon-code-subname">{coupon.data().discount_absolute ? `${coupon.data().discount_absolute / 100}€ Rabatt` : `${coupon.data().discount_percent}% Rabatt`}</div>
                          </div>
                        </div>
                        <div id={index} className="copy-btn" onClick={() => {copyToClipBoard(index,coupon.data().code)}}> Kopieren </div>
                      </div>
                    )
                  })
                }
              </div>
            </div> */}
          </div>
          <div className="ticketingTabBox" id="ticketingTabSales">
            <div className="box-title-flex">
              <div className="box-title-first-ele">
                <div className="eckdatenText">
                  Ticketverkäufe
                </div>
              </div>
              <div className="box-title-second-ele" style={{fontSize: "var(--fontsize-large)", gap:"1rem"}}>
                <div style={{cursor:"pointer", color: "white"}}
                  onClick={() => {
                    if(ticketSalesState === 0)
                    {setTicketSalesState(3)}
                    else{
                      setTicketSalesState(ticketSalesState - 1 )}
                  }}
                >{chevronLeftIcon}</div>
                <div className="uebersichtTitelDetails" style={{textAlign:"center", fontSize: "var(--fontsize-large)"}}>{ticketSalesActiveText}</div> 
                <div style={{cursor:"pointer", color: "white"}}
                  onClick={() => {
                    if(ticketSalesState === 3)
                    {setTicketSalesState(0)}
                    else{
                      setTicketSalesState(ticketSalesState + 1 )}
                  }}
                >{chevronRightIcon}</div>
              </div>
            </div>
            <div className="numberSales">
              {ticketingLoading ? "Loading..." : (isNaN(ticketSalesActiveNum) ? 0 : ticketSalesActiveNum) + " €"}
            </div> 
            <div className="chart-box">
              <LineGraph data={ticketSalesActiveSums} labels={ticketSalesActiveLabels} colors={['#fdfd44']}/>
            </div>
          </div>
        </div>
      :
      <div id="noTicketing">
        <div>Du hast bei diesem Event noch kein Ticketing hinzugefügt!</div>
        {permissions?.ticketing?.edit && <div className="newPassw flex-gap-1rem" onClick={() => {props.setShowModalAnlegen(true)}}>
          <div>{plusIcon}</div>
          <div>Für dieses Event aktivieren</div>
        </div>}
      </div>
    )
  }


  
  // STATISTIKEN TAB

  function StatistikenTab(){
    return(
      <div className="mainBoxStatsTab" id="statistics">
        <div className="statisticsTabBox">
          <div className="statisticsBoxTopRow">
            <div className="EventStatisticsHeader">Kundenstamm</div>
            <FurtherInfo
              explanation="Hier siehst du prozentual wie viele der Ticketkäufer wiederkehrende Kunden (Bestandskunden) oder erstmalige Kunden (Neukunden) sind."
            />
          </div>
          <PieChart data={customerStatuses} labels={customerStatusesLabels} colors={['#faab17', '#4ab022']} />
        </div>
        <div className="statisticsTabBox">
          <div className="statisticsBoxTopRow">
            <div className="EventStatisticsHeader">Geschlecht</div>
            <FurtherInfo
              explanation="Hier siehst du die Verteilung deiner Kunden in Geschlechtern. Als Kunde zählen wir hier alle, die mit deinen Events interagiert (zb. gemerkt, geteilt, gekauft) haben."
            />
          </div>
          <PieChart data={customerGenders} labels={customerGendersLabels} colors={['#0d6efd', '#f20000', '#8b44fd']}/>
        </div>
        <div className="statisticsTabBox">
          <div className="statisticsBoxTopRow">
            <div className="EventStatisticsHeader">Alter</div>
            <FurtherInfo
              explanation="Hier siehst du die Verteilung deiner Kunden in Altersgruppen. Als Kunde zählen wir hier alle, die mit deinen Events interagiert (zb. gemerkt, geteilt, gekauft) haben."
            />
          </div>
          <BarChart data={customerAges} labels={customerAgesLabels} colors={['#fdfd44']}/>
        </div>
        <div className="statisticsTabBox">
          <div className="statisticsBoxTopRow">
            <div className="EventStatisticsHeader">Musikgenre</div>
            <FurtherInfo
                explanation="Hier siehst du in welchen Musikgenre deine Kunden oft Events suchen."
              />
          </div>
          <LineChart data={customerGenres} labels={customerGenresLabels} colors={['#f20000']}/>
        </div>
        <div className="statisticsTabBox">
          <div className="statisticsBoxTopRow">
            <div className="EventStatisticsHeader">Herkunft</div>
            <FurtherInfo
              explanation="Hier siehst du aus welchen größeren Ballungsräumen deine Kunden kommen."
            />
          </div>
          <LineChart data={customerOrigin} labels={customerOriginLabels} colors={['#faab17']}/>
        </div>
        <div className="statisticsTabBox">
          <div className="statisticsBoxTopRow">
            <div className="EventStatisticsHeader">Alternative Clubs</div>
            <FurtherInfo
              explanation="Hier siehst du in welche anderen Clubs deine Gäste noch gerne gehen."
            />
          </div>
          <LineChart data={customerClubs} labels={customerClubsLabels} colors={['#8b44fd']}/>
        </div>
      </div>
    )
  }

  // Galerie TAB

  function GalerieTab(props){
    
    const { organizerId } = useContext(UserContext);

    function uploadGallery() {
      document.getElementById("getFile")?.click();
    }

    //upload state stuff
    const [uploading, setUploading] = useState(false);
    const [filesUploaded, setFilesUploaded] = useState(0);
    const [filesTotal, setFilesTotal] = useState(0);
    const [progress, setProgress] = useState(0);

    //gallery imags
    const [images, setImages] = useState([]);
    useEffect(() => {
      onSnapshot(query(collection(firestore, 'gallery'), where('event', '==', id)), (snapshot) => setImages(snapshot.docs));
    }, [images]);

    useEffect(() => {(async () => {
      if(!props.banner) return;
      document.getElementById("output_image").style.visibility='visible';
      document.getElementById('UploadBtn').style.visibility='hidden';
      const downloadUrl = await getDownloadURL(ref(storage, props.banner));
      document.getElementById('output_image').src = downloadUrl;
    })()}, [props.banner]);
    
    return(
      <>
          <div id="noTicketing">
            {
            isParentEvent ? 
            <div>Das funktioniert noch nicht mit Eventreihen!</div>
            :
            eventHasPassed
              ? <>
              <input
                type="file"
                multiple="multiple"
                id="getFile"
                className="imgInp"
                accept="image/png ,image/jpeg, image/jpg"
                onChange={async (evt, i) => {
                  const files = evt.target.files;
                  setFilesTotal(files.length);
                  setUploading(true);
                  for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    const storageRef = ref(storage, 'organizers/' + organizerId + '/gallery/' + id + '-' + file.name);
                    const uploadTask = uploadBytesResumable(storageRef, file);
                    uploadTask.on('state_changed', (snapshot) => {
                      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                      setProgress(progress);
                    }, (error) => {
                      alert('Es ist beim Hochladen ein Fehler aufgetreten. Bitte versuche es erneut.');
                      console.error(error);
                    }, () => {
                      setFilesUploaded(i + 1);
                      setProgress(0);
                      if (files.length === i + 1) {
                        setUploading(false);
                      }
                    });
                    await uploadTask;
                    await setDoc(doc(firestore, 'gallery', id + '-' + file.name), {
                      image: storageRef.fullPath,
                      event: id,
                      organizer: organizerId,
                    });
                  }
                  evt.target.value = null;
                }}
              />
              {images?.length ? <div className="gallery-images-container">
                {images.map((image) => <div className="gallery-image-box">
                  <FirebaseStorageImage reference={image.data().image} resolution="512" />
                </div>)}
                <div>
                  {!uploading 
                    ? <button
                        onClick={uploadGallery}
                        className="UploadBtnGallery"
                      > Weitere Bilder hochladen
                      </button>
                    : <div> {filesUploaded}/{filesTotal} Bilder hochgeladen ({(filesUploaded * 100 / filesTotal + progress / filesTotal).toFixed(0)}%)</div>
                  }
                </div>
              </div> : <>
                  <div>Lade hier die Bilder zu deinem Event hoch:</div>
                  
                {!uploading ? <button
                  onClick={uploadGallery}
                  className="UploadBtnGallery"
                >
                  Bilder auswählen
                </button> : <div>
                  {filesUploaded}/{filesTotal} Bilder hochgeladen ({(filesUploaded * 100 / filesTotal + progress / filesTotal).toFixed(0)}%)
                </div>}
              </>
              }</>: <div>Hier kannst du nach dem Event Bilder zu deinem Event hochladen</div>
            }
          </div>
      </>
    )
  }
}

export { EventEinzeln };