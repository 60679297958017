import React, { useContext, useEffect, useState } from "react";
import "../home.css";
import "../settings.scss";
import "./changelog.css";
import 'animate.css/animate.css'
import Modal from "react-modal";
import "../../../index.scss";
import { collection, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";
import { chevronLeftIcon, chevronRightIcon } from "../../../icons";



export default function ModalChangelog({ show, handleClose }) {
  const el = Modal.setAppElement("#portal");
  const [changelogEntries,setChangelogEntries] = useState([])
  const {organizerId} = useContext(UserContext)
  const [currentIndex, setCurrentIndex] = useState(0);
  
  useEffect(() => {
    // Fetch the changelog entries
    getDocs(collection(firestore, "changelogs", "dashboard", "changes"))
      .then((querySnapshot) => {
        // Fetch the organizer's data
        getDoc(doc(collection(firestore, "organizers"), organizerId))
          .then((organizerDoc) => {
            let sortedChangelogs = querySnapshot.docs
              .map((doc) => ({
                id: doc.id,
                ...doc.data()
              }))
              .sort((a, b) => b.created_timestamp.seconds - a.created_timestamp.seconds); 
  
            if (sortedChangelogs.length > 0) {
              const lastVisitedId = organizerDoc.exists() && organizerDoc.data().last_visited_changelog
                ? organizerDoc.data().last_visited_changelog
                : null;
  
              const lastVisitedIndex = lastVisitedId
                ? sortedChangelogs.findIndex(changelog => changelog.id === lastVisitedId)
                : -1;
  
              if (lastVisitedIndex > 0) {
                const newChangelogs = sortedChangelogs.slice(0, lastVisitedIndex);
                setChangelogEntries(newChangelogs.length > 0 ? newChangelogs : [sortedChangelogs[lastVisitedIndex]]);
              } else {
                setChangelogEntries(sortedChangelogs.slice(0, 3));
              }
  
              const mostRecentChangelog = sortedChangelogs[0];
              if (mostRecentChangelog && organizerId) {
                const organizerRef = doc(collection(firestore, "organizers"), organizerId);
                updateDoc(organizerRef, {
                  last_visited_changelog: mostRecentChangelog.id,
                }).then(() => {
                  console.log("Last viewed changelog ID updated successfully:", mostRecentChangelog.id);
                }).catch((e) => {
                  console.error("Error updating last viewed changelog ID:", e);
                });
              }
            } else {
              setChangelogEntries([]); 
            }
          })
          .catch((e) => {
            console.error('Error fetching organizer data:', e);
          });
      })
      .catch((e) => {
        console.error('Error fetching changelogs:', e);
      });
  }, [organizerId]);
  
    // Handle changing to the previous entry
    const handlePrevious = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? changelogEntries.length - 1 : prevIndex - 1));
    };
  
    // Handle changing to the next entry
    const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex === changelogEntries.length - 1 ? 0 : prevIndex + 1));
    };


  return (
    <>
      <Modal
        isOpen={show}
        appElement={el}
        className="change-modal"
        overlayClassName="modalOverlayNormal"
        onRequestClose={handleClose}
        shouldFocusAfterRender={false}
        autoFocus={false}
      >
        <div className="col-flex gap-4">
          {/* TODO: Remove style tag and add w-max-content to classname */}
          <div className="row-flex align_center gap-4 justify_center" >
            {changelogEntries.length > 1 && <div onClick={handlePrevious} className="fontSize-30px pointer">{chevronLeftIcon}</div>}
            <div className="change-content">
              <div className="col-flex gap-4 w-100">
                <div className="col-flex gap-1">
                  <div className="t-secondary">Das ist Neu</div>
                  <div className="white humongous font-semibold">{changelogEntries[currentIndex]?.name} </div>
                </div>
                <div className="change-logs-map">
                {changelogEntries.length > 0 && (
                    <div className="changeLog">
                      {changelogEntries[currentIndex].values.length > 0 &&
                        changelogEntries[currentIndex].values.map((value, index) => {
                          return (
                            <div key={index}>
                              <div className="row-flex gap-3">
                                <i className={value.icon} style={{marginTop: "5px"}}></i>
                                <div>
                                  <div className="changeTitle">{value.title}</div>
                                  <div className="changeSubtitle">{value.description}</div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  )}
                </div>
              </div> 
              <div className="link-text" onClick={handleClose}>Schließen</div>
            </div>
            {changelogEntries.length > 1 && <div onClick={handleNext} className="fontSize-30px pointer">{chevronRightIcon}</div>}
          </div>

          {changelogEntries.length > 1 && <div id="changelog_dots" className="row-flex align_center justify_center">
            {changelogEntries.map((entry, index) => (
              <div
                key={index}
                className={`dot ${currentIndex === index ? "active" : ""}`}
              ></div>
            ))}
          </div>}
        </div>

      </Modal>
    </>
  );
  }